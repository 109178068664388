import React, { useState, useRef, useEffect } from "react";
import styles from "./index.less";

const ExpandableTextScreen = ({ text, index, pageType }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isOverflown, setIsOverflown] = useState(false);
  const textRef = useRef(null);
  console.log(isOverflown);
  useEffect(() => {
    const checkOverflow = () => {
      const element = textRef.current;
      setIsOverflown(element.scrollHeight > element.clientHeight);
    };
    checkOverflow();

    window.addEventListener("resize", checkOverflow);

    return () => {
      window.removeEventListener("resize", checkOverflow);
    };
  }, [text, pageType]);

  const toggleExpansion = () => {
    setIsExpanded(!isExpanded);
  };

  const renderExpandButton = () => {
    if (isOverflown) {
      return (
        <span
          onClick={toggleExpansion}
          className={`${styles["expandable-btn"]} ${
            index % 2 === 0
              ? styles["expandable-even"]
              : styles["expandable-odd"]
          }`}
        >
          {isExpanded ? "" : "更多"}
          {!isExpanded && (
            <span
              className={`${styles["expandable-icon"]} iconfont icon-xiangxia`}
            ></span>
          )}
        </span>
      );
    }
  };

  return (
    <div
      className={`${styles["expandable-text"]} ${
        isExpanded ? styles.expanded : ""
      }`}
      ref={textRef}
    >
      {text}
      {renderExpandButton()}
    </div>
  );
};

export default ExpandableTextScreen;
