import react, { useState, useRef, useEffect } from "react";
import styles from "./index.less";
import defaultPeople from "../../../image/defaultPeople.svg";
import { getRelateList } from "../../../services/api";
import CommonTitle from "../../CommonTitle";
import _ from "lodash";
import { IListData } from "src/pages/Scholar";
interface IScholarPart {
  currentClickData: any;
}

function ScholarWin(props: IScholarPart) {
  const { currentClickData } = props;
  const [listData, setListData] = useState<Array<IListData>>([]);
  const listRef = useRef<HTMLDivElement>(null);
  const currentPageRef = useRef(1);
  const listDataRef = useRef([]);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const [status, setStatus] = useState<"expand" | "collapse">("expand");
  const getData = async (params: any) => {
    let res: any = await getRelateList({
      node_id: params.node_id,
      source_node_type: "org",
      size: 24,
      target_node_type: "person",
      page: currentPage,
    });
    if (res.code === 0) {
      let temp = res.data.person_list || [];
      if (currentPage > 1 && listDataRef.current) {
        temp = [...listDataRef.current, ...temp];
      }
      setListData(temp);
      listDataRef.current = temp;
    }
  };
  const handleScreenAuto = (e: any) => {
    let dom = listRef.current;
    if (dom) {
      //文档内容实际高度（包括超出视窗的溢出部分）
      let scrollHeight = Math.max(dom.scrollHeight, dom.scrollHeight);
      //滚动条滚动距离
      let scrollTop = e.target.scrollTop;
      //窗口可视范围高度
      let clientHeight = dom.clientHeight;
      if (clientHeight + scrollTop + 100 >= scrollHeight) {
        if (listDataRef.current.length === currentPageRef.current * 24) {
          currentPageRef.current += 1;

          setCurrentPage(currentPageRef.current);
        }
      }
    }
  };

  //React的生命周期 如果你是vue可以放到mountd或created中
  useEffect(() => {
    if (listRef.current) {
      listRef.current.addEventListener(
        "scroll",
        _.debounce(handleScreenAuto, 200),
        true
      );
      return () => {
        if (listRef.current) {
          listRef.current.removeEventListener(
            "scroll",
            _.debounce(handleScreenAuto, 200),
            true
          );
        }
      };
    }
  }, []);

  useEffect(() => {
    if (listRef.current) {
      listRef.current.scrollTop = 0;
    }
    listDataRef.current = [];
    currentPageRef.current = 1;
    setCurrentPage(1);
  }, [JSON.stringify(currentClickData)]);

  useEffect(() => {
    if (currentClickData.node_id) {
      getData(currentClickData);
    }
  }, [JSON.stringify(currentClickData), currentPage]);

  return (
    <>
      <div
        className={
          listData && listData.length ? styles.ScholarPart : styles.hide
        }
      >
        <span className={styles.title}>相关学者</span>
        <CommonTitle title={"相关学者"} className={styles.title} />
        <span
          className={styles.closeBtn}
          onClick={() => setStatus("collapse")}
        ></span>
        <div className={styles.list} ref={listRef}>
          {Array.isArray(listData) &&
            listData.map((item: any) => (
              <div
                key={item.node_id}
                className={styles.listItem}
                data-id={item.node_id}
              >
                <img
                  className={styles.listImg}
                  src={item.avatar || ""}
                  alt=""
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = defaultPeople;
                  }}
                />

                <div className={styles.rightPart}>
                  <span
                    className={styles.listName + " text-ellipsis"}
                    title={item.scholar_nameZh || item.scholar_name}
                    onClick={() =>
                      window.open(
                        `/graph_detail?id=${item.aminer_id}&node_type=person`,
                        "_blank"
                      )
                    }
                  >
                    {item.scholar_nameZh || item.scholar_name}
                  </span>

                  <div className={styles.bottomCount}>
                    <div className={styles.bottomDetail}>
                      <span className={styles.label}>H-index：</span>
                      <span className={styles.count}>{item.hindex || 0}</span>
                    </div>
                    <div className={styles.bottomDetail}>
                      <span className={styles.label}>论文数：</span>
                      <span className={styles.count}>
                        {item.n_papers === 10000 ? "10000+" : item.npubs}
                      </span>
                    </div>
                  </div>

                  <p
                    className={styles.listorg + " text-ellipsis"}
                    title={item.org_nameZh || item.org_name}
                  >
                    {item.org_nameZh || item.org_name}
                  </p>
                </div>
              </div>
            ))}
        </div>
      </div>
    </>
  );
}

export default ScholarWin;
