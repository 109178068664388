import react, { useState, useRef, useEffect } from "react";
import styles from "./index.less";
import { getRelateList } from "../../../services/api";
import _ from "lodash";
import CommonTitle from "../../CommonTitle";

interface IPaperWin {
  currentClickData: any;
}

function PaperWin(props: IPaperWin) {
  const { currentClickData } = props;
  const [listData, setListData] = useState<Array<any>>([]);
  const listRef = useRef<HTMLDivElement>(null);
  const currentPageRef = useRef(1);
  const listDataRef = useRef([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [status, setStatus] = useState<"expand" | "collapse">("expand");

  const getData = async (params: any) => {
    let res: any = await getRelateList({
      node_id: params.node_id,
      source_node_type: "person",
      size: 24,
      target_node_type: "paper",
      page: currentPage,
    });
    if (res.code === 0) {
      let temp = res.data.paper_list || [];
      if (temp.length === 0 && currentPage === 1) {
        setStatus("collapse");
      } else {
        setStatus("expand");
      }
      if (currentPage > 1 && listDataRef.current) {
        temp = [...listDataRef.current, ...temp];
      }
      setListData(temp);
      listDataRef.current = temp;
    }
  };

  const handleScreenAuto = (e: any) => {
    let dom = listRef.current;
    if (dom) {
      //文档内容实际高度（包括超出视窗的溢出部分）
      let scrollHeight = Math.max(dom.scrollHeight, dom.scrollHeight);
      //滚动条滚动距离
      let scrollTop = e.target.scrollTop;
      //窗口可视范围高度
      let clientHeight = dom.clientHeight;

      if (clientHeight + scrollTop + 10 >= scrollHeight) {
        if (listDataRef.current.length === currentPageRef.current * 24) {
          currentPageRef.current += 1;

          setCurrentPage(currentPageRef.current);
        }
      }
    }
  };

  //React的生命周期 如果你是vue可以放到mountd或created中
  useEffect(() => {
    if (listRef.current) {
      listRef.current.addEventListener(
        "scroll",
        _.debounce(handleScreenAuto, 200),
        true
      );
      return () => {
        if (listRef.current) {
          listRef.current.removeEventListener(
            "scroll",
            _.debounce(handleScreenAuto, 200),
            true
          );
        }
      };
    }
  }, []);

  useEffect(() => {
    if (listRef.current) {
      listRef.current.scrollTop = 0;
    }
    listDataRef.current = [];
    currentPageRef.current = 1;
    setCurrentPage(1);
  }, [JSON.stringify(currentClickData)]);

  useEffect(() => {
    if (currentClickData.node_id) {
      getData(currentClickData);
    }
  }, [JSON.stringify(currentClickData), currentPage]);
  return (
    <>
      <div className={status === "expand" ? styles.PaperWin : styles.hide}>
        <span className={styles.title}>相关论文</span>
        <CommonTitle title={"相关论文"} className={styles.title} />
        <div className={styles.list} ref={listRef}>
          {Array.isArray(listData) &&
            listData.map((item: any) => (
              <div
                key={item.node_id}
                className={styles.listItem}
                data-id={item.node_id}
              >
                <span
                  className={styles.listName + " text-two-ellipsis "}
                  title={item.titleZh || item.title}
                  onClick={() =>
                    window.open(
                      `/graph_detail?id=${item.node_id}&node_type=paper`,
                      "_blank"
                    )
                  }
                >
                  {item.titleZh || item.title}
                </span>
                {Array.isArray(item.authors) && item.authors.length && (
                  <p
                    className={styles.authors + " text-ellipsis "}
                    title={item.authors
                      .map((item: any) => item.nameZh || item.name)
                      .join("，")}
                  >
                    {item.authors.map((item: any) => item.nameZh || item.name)}
                  </p>
                )}
                <div className={styles.bottomCount}>
                  <div className={styles.bottomDetail}>
                    <span className={styles.label}>引用量：</span>
                    <span className={styles.count}>{item.cite_num || 0}</span>
                  </div>
                  <div className={styles.bottomDetail}>
                    <span className={styles.label}>发表日期：</span>
                    <span className={styles.count}>
                      {item.pub_year || "--"}
                    </span>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </>
  );
}

export default PaperWin;
