import react, { ReactNode, useState, useEffect } from "react";
import Overview from "../../image/topBar/overview.svg";
import OverviewActive from "../../image/topBar/overview-active.svg";
import Industry from "../../image/topBar/industry.svg";
import IndustryActive from "../../image/topBar/industry-active.svg";
import Scholar from "../../image/topBar/scholar.svg";
import ScholarActive from "../../image/topBar/scholar-active.svg";
import Paper from "../../image/topBar/paper.svg";
import PaperActive from "../../image/topBar/paper-active.svg";
import Patent from "../../image/topBar/patent.svg";
import PatentActive from "../../image/topBar/patent-active.svg";
import styles from "./index.less";
import { Cascader } from "antd";
import tj from "@itic-sci/teamjob";

interface ITopBar {
  pageType: string;
  setPageType: any;
  data: any;
  setCurrentClickData: any;
}
interface ITopItem {
  key: string;
  name: string;
  countKey?: string;
  unit?: string;
  icon: ReactNode;
  activeIcon: ReactNode;
}

const listMap: Array<ITopItem> = [
  {
    key: "overview",
    name: "领域",
    icon: Overview,
    activeIcon: OverviewActive,
  },
  {
    key: "paper",
    name: "论文",
    countKey: "paper_count",
    icon: Paper,
    activeIcon: PaperActive,
    unit: "篇",
  },

  {
    key: "patent",
    name: "专利",
    countKey: "patent_count",
    icon: Patent,
    activeIcon: PatentActive,
    unit: "篇",
  },
  {
    key: "scholar",
    name: "学者",
    countKey: "scholar_count",
    icon: Scholar,
    activeIcon: ScholarActive,
    unit: "位",
  },

  {
    key: "industry",
    name: "机构",
    countKey: "org_count",
    icon: Industry,
    activeIcon: IndustryActive,
    unit: "所",
  },
  {
    key: "company",
    name: "企业",
    icon: Industry,
    activeIcon: IndustryActive,
  },
];

function TopBar(props: ITopBar) {
  const { pageType, setPageType, data, setCurrentClickData } = props;
  const handleTrack = (tag: string) => {
    if (tag === "paper") {
      tj.track({
        params: {
          // 上报参数
          event_id: "kgi_cells_and_genes_db_paper_click",
        },
      });
    } else if (tag === "scholar") {
      tj.track({
        params: {
          // 上报参数
          event_id: "kgi_cells_and_genes_db_scholar_click",
        },
      });
    } else if (tag === "industry") {
      tj.track({
        params: {
          // 上报参数
          event_id: "kgi_cells_and_genes_db_institution_click",
        },
      });
    } else if (tag === "patent") {
      tj.track({
        params: {
          // 上报参数
          event_id: "kgi_cells_and_genes_db_patent_click",
        },
      });
    } else if (tag === "company") {
      tj.track({
        params: {
          // 上报参数
          event_id: "kgi_cells_and_genes_db_enterprise_click",
        },
      });
    } else if (tag === "overview") {
      tj.track({
        params: {
          // 上报参数
          event_id: "kgi_cells_and_genes_db_field_click",
        },
      });
    }
  };
  const handleClick = (key: string) => {
    setPageType(key);
    handleTrack(key);
  };
  const onChange = (a: any, b: any) => {
    let raw = b[b.length - 1];
    setCurrentClickData({
      id: a[a.length - 1],
      node_type: "field",
      node_name: raw.name_zh,
      ...raw,
    });
  };
  return (
    <div className={styles.TopBar}>
      {data.length &&
        listMap.map((item) => (
          <div
            className={`${styles.item}   ${
              pageType === item.key ? styles["active"] : ""
            }`}
            key={item.key}
            onClick={() => handleClick(item.key)}
          >
            <p className={styles.overviewName}>{item.name}</p>
            {/* {item.key === "overview" && (
              <Cascader
                options={data[0].children}
                onChange={onChange}
                expandTrigger="hover"
                expandIcon={
                  <span
                    className={`iconfont icon-youjian ${styles.iconfont}`}
                  ></span>
                }
              >
                <p className={styles.overviewName}>
                  {item.name}
                  <span
                    className={`iconfont icon-down ${styles.iconfontDown}`}
                  ></span>
                </p>
              </Cascader>
            )}
            {item.key !== "overview" && (
              <p className={styles.overviewName}>{item.name}</p>
            )} */}
          </div>
        ))}
    </div>
  );
}

export default TopBar;
