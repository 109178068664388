import react, { useState, useEffect, useRef } from "react";
import student from "src/image/emoji/student.svg";
import building from "src/image/emoji/building.svg";
import bookmark from "src/image/emoji/bookmark.svg";
import bluebook from "src/image/emoji/bluebook.svg";
import { Routes, Route, Link, useNavigate } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import { exampleList } from "./data";
import { message, Popover, Tooltip } from "antd";
import loadingGif from "src/image/chatGraph/loading.gif";
import sendBtn from "src/image/sendBtn.png";
import sendBtnActive from "src/image/sendBtnActive.png";
import axios from "axios";
import _ from "lodash";
import tj from "@itic-sci/teamjob";

import remarkGfm from "remark-gfm";
import {
  getChatLike,
  getChatRecord,
  queryDataNew,
  testUpdate,
  queryRegen,
} from "src/services/api";
import Typewriter from "src/component/TypeWriter";
import FeedBackModal from "src/component/FeedBackModal";
import LoadingAnimation from "src/component/LoadingAnimation";
import styles from "./index.less";
interface IEntryPage {
  pageType?: string;
}

let CancelToken = axios.CancelToken;
let source = CancelToken.source();
let sse: any;

const detaultCardList = [
  {
    title: "查论文",
    icon: bluebook,
    desc: "支持通过领域词、机构、学者信息查论文，通过时间、引用量等条件进行筛选",
    key: "paper",
    list: [
      "有哪些关于基因芯片领域的论文可以参考？",
      "我想了解，麻省理工学院在今年的论文总量是多少？",
    ],
  },
  {
    title: "查专利",
    icon: bookmark,
    desc: "支持通过领域词、机构等信息找专利，通过时间进行筛选",
    key: "patent",
    list: [
      "在细胞转导技术领域，申请年份大于2010年的专利数量是多少？",
      "清华在生物育种发表的专利有哪些？",
    ],
  },
  {
    title: "找学者",
    icon: student,
    desc: "支持通过领域词、机构、发表论文量/引用量找学者，通过某年份论文量/引用量进行筛选",
    key: "scholar",
    list: [
      "请问一下研究细胞分离或者细胞存储的学者的hindex最高的是哪位？",
      "请问耶鲁大学有多少位致力于细胞转导技术研究的学者？",
    ],
  },
  {
    title: "找机构",
    icon: building,
    desc: "支持通过领域词、发表论文量/引用量找机构，通过某年份论文量/引用量进行筛选",
    key: "org",
    list: [
      "肿瘤诊断基因检测领域或是遗传病领域有哪些值得推荐的机构呢？",
      "2019年，在细胞与基因领域发表论文超过200篇的有哪些机构？",
    ],
  },
];

function EntryPage(props: IEntryPage) {
  const { pageType } = props;
  const textareaRef = useRef<any>();
  const navigate = useNavigate();

  const chatWapperRef = useRef<any>();
  const [, foceUpdate] = useState<any>(null);
  const loadingRef = useRef(false);
  const [sessionID, setsessionID] = useState<string>(
    (window as any).defaultsSessionID || ""
  );
  const questionListRef = useRef<any>([]);
  const [inputValue, setInputValue] = useState<string>("");
  const [cardList, setCardList] = useState<any>(detaultCardList);
  const [smallCardList, setSmallCardList] = useState<any>([]);
  const [currentIndex, setcurrentIndex] = useState<number>(0);
  const [feedBackWinVisiable, setFeedBackWinVisiable] =
    useState<boolean>(false);
  const [selectedData, setSelectedData] = useState<string[]>([]);
  const [showList, setShowList] = useState<any[]>(
    (window as any).defaultShowList || []
  );
  const divRef = useRef<HTMLDivElement>(null);
  const [hasShadow, setHasShadow] = useState(false);
  const [animationFinished, setAnimationFinished] = useState(true);
  const handleTrack = (tag: string) => {
    if (tag === "paper") {
      tj.track({
        params: {
          // 上报参数
          event_id: "kgi_cells_and_genes_chat_paper_switch_click",
        },
      });
    } else if (tag === "scholar") {
      tj.track({
        params: {
          // 上报参数
          event_id: "kgi_cells_and_genes_chat_scholar_switch_click",
        },
      });
    } else if (tag === "org") {
      tj.track({
        params: {
          // 上报参数
          event_id: "kgi_cells_and_genes_chat_institution_switch_click",
        },
      });
    } else if (tag === "patent") {
      tj.track({
        params: {
          // 上报参数
          event_id: "kgi_cells_and_genes_chat_patent_switch_click",
        },
      });
    }
  };
  const handleClickTrack = (tag: string) => {
    if (tag === "paper") {
      tj.track({
        params: {
          // 上报参数
          event_id: "kgi_cells_and_genes_chat_paper_questions_click",
        },
      });
    } else if (tag === "scholar") {
      tj.track({
        params: {
          // 上报参数
          event_id: "kgi_cells_and_genes_chat_scholar_questions_click",
        },
      });
    } else if (tag === "org") {
      tj.track({
        params: {
          // 上报参数
          event_id: "kgi_cells_and_genes_chat_institution_questions_click",
        },
      });
    } else if (tag === "patent") {
      tj.track({
        params: {
          // 上报参数
          event_id: "kgi_cells_and_genes_chat_patent_questions_click",
        },
      });
    }
  };
  const pickData = (type: string) => {
    let current: any = _.cloneDeep(cardList);
    let copyData = [...exampleList[type]];
    let newData: string[] = [];
    handleTrack(type);
    for (let i = 0; i < 2; i++) {
      const randomIndex = Math.floor(Math.random() * copyData.length);
      newData.push(copyData[randomIndex]);
      copyData.splice(randomIndex, 1);
    }
    let target = current.find((v: any) => v.key === type);
    if (target) {
      target.list = newData;
    }
    setCardList(current);
  };

  const pickSmallData = (tag?: any) => {
    let copyData: any = Object.values(exampleList).flat(9);
    let newData: string[] = [];

    for (let i = 0; i < 4; i++) {
      const randomIndex = Math.floor(Math.random() * copyData.length);
      newData.push(copyData[randomIndex]);
      copyData.splice(randomIndex, 1);
    }
    console.log(newData);
    if (tag) {
      tj.track({
        params: {
          // 上报参数
          event_id: "kgi_cells_and_genes_detail_chat_expand_switch_click",
        },
      });
    }

    setSmallCardList(newData);
  };
  const scrollToBottom = () => {
    if (divRef.current) {
      divRef.current.scrollTop = divRef.current.scrollHeight;
    }
  };

  const handleChatRecord = (data: any) => {
    getChatRecord({
      chat_res: data,
      sess_id: data.sess_id || "",
      chat_req: {
        query: data.query,
      },
      seq_no: Math.floor(showList.length / 2),
    });
  };

  const getData = async (str?: string, type?: string) => {
    if (loadingRef.current) {
      return;
    }
    let replace = false;

    loadingRef.current = true;
    foceUpdate({});
    setTimeout(() => {
      scrollToBottom();
    }, 0);
    if (!str || str.trim().length === 0) {
      message.error("请输入有效字符！");
      return;
    }
    try {
      let res: any = {};
      if (type) {
        replace = true;
      }
      // 点击重试的逻辑和正常的逻辑
      if (type !== "regen") {
        if (type === "retry") {
          let other = showList.slice(0, showList.length - 1);
          setShowList(other);
        }
        res = await queryDataNew(str.trim(), sessionID, {
          // signal: cancelReqRef.current.signal,
          cancelToken: source.token,
        });
        if (!res || !res.sess_id) {
          throw res;
        }
        // Create new EventSource
        const url = `https://llm.itic-sci.com/paper_link//stream_response?sess_id=${
          res.sess_id
        }&query=${str.trim()}`;

        setAnimationFinished(false);

        setsessionID(res.sess_id);
        (window as any).defaultsSessionID = res.sess_id;
        sse = new EventSource(url);
      } else {
        // 重新生成的逻辑
        const url = `https://llm.itic-sci.com/paper_link//regen?sess_id=${sessionID}`;
        setAnimationFinished(false);
        sse = new EventSource(url);
        let listBak = _.cloneDeep(showList);
        res = listBak[listBak.length - 1];
        res.status = "";
        res.copyed = false;
        let other = showList.slice(0, showList.length - 1);
        setShowList(other);
      }

      sse.addEventListener("message", (e: any) => {
        loadingRef.current = false;

        if (e.data.includes("|end|")) {
          sse.close();
          loadingRef.current = false;
          setAnimationFinished(true);
          setTimeout(() => {
            scrollToBottom();
          }, 200);
          handleChatRecord(res);
        }
        insertList(
          {
            ...res,
            response: e.data.replace("|end|", "").replace(/�/g, ""),
          },
          replace
        );
      });

      sse.addEventListener("error", function (e: any) {
        sse.close();
        insertList(
          {
            is_data_error: true,
            error: "unexpert",
          },
          replace
        );
        loadingRef.current = false;
      });
    } catch (e: any) {
      if (e) {
        let res: any = await testUpdate();
        if (res.code !== 0) {
          message.warning("系统升级中，部分服务暂不可用，还请见谅");
          return;
        }
        if (e.code === "ECONNABORTED") {
          message.error("请求超时");
        } else if (e.code === "ERR_CANCELED") {
          // message.error("已停止响应");
        } else {
          insertList(
            {
              is_data_error: true,
              error: "unexpert",
            },
            replace
          );
        }
      }
      loadingRef.current = false;
    }
    foceUpdate({});
  };

  useEffect(() => {
    let temp: any = _.cloneDeep(showList);
    let last = temp[temp.length - 1];
    if (temp && temp.length) {
      if (last.type === "question" && !last.autoSend) {
        updateList({ autoSend: true }, temp.length - 1);
        last.autoSend = true;
        getData(last.question);
      }
    }
    localStorage.setItem("chatHistory", JSON.stringify(temp));
    (window as any).defaultShowList = temp;
  }, [showList.length]);

  function handleCopy(text: string) {
    var textArea = document.createElement("textarea");
    textArea.value = text;

    // Avoid scrolling to bottom
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      var successful = document.execCommand("copy");
      var msg = successful ? "successful" : "unsuccessful";
      console.log("Fallback: Copying text command was " + msg);
      message.success("复制成功");
    } catch (err) {
      console.error("Fallback: Oops, unable to copy", err);
      message.error("复制失败");
    }

    document.body.removeChild(textArea);
  }

  useEffect(() => {
    textareaRef.current.style.height = "0";
    let calcHeight = inputValue.length ? textareaRef.current.scrollHeight : 28;
    calcHeight = Math.min(calcHeight, 104);
    calcHeight = Math.max(calcHeight, 28);

    textareaRef.current.style.height = `${calcHeight}px`;
    let wapperTop: any = document.querySelector("#wapperTop");
    if (wapperTop) {
      wapperTop.style.height = `calc(100vh - 240px - ${calcHeight - 28}px)`;
    }
  }, [inputValue]);

  const handleChange = (event: any) => {
    setInputValue(event.target.value);
  };

  // 更新第index项的值
  const updateList = (obj: any, index: number) => {
    let temp: any = _.cloneDeep(showList);
    Object.assign(temp[index], obj);
    setShowList(temp);
    localStorage.setItem("chatHistory", JSON.stringify(temp));
    (window as any).defaultShowList = temp;
  };
  const resetCopy = () => {
    let temp: any = _.cloneDeep(showList);
    temp.forEach((element: any) => {
      element.copyed = false;
    });
    setShowList(temp);
    localStorage.setItem("chatHistory", JSON.stringify(temp));
    (window as any).defaultShowList = temp;
  };
  const insertList = (obj: any, isReplace?: boolean) => {
    let listBak = _.cloneDeep(showList);
    if (isReplace) {
      listBak = listBak.slice(0, listBak.length - 1);
    }
    let temp = [...listBak, obj];
    setShowList(temp);

    localStorage.setItem("chatHistory", JSON.stringify(temp));
    (window as any).defaultShowList = temp;
  };

  const onDisLikeSuccess = (index: number) => {
    updateList({ status: "cai" }, index);
  };

  const handleLike = async (item: any, index: number) => {
    let res: any = {};
    try {
      if (showList && showList.length) {
        if (showList[index].status) {
          message.warning("反馈结果已提交");
          return;
        }
      }
      res = await getChatLike({
        sess_id: item.sess_id,
        seq_no: Math.floor(index / 2),
        user_id: item.user_id,
      });
      if (res.code === 0) {
        updateList({ status: "zan" }, index);

        message.success("提交成功");
      } else {
        message.success("提交失败，请重试");
      }
    } catch (error) {
      message.error("接口网络状态异常，请您稍后再试！");
    }
  };

  // 清空上下文
  const handleClear = () => {
    setsessionID("");
    setShowList([]);
    (window as any).animateShowList = [];

    (window as any).defaultsSessionID = "";
  };

  const handleKeyDown = (e: any) => {
    if (e.key === "Enter") {
      e.preventDefault(); // 阻止默认行为，比如换行

      if (!inputValue || inputValue.trim().length === 0) {
        message.error("请输入有效字符！");
        return;
      }
      if (!animationFinished || loadingRef.current) {
        message.warning("回答生成中，请稍后发送");
        return;
      }
      setShowList([
        ...showList,
        {
          question: inputValue,
          type: "question",
        },
      ]);
      setInputValue("");
    }
  };

  const handleJumpDetail = (params: any) => {
    localStorage.setItem("ChatDetailPage", JSON.stringify(params));
    window.open(`/ChatDetailPage`, "_blank");
  };

  const replaceWithLink = (state: any, text: string): string => {
    let modifiedText = text;
    let jumpType = ["org", "person", "paper"];

    state &&
      state.length &&
      state.forEach((org: any) => {
        if (jumpType.includes(org.entity_type)) {
          let str = org.name;
          if (str[str.length - 1] === ".") {
            str = str.substr(0, str.length - 1);
          }
          const link = `[${str}](/graph_detail?id=${org.entity_id}&node_type=${org.entity_type})`;
          modifiedText = modifiedText.replaceAll(str, link);
        }
      });
    return modifiedText;
  };

  const components = {
    a: ({
      href,
      children,
      ...props
    }: any & { href?: string; children?: React.ReactNode }) => {
      if (href && href.startsWith("/")) {
        return (
          <Link to={href} className={styles.linkHover}>
            {children}
          </Link>
        );
      }
      return (
        <a
          href={href}
          {...props}
          rel="noopener noreferrer"
          target="_blank"
          className={styles.linkHover}
        >
          {children}
        </a>
      );
    },
  };

  useEffect(() => {
    const handleScroll = () => {
      if (divRef.current) {
        console.log(divRef.current.scrollTop);
        setHasShadow(divRef.current.scrollTop > 0);
      }
    };
    pickSmallData();

    divRef.current?.addEventListener("scroll", handleScroll);
    if (pageType !== "small") {
      tj.track({
        params: {
          // 上报参数
          event_id: "kgi_cells_and_genes_chat_enter",
        },
      });
    }

    return () => {
      divRef.current?.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div className={styles.EntryPage}>
      {!showList.length && (
        <>
          {pageType !== "small" && (
            <div className={styles.wapperTop} id="wapperTop">
              {/* <div className={styles.wapperTopCardGradientTop}></div> */}
              <div className={styles.wapperTopCardGradientBottom}></div>
              <div className={styles.wapperTopCard}>
                <div className="relative w-[1000px] h-[124px] bg-white rounded-[8px] [background:linear-gradient(180deg,rgb(229.19,244.16,255)_0%,rgb(232.22,238.6,255)_100%)]">
                  <div className="top-[55px] [font-family:'PingFang_SC-Regular',Helvetica] font-normal text-[#263b5f] text-[14px] leading-[14px] absolute left-[24px] tracking-[0]">
                    您可以跟我聊天提问，我可以帮您找论文，找专家、查专利、搜机构，还能答疑解惑。
                  </div>
                  <p className="top-[83px] [font-family:'PingFang_SC-Regular',Helvetica] font-normal text-transparent text-[14px] leading-[14px] absolute left-[24px] tracking-[0]">
                    <span className="text-[#263b5f]">想知道我还能做什么？</span>
                    <span
                      onClick={() => {
                        navigate("/Guide?target=chat");
                      }}
                      className="cursor-pointer [font-family:'PingFang_SC-Medium',Helvetica] font-medium text-[#316efc] hover:text-[rgba(49,110,252,0.7)]"
                    >
                      点这里快速上手！
                    </span>
                    <span className="text-[#263b5f]">
                      目前我还在不断学习中，如果我出现错误，请您及时联系我，帮助我提升。
                    </span>
                  </p>
                  <div className="top-[19px] [font-family:'PingFang_SC-Medium',Helvetica] font-medium text-[#263b5f] text-[20px] leading-[normal] absolute left-[24px] tracking-[0]">
                    您好，我是您的科研小助手
                  </div>
                </div>

                {cardList.map((item: any) => (
                  <div className="w-[488px] h-[181px]" key={item.key}>
                    <div className="relative h-[181px] rounded-[8px]">
                      <div className="absolute w-[492px] h-[181px] top-0 left-0 bg-[#fafbff] rounded-[8px]">
                        <div
                          onClick={() => pickData(item.key)}
                          className="cursor-pointer text-[#263b5f80] hover:text-[#54617f]  relative w-[54px] h-[14px] top-[24px] left-[414px]"
                        >
                          <span
                            className="iconfont icon-huanyipi absolute w-[14px] h-[14px] top-0 left-0"
                            style={{
                              marginRight: 6,
                              fontSize: 12,
                            }}
                          ></span>
                          <div className="absolute top-[-6px] left-[18px] [font-family:'PingFang_SC-Regular',Helvetica] font-normal  text-[12px] text-right tracking-[0] leading-[24px] whitespace-nowrap">
                            换一批
                          </div>
                        </div>
                      </div>
                      <div className="absolute w-[120px] h-[29px] top-[16px] left-[24px]">
                        <img
                          className="absolute w-[32px] h-[32px] top-[-4px] left-0"
                          alt="Twemoji blue book"
                          src={item.icon}
                        />
                        <div className="absolute -top-px left-[40px] [font-family:'PingFang_SC-Medium',Helvetica] font-medium text-[#263b5f] text-[20px] tracking-[0] leading-[normal]">
                          {item.title}：
                        </div>
                      </div>
                      <div className="w-[444px] top-[52px] text-[#263b5fcc]  absolute left-[24px] [font-family:'PingFang_SC-Regular',Helvetica] font-normal text-[14px] tracking-[0]">
                        {item.desc}
                      </div>
                      <p className="w-[460px] top-[110px] text-transparent leading-[24px] absolute left-[24px] [font-family:'PingFang_SC-Regular',Helvetica] font-normal text-[14px] tracking-[0]">
                        {item.list.map((v: string, index: number) => (
                          <span
                            key={v + index}
                            title={v}
                            className="w-[460px] inline-block cursor-pointer text-[#316efc] hover:text-[#6594fd] text-ellipsis"
                            onClick={() => {
                              handleClickTrack(item.key);
                              setShowList([
                                {
                                  question: v,
                                  type: "question",
                                },
                              ]);
                            }}
                          >
                            {v}
                            <br />
                          </span>
                        ))}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}

          {pageType === "small" && (
            <div className={styles.wapperSmallTopCt} key={smallCardList[0]}>
              <div className={styles.wapperSmallTop}>
                <div className={styles.wapperTopTitle}>
                  您好，我是您的科研小助手
                </div>
                <div className={styles.wapperTopDesc}>
                  您可以跟我聊天提问，我可以帮您找论文，找专家、查专利、搜机构，还能答疑解惑。
                </div>
                <div className={styles.wapperSub}>
                  <span className={styles.wapperSubDesc}>你可以试着问我</span>
                  <div
                    onClick={() => pickSmallData(true)}
                    className={styles.wapperSmallBtn}
                  >
                    <span
                      className={`${styles.wapperBtnIcon} iconfont icon-huanyipi `}
                      style={{
                        marginRight: 6,
                        fontSize: 12,
                      }}
                    ></span>
                    <div className={styles.wapperBtnText}>换一批</div>
                  </div>
                </div>
                <div className={styles.smallListCt}>
                  <span
                    key={smallCardList[0]}
                    title={smallCardList[0]}
                    className="w-[320px] inline-block cursor-pointer text-[#316efc] hover:text-[#6594fd] text-ellipsis"
                    onClick={() => {
                      tj.track({
                        params: {
                          // 上报参数
                          event_id:
                            "kgi_cells_and_genes_detail_chat_expand_questions_click",
                        },
                      });

                      setShowList([
                        {
                          question: smallCardList[0],
                          type: "question",
                        },
                      ]);
                    }}
                  >
                    {smallCardList[0]}
                    <br />
                  </span>

                  <span
                    key={smallCardList[1]}
                    title={smallCardList[1]}
                    className="w-[320px] inline-block cursor-pointer text-[#316efc] hover:text-[#6594fd] text-ellipsis"
                    onClick={() =>
                      setShowList([
                        {
                          question: smallCardList[1],
                          type: "question",
                        },
                      ])
                    }
                  >
                    {smallCardList[1]}
                    <br />
                  </span>
                  <span
                    key={smallCardList[2]}
                    title={smallCardList[2]}
                    className="w-[320px] inline-block cursor-pointer text-[#316efc] hover:text-[#6594fd] text-ellipsis"
                    onClick={() =>
                      setShowList([
                        {
                          question: smallCardList[2],
                          type: "question",
                        },
                      ])
                    }
                  >
                    {smallCardList[2]}
                    <br />
                  </span>
                  <span
                    key={smallCardList[3]}
                    title={smallCardList[3]}
                    className="w-[320px] inline-block cursor-pointer text-[#316efc] hover:text-[#6594fd] text-ellipsis"
                    onClick={() =>
                      setShowList([
                        {
                          question: smallCardList[3],
                          type: "question",
                        },
                      ])
                    }
                  >
                    {smallCardList[3]}
                    <br />
                  </span>
                </div>
              </div>
            </div>
          )}
        </>
      )}
      {pageType !== "small" && !!showList.length && (
        <div className={`${hasShadow ? styles.shadow : ""}`}></div>
      )}

      {pageType !== "small" && !!showList.length && (
        <div className={styles.shadowBottom}></div>
      )}

      <div
        className={`${styles.chatWapperScroll} ${
          !showList.length && styles.hide
        }`}
        ref={divRef}
      >
        <div
          className={styles.chatWapper}
          ref={chatWapperRef}
          style={{
            padding:
              pageType === "small" ? "40px 20px 0 20px" : "16px 0px 0 0px",
          }}
        >
          <p className={styles.chatTip}>
            对话由AI提供支持，可能出现意外和错误，请确保核对事实
          </p>
          {showList.map((item: any, index: number) => {
            return item.type === "question" ? (
              <div className={styles.wapperLayer} key={index}>
                <p className={styles.question}>
                  {item.question}
                  <span
                    className={styles.wapperCopy}
                    onClick={() => {
                      console.log(item);
                      handleCopy(item.question);
                    }}
                  >
                    <span className="iconfont icon-copy"></span>
                    {/* <span className={styles.copyBtn}>复制</span> */}
                  </span>
                </p>
              </div>
            ) : (
              <div className={styles.wapperResult} key={index}>
                <p className={styles.resText}>
                  {index === showList.length - 1 ? (
                    <>
                      {item.is_data_error && (
                        <>
                          {item.error === "unexpert" ? (
                            <div className={styles.notLink}>
                              <p className={styles.title}>
                                很抱歉，出了一点问题
                              </p>
                              <p className={styles.content}>
                                导致我们无法完成您的请求。请检查您的网络连接或稍后重试，或者您也可以尝试联系我们的技术支持团队，他们能够为您提供更具体的帮助。谢谢您的理解和耐心！
                              </p>
                              <span
                                className={styles.btn}
                                onClick={() => {
                                  // setShowList(
                                  //   showList.slice(0, showList.length - 1)
                                  // );
                                  let questionList = showList.filter(
                                    (v) => v.type === "question"
                                  );
                                  getData(
                                    questionList[questionList.length - 1]
                                      .question,
                                    "retry"
                                  );
                                }}
                              >
                                点击重试
                              </span>
                            </div>
                          ) : (
                            <p className={styles.errorLinkPart}>
                              很抱歉，查询服务出了一点问题。您可以稍后重试或者我们先聊点别的~
                              <span
                                className={styles.retryLink}
                                onClick={() => {
                                  // setShowList(
                                  //   showList.slice(0, showList.length - 1)
                                  // );
                                  let questionList = showList.filter(
                                    (v) => v.type === "question"
                                  );
                                  getData(
                                    questionList[questionList.length - 1]
                                      .question,
                                    "retry"
                                  );
                                }}
                              >
                                点击重试
                              </span>
                            </p>
                          )}
                        </>
                      )}
                      {!item.is_data_error && (
                        <>
                          {item.pre_response && item.task && (
                            <p className={styles.noResultTip}>
                              {item.pre_response}
                            </p>
                          )}
                          <ReactMarkdown
                            className={styles.wapperMarkdown}
                            components={components}
                            children={replaceWithLink(
                              item.state,
                              item.pre_response && !item.task
                                ? item.pre_response
                                : item.response.replace(/\n/g, "  \n")
                            )}
                            remarkPlugins={[remarkGfm]}
                          />

                          {animationFinished && (
                            <p className={styles.explainText}>
                              {item.task &&
                                !location.href.includes("graph_detail") && (
                                  <div className={styles.left}>
                                    <span className="iconfont icon-tishi"></span>
                                    上述回答参考数据库检索结果生成{" "}
                                    {item?.command?.select !== "领域" && (
                                      <>
                                        ，点击查看
                                        <span
                                          className={styles.link}
                                          onClick={() =>
                                            handleJumpDetail(item.command)
                                          }
                                        >
                                          检索详情
                                        </span>
                                      </>
                                    )}
                                  </div>
                                )}
                              {item.task &&
                                location.href.includes("graph_detail") && (
                                  <div className={styles.left}>
                                    <span className="iconfont icon-tishi"></span>

                                    {item?.command?.select !== "领域" && (
                                      <>
                                        点击查看
                                        <span
                                          className={styles.link}
                                          onClick={() =>
                                            handleJumpDetail(item.command)
                                          }
                                        >
                                          检索详情
                                        </span>
                                      </>
                                    )}
                                  </div>
                                )}
                              {/* {!item.task && <span></span>} */}

                              <span className={styles.feedback}>
                                <Tooltip title="复制" color={"#6f737c"}>
                                  <span
                                    className={`${
                                      item.copyed && styles.copyed
                                    } iconfont ${
                                      item.copyed
                                        ? "icon-fuzhichenggong"
                                        : "icon-fuzhi"
                                    }`}
                                    onClick={() => {
                                      handleCopy(
                                        item.pre_response && !item.task
                                          ? item.pre_response
                                          : item.response.replace(/\n/g, "  \n")
                                      );
                                      updateList({ copyed: true }, index);
                                      setTimeout(() => {
                                        resetCopy();
                                      }, 5000);
                                    }}
                                  ></span>
                                </Tooltip>
                                <Tooltip title="喜欢" color={"#6f737c"}>
                                  <span
                                    className={`${
                                      item.status === "zan" && styles.zanActive
                                    }  ${
                                      (item.status === "zan" ||
                                        item.status === "cai") &&
                                      styles.cursorDisable
                                    } iconfont icon-zan`}
                                    onClick={() => handleLike(item, index)}
                                  ></span>
                                </Tooltip>
                                <Tooltip title="不喜欢" color={"#6f737c"}>
                                  <span
                                    className={`${
                                      item.status === "cai" && styles.zanActive
                                    }  ${
                                      (item.status === "zan" ||
                                        item.status === "cai") &&
                                      styles.cursorDisable
                                    } iconfont icon-cai`}
                                    onClick={() => {
                                      if (item.status) {
                                        message.warning("反馈结果已提交");
                                        return;
                                      }
                                      setcurrentIndex(index);
                                      setFeedBackWinVisiable(true);
                                    }}
                                  ></span>
                                </Tooltip>
                                <Tooltip title="重新生成" color={"#6f737c"}>
                                  <span
                                    className="iconfont icon-zhongxinshengcheng"
                                    onClick={() => {
                                      // setShowList(
                                      //   showList.slice(0, showList.length - 1)
                                      // );
                                      let questionList = showList.filter(
                                        (v) => v.type === "question"
                                      );
                                      getData(
                                        questionList[questionList.length - 1]
                                          .question,
                                        "regen"
                                      );
                                    }}
                                  ></span>
                                </Tooltip>
                              </span>
                            </p>
                          )}
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      {item.is_data_error && (
                        <>
                          {item.error === "unexpert" ? (
                            <div>
                              <p>很抱歉，出了一点问题</p>
                              <p>
                                导致我们无法完成您的请求。请检查您的网络连接或稍后重试，或者您也可以尝试联系我们的技术支持团队，他们能够为您提供更具体的帮助。谢谢您的理解和耐心！
                              </p>
                            </div>
                          ) : (
                            <p className={styles.errorLinkPart}>
                              很抱歉，查询服务出了一点问题。您可以稍后重试或者我们先聊点别的~
                            </p>
                          )}
                        </>
                      )}
                      {!item.is_data_error && (
                        <div className={styles.preResult}>
                          {item.pre_response && item.task && (
                            <p className={styles.noResultTip}>
                              {item.pre_response}
                            </p>
                          )}
                          <ReactMarkdown
                            className={styles.wapperMarkdown}
                            components={components}
                            children={replaceWithLink(
                              item.state,
                              item.pre_response && !item.task
                                ? item.pre_response
                                : item.response.replace(/\n/g, "  \n")
                            )}
                            remarkPlugins={[remarkGfm]}
                          />

                          <p className={styles.explainText}>
                            {item.task &&
                              !location.href.includes("graph_detail") && (
                                <div className={styles.left}>
                                  <span className="iconfont icon-tishi"></span>
                                  上述回答参考数据库检索结果生成{" "}
                                  {item?.command?.select !== "领域" && (
                                    <>
                                      ，点击查看
                                      <span
                                        className={styles.link}
                                        onClick={() =>
                                          handleJumpDetail(item.command)
                                        }
                                      >
                                        检索详情
                                      </span>
                                    </>
                                  )}
                                </div>
                              )}
                            {item.task &&
                              location.href.includes("graph_detail") && (
                                <div className={styles.left}>
                                  <span className="iconfont icon-tishi"></span>
                                  {item?.command?.select !== "领域" && (
                                    <>
                                      点击查看
                                      <span
                                        className={styles.link}
                                        onClick={() =>
                                          handleJumpDetail(item.command)
                                        }
                                      >
                                        检索详情
                                      </span>
                                    </>
                                  )}
                                </div>
                              )}
                          </p>
                          <span className={styles.feedbackPopover}>
                            <Tooltip
                              title="复制"
                              color={"#6f737c"}
                              overlayStyle={{ pointerEvents: "none" }}
                            >
                              <span
                                className={`${
                                  item.copyed && styles.copyed
                                } iconfont ${
                                  item.copyed
                                    ? "icon-fuzhichenggong"
                                    : "icon-fuzhi"
                                }`}
                                onClick={() => {
                                  handleCopy(
                                    item.pre_response && !item.task
                                      ? item.pre_response
                                      : item.response.replace(/\n/g, "  \n")
                                  );
                                  updateList({ copyed: true }, index);
                                  setTimeout(() => {
                                    resetCopy();
                                  }, 5000);
                                }}
                              ></span>
                            </Tooltip>

                            <Tooltip
                              title="喜欢"
                              color={"#6f737c"}
                              overlayStyle={{ pointerEvents: "none" }}
                            >
                              <span
                                className={`${
                                  item.status === "zan" && styles.zanActive
                                } ${
                                  (item.status === "zan" ||
                                    item.status === "cai") &&
                                  styles.cursorDisable
                                } iconfont icon-zan`}
                                onClick={() => handleLike(item, index)}
                              ></span>
                            </Tooltip>

                            <Tooltip
                              title="不喜欢"
                              color={"#6f737c"}
                              overlayStyle={{ pointerEvents: "none" }}
                            >
                              <span
                                className={`${
                                  item.status === "cai" && styles.zanActive
                                } ${
                                  (item.status === "zan" ||
                                    item.status === "cai") &&
                                  styles.cursorDisable
                                } iconfont icon-cai`}
                                onClick={() => {
                                  if (item.status) {
                                    message.warning("反馈结果已提交");
                                    return;
                                  }
                                  setcurrentIndex(index);
                                  setFeedBackWinVisiable(true);
                                }}
                              ></span>
                            </Tooltip>
                          </span>
                        </div>
                      )}
                    </>
                  )}
                </p>

                {/* <Table data={resData} /> */}
              </div>
            );
          })}
          {loadingRef.current && (
            <div className={styles.loadingCt}>
              <div className={styles.loading}>
                <img src={loadingGif} alt="" />
                <span>
                  正在思考，请稍等
                  {/* <span className={styles.blink}>_</span> */}
                </span>
              </div>
              <span
                className={styles.cancelBtn}
                onClick={() => {
                  loadingRef.current = false;
                  source.cancel("停止响应");
                  source = CancelToken.source();
                  if (sse) {
                    sse.close();
                    setAnimationFinished(true);
                    // message.error("已停止响应");
                  }
                  message.error("已停止响应");

                  // setQuestion("");
                  foceUpdate({});
                }}
              >
                停止生成
              </span>
            </div>
          )}
          {!loadingRef.current && (
            <p className={styles.bottomTip}>
              <span className={styles.clearBtn} onClick={() => handleClear()}>
                清空
              </span>
              上下文，开始新对话
            </p>
          )}
        </div>
      </div>

      <div
        className={
          pageType !== "small" ? styles.sendWapper : styles.sendWapperSmall
        }
      >
        <div className={styles.sendPart}>
          <textarea
            name=""
            ref={textareaRef}
            onKeyDown={handleKeyDown}
            id=""
            value={inputValue}
            onChange={handleChange}
            rows={3}
            className={styles.sendTextarea}
            placeholder="问我点图谱相关的问题或者随便聊聊~"
          ></textarea>
          <img
            src={sendBtnActive}
            alt=""
            onClick={() => {
              if (!inputValue || inputValue.trim().length === 0) {
                message.error("请输入有效字符！");
                return;
              }

              if (!animationFinished || loadingRef.current) {
                message.warning("回答生成中，请稍后发送");
                return;
              }
              tj.track({
                params: {
                  // 上报参数
                  event_id:
                    pageType !== "small"
                      ? "kgi_cells_and_genes_chat_sending_click"
                      : "kgi_cells_and_genes_detail_chat_expand_sending_click",
                },
              });
              setShowList([
                ...showList,
                {
                  question: inputValue.trim(),
                  type: "question",
                },
              ]);
              setInputValue("");
            }}
            className={`${styles.sendBtn} ${!inputValue.length && styles.hide}`}
          />
          <img
            src={sendBtn}
            alt=""
            className={`${styles.sendBtn} ${inputValue.length && styles.hide}`}
          />
        </div>
      </div>
      {feedBackWinVisiable && (
        <FeedBackModal
          setFeedBackWinVisiable={setFeedBackWinVisiable}
          sessionID={sessionID}
          currentIndex={currentIndex}
          onSuccess={onDisLikeSuccess}
        />
      )}
    </div>
  );
}

export default EntryPage;
