import react, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
  useRef,
  useContext,
} from "react";
import _ from "lodash";
import styles from "./index.less";
import InputClear from "../../image/inputClear.svg";
import Empty from "src/image/ListEmpty.svg";
import ExpandableText from "src/component/ExpandableTextScreen/index";
import DefaultScholar from "src/image/paper/defaultScholar.svg";
import DefaultIndustry from "src/image/defaultIndustry.svg";
import { getScholarList } from "../../services/api";
import { UserContext } from "src/Context";
import {
  Pagination,
  Select,
  Input,
  ConfigProvider,
  message,
  Popover,
} from "antd";
import zhCN from "antd/lib/locale/zh_CN";
export interface IListData {
  scholar_name: string;
  avatar: string;
  org_name: string;
  region: string;
  npubs: number;
  profile: string;
  org_nameZh: string;
  scholar_nameZh: string;
  city: string;
  ncitation: string;
  hindex: number;
}
interface IScholar {
  leftCurrentClickData: any;
  pageType: string;
}

function Scholar(props: IScholar, ref: any) {
  const listRef = useRef<HTMLDivElement>(null);
  const currentPageRef = useRef(1);
  const { isloading, setIsLoading } = useContext(UserContext);
  const listDataRef = useRef([]);
  const inputRef = useRef(null);

  const [searchStr, setSearchStr] = useState<string>("");
  const { leftCurrentClickData, pageType } = props;
  const [sortType, setSortType] = useState<
    "ncitation" | "npubs" | "hindex" | "weight"
  >("weight");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [total, setTotal] = useState<number>(1);
  const [listData, setListData] = useState<Array<IListData>>([]);
  const [checkBoxState, setCheckBoxState] = useState(false);
  useImperativeHandle(ref, () => ({}));
  const getListData = async (params: any) => {
    setIsLoading(true);
    let res: any = {};
    try {
      res = await getScholarList({
        node_name: params.name_zh,
        node_layer: params.layer,
        sort_field: sortType,
        size: pageSize,
        page: currentPage,
        is_sz: checkBoxState ? 1 : 0,
        keyword: searchStr.trim(),
      });
    } catch (error) {
      message.error("接口网络状态异常，请您稍后再试！");
    }

    setIsLoading(false);

    if (res.code === 0) {
      let temp = res.data.scholars;

      setListData(temp);
      setTotal(res.data.total);
      listDataRef.current = temp;
    }
    setIsLoading(false);
  };

  const handleChange = (page: number, pageSize: number) => {
    setPageSize(pageSize);
    setCurrentPage(page);
  };

  useEffect(() => {
    if (listRef.current) {
      listRef.current.scrollTop = 0;
    }
    getListData(leftCurrentClickData);
  }, [currentPage, pageSize]);

  useEffect(() => {
    if (currentPage !== 1) {
      setCurrentPage(1);
    } else {
      if (listRef.current) {
        listRef.current.scrollTop = 0;
      }
      getListData(leftCurrentClickData);
    }
  }, [
    JSON.stringify(leftCurrentClickData),
    checkBoxState,
    sortType,
    searchStr,
  ]);
  const renderPop = (author: any) => {
    return (
      <div key={author.node_id} className={styles.orgItem}>
        <img
          src={author.org_card.image || DefaultIndustry}
          alt=""
          onClick={() =>
            window.open(`/graph_detail?id=${author.org_id}&node_type=org`)
          }
          className={styles.leftIcon}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = DefaultIndustry;
          }}
        />
        <div className={styles.rightPart}>
          <div className={styles.topInfo}>
            <p
              title={author.org_card.org_nameZh || author.org_card.org_name}
              className={styles.topTitle + " text-ellipsis "}
              onClick={() => {
                if (author.aminer_id) {
                  window.open(
                    `/graph_detail?id=${author.org_id}&node_type=org`,
                    "_blank"
                  );
                }
              }}
            >
              {author.org_card.org_nameZh || author.org_card.org_name}
            </p>
          </div>
          <div className={styles.bottomInfo}>
            <div className={styles.bottomCount}>
              <div className={styles.bottomDetail}>
                <span className={styles.label}>论文数：</span>
                <span className={styles.count}>
                  {author.org_card.n_papers === 10000
                    ? "10000+"
                    : author.org_card.n_papers || "0"}
                </span>
              </div>
              <div className={styles.bottomDetail}>|</div>
              <div className={styles.bottomDetail}>
                <span className={styles.label}>引用数：</span>
                <span className={styles.count}>
                  {author.org_card.n_citation || "0"}
                </span>
              </div>
              <div className={styles.bottomDetail}>|</div>
              <div
                className={`${styles.bottomDetail} ${styles.bottomDetailLeft}`}
              >
                <span className={styles.label}>相关实体：</span>
                <span className={styles.count}>
                  {author.org_card.sub_graph_total || "0"}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <>
      {/* <SearchPart
        searchTag={tagMap}
        searchStr={searchStr}
        setSearchStr={setSearchStr}
      /> */}
      <div className={styles.scholar}>
        <div className={styles.topBar}>
          <span className={styles.title}>细胞与基因</span>

          <div className={styles.sortCt}>
            <div className={styles.checkBox}>
              <span
                onClick={() => setCheckBoxState(!checkBoxState)}
                className={`${""}  ${
                  checkBoxState ? styles.checkBoxImgActive : styles.checkBoxImg
                }`}
              ></span>
              <span className={styles.checkBoxText}>只看深圳</span>
            </div>
            <Select
              defaultValue="weight"
              className={styles.selectBox}
              onChange={(value: any) => {
                setSortType(value);
              }}
              suffixIcon={
                <span
                  className={`iconfont icon-down ${styles.iconfont}`}
                ></span>
              }
              options={[
                { value: "weight", label: "综合" },
                { value: "npubs", label: "论文数" },

                { value: "ncitation", label: "引用量" },
                { value: "h_index", label: "hindex" },
              ]}
            />
            <Input
              className={styles.searchBox}
              ref={inputRef}
              placeholder="请输入要搜索的内容"
              allowClear={{
                clearIcon: (
                  <img
                    src={InputClear}
                    alt=""
                    className={styles.closeImg}
                    onClick={() => {
                      setSearchStr("");
                    }}
                  />
                ),
              }}
              onPressEnter={() => {
                if (inputRef.current) {
                  setSearchStr((inputRef.current as any).input.value);
                }
              }}
              suffix={
                <span
                  className={`iconfont icon-sousuo ${styles.iconfont}`}
                  onClick={() => {
                    if (inputRef.current) {
                      setSearchStr((inputRef.current as any).input.value);
                    }
                  }}
                ></span>
              }
            />
          </div>
        </div>
        {Array.isArray(listData) && !!listData.length && (
          <>
            <div className={styles.listCt} ref={listRef}>
              {Array.isArray(listData) &&
                listData.map((item: any, index) => (
                  <div key={index} className={styles.listItem}>
                    <img
                      src={item.avatar || DefaultScholar}
                      alt=""
                      onClick={() =>
                        window.open(
                          `/graph_detail?id=${item.node_id}&node_type=${item.node_type}`
                        )
                      }
                      className={styles.leftIcon}
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = DefaultScholar;
                      }}
                    />
                    <div className={styles.rightPart}>
                      <div className={styles.topInfo}>
                        <p
                          className={styles.topTitle + " text-two-ellipsis "}
                          title={item.scholar_nameZh || item.scholar_name}
                          onClick={() =>
                            window.open(
                              `/graph_detail?id=${item.node_id}&node_type=${item.node_type}`
                            )
                          }
                        >
                          {item.scholar_nameZh || item.scholar_name}
                        </p>
                        {item.scholar_nameZh && item.scholar_name && (
                          <span className={styles.region}>
                            （{item.scholar_name}）
                          </span>
                        )}
                      </div>
                      {item.org_card && (
                        <Popover
                          arrow={false}
                          overlayClassName={styles.popover}
                          key={item.scholar_name}
                          content={renderPop(item)}
                          placement="bottom"
                          trigger="hover"
                        >
                          <p
                            className={`${styles.belongOrg} ${
                              styles.belongOrgActive
                            } ${
                              (
                                item.org_card.org_nameZh ||
                                item.org_card.org_name
                              ).length > 120 && styles.belongOrgLong
                            } text-two-ellipsis`}
                            onClick={() =>
                              window.open(
                                `/graph_detail?id=${item.org_id}&node_type=org`
                              )
                            }
                          >
                            {item.org_card.org_nameZh || item.org_card.org_name}
                          </p>
                        </Popover>
                      )}

                      {!item.org_card && (
                        <p
                          className={`${styles.belongOrg} ${
                            (item.org_nameZh || item.org_name).length > 120 &&
                            styles.belongOrgLong
                          } text-two-ellipsis`}
                          title={item.org_nameZh || item.org_name || "--"}
                        >
                          {item.org_nameZh || item.org_name}
                        </p>
                      )}
                      <div className={styles.bottomInfo}>
                        <div className={styles.bottomCount}>
                          <div
                            className={`${styles.bottomDetail} ${styles.bottomDetailLeft}`}
                          >
                            <span className={styles.label}>H-index：</span>
                            <span className={styles.count}>
                              {item.hindex || "0"}
                            </span>
                          </div>
                          <div className={styles.bottomDetail}>
                            <span className={styles.label}>引用量：</span>
                            <span className={styles.count}>
                              {item.ncitation || "0"}
                            </span>
                          </div>
                          <div className={styles.bottomDetail}>
                            <span className={styles.label}>论文数：</span>
                            <span className={styles.count}>
                              {item.npubs === 10000
                                ? "10000+"
                                : item.npubs || "0"}
                            </span>
                          </div>
                          <div className={styles.bottomDetail}>
                            <span className={styles.label}>相关实体：</span>
                            <span className={styles.count}>
                              {item.sub_graph_total || "0"}
                            </span>
                          </div>
                        </div>
                      </div>

                      <ExpandableText
                        text={item.profile}
                        key={item.node_id}
                        index={index}
                        pageType={pageType}
                      />
                    </div>
                  </div>
                ))}
            </div>
            <div className={styles.footerBar}>
              <ConfigProvider locale={zhCN}>
                <Pagination
                  total={total}
                  showSizeChanger
                  pageSize={pageSize}
                  current={currentPage}
                  onChange={handleChange}
                  defaultCurrent={1}
                  className={styles.pagination}
                  showQuickJumper
                />
              </ConfigProvider>
              <div className={styles.totalBar}>共 {total} 个结果 </div>
            </div>
          </>
        )}
        {Array.isArray(listData) && listData.length === 0 && (
          <div className={styles.emptyPart}>
            <img src={Empty} alt="" />
            <p>找不到相关内容</p>
          </div>
        )}
      </div>
    </>
  );
}

export default forwardRef(Scholar);
