const baseNumber = 1; // 获取相对好计算的基数

export function setHtmlFontSize(designWidth = 1920) {
  // 设计稿宽度为1920，利用移动端rem的适配方案，动态修改html的值
  const clientWidth = Math.max(document.documentElement.clientWidth, 1366);

  // 选取的是 1920 ， * 100是为了防止浏览器默认行为，如果小于12px默认会处理为12px进行计算。在css中一定要除以100.
  var fontSize = (clientWidth / designWidth) * baseNumber; // 计算完毕后，在设计稿上量出的尺寸直接写入，只是把px换成rem即可，其他内部需要动态设置为px的可以乘这个基数（size * fontSize / 100 + 'px'）
  var elHtml = document.getElementsByTagName("html")[0];

  // elHtml.style.fontSize = (fontSize > 80 ? 80 : fontSize < 50 ? 50 : fontSize) + 'px'; // 规定上限在80px，下限在50px，上下限值根据需求进行调整，也可以不进行调整。
  elHtml.style.fontSize = fontSize + "px";
}

/**
 * 获取当前的实际像素
 */
export function getUnitRem(px) {
  if (typeof px === "string") px = parseFloat(px);
  return (isNaN(px) ? 0 : px / baseNumber) + "rem";
}
