import React from "react";

import styles from "./index.less";
const operateMap = {
  eq: "",
  lt: "小于",
  gt: "大于",
};
const Component = (props) => {
  let { chatDetailPageParams } = props;

  if (!chatDetailPageParams || !chatDetailPageParams.where) {
    return <></>;
  }
  return (
    <div className={styles.rootFrame2863}>
      <p className={styles.label}>解析聊天内容后在数据库中执行了如下检索：</p>
      <div className={styles.flex}>
        <p className={styles.label}>检索对象：</p>
        <div className={styles.content}>
          <p className={styles.text2}>{chatDetailPageParams.select}</p>
        </div>
      </div>
      <div className={styles.flex}>
        <p className={styles.label}>检索条件：</p>
        <div className={styles.content} style={{ top: "-5px" }}>
          {chatDetailPageParams.where.length === 0 && (
            <span className={styles.emptyString}>--</span>
          )}
          {chatDetailPageParams.where.length > 0 &&
            chatDetailPageParams.where.map((item) => (
              <div className={styles.item}>
                <span className={styles.text}>{item.name}：</span>
                <span className={styles.text1}>
                  {operateMap[item.cond_type] + item.value}
                </span>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default Component;
