import * as echarts from "echarts";
import react, { useState, useRef, useEffect, useLayoutEffect } from "react";
import styles from "./index.less";
import { getPersonInterest } from "../../../services/api";
import _ from "lodash";
const formatData = (data) => {
  let res = data.map((item) => {
    item.year.forEach((v) => {
      v.push(item.t);
      v[0] = v[0].toString();
    });
    return item.year;
  });
  return res.flat(1);
};

const Chart = (props) => {
  let { id = "54844d57dabfae9b40132e9e" } = props;
  let scale = window.innerWidth / 1920;
  const el = useRef(null);
  const [chartData, setChartData] = useState([]);
  const chartRef = useRef(null);
  const init = () => {
    var chartDom = el.current;
    chartRef.current = echarts.init(chartDom);
    var option;

    option = {
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "line",
          lineStyle: {
            color: "rgba(0,0,0,0.2)",
            width: 1,
            type: "solid",
          },
        },
      },
      grid: {
        // 图表容器的左边距
        left: 40 * scale,
        // 图表容器的右边距
        right: 40 * scale,
        // 图表容器的上边距
        top: 30 * scale,
        // 图表容器的下边距
        bottom: 20 * scale,
      },
      legend: {
        right: 0,
        data: chartData.map((item) => item.t),
        formatter: function (name) {
          // 截取前 10 个字符
          var shortName = name.substr(0, 6);
          // 如果文本过长，用...表示
          if (name.length > 5) {
            return shortName + "...";
          } else {
            return shortName;
          }
        },
        tooltip: {
          show: true,
        },
        icon: "circle",
        itemWidth: 10, // 设置宽度
        orient: "horizontal",
        itemHeight: 10, // 设置高度
        align: "left",
        itemGap: 10, // 设置间距
        textStyle: {
          color: "#fff",
          fontSize: 12,
          fontWeight: "normal",
          fontStyle: "normal",
          fontFamily: "Arial",
        },
      },

      singleAxis: {
        axisTick: {
          show: false,
        },
        axisLine: {
          show: false,
        },
        type: "time",
      },
      series: [
        {
          type: "themeRiver",
          label: {
            show: false,
          },
          boundaryGap: ["20%", "0%"],
          color: ["#FF5C00", "#FFC806", "#00FFA3", "#00C2FF"],
          emphasis: {
            itemStyle: {
              shadowBlur: 20,
              shadowColor: "rgba(0, 0, 0, 0.8)",
            },
          },
          itemStyle: {
            emphasis: {
              shadowBlur: 0, // 去掉 hover 时的阴影
              shadowColor: "rgba(0, 0, 0, 0)", // 设置阴影颜色为透明
            },
          },
          data: formatData(chartData),
        },
      ],
    };

    option && chartRef.current.setOption(option);
  };

  const handleScreenAuto = () => {
    if (chartRef.current) {
      chartRef.current.resize();
    }
  };

  useEffect(() => {
    handleScreenAuto();
    // 监听
    window.addEventListener("resize", _.debounce(handleScreenAuto, 600));
    // 销毁
    return () =>
      window.removeEventListener("resize", _.debounce(handleScreenAuto, 600));
  });

  const getData = async () => {
    let res = await getPersonInterest([
      {
        action: "PersonInterest.Get",
        parameters: { id, is_year: true },
      },
    ]);
    if (res.data[0].data && res.data[0].data.data) {
      setChartData(res.data[0].data.data.slice(0, 4));
    }
  };

  useEffect(() => {
    if (chartData.length) {
      init();
    }
  }, [chartData]);
  useLayoutEffect(() => {
    getData();
  }, [id]);
  return <div ref={el} className={styles.chart}></div>;
};
export default Chart;
