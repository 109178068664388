import React, { useEffect, useState } from "react";
import { Collapse } from "@itic-sci/iticui";
import Style from "./style.less";
import classnames from "classnames";
import Header from "./Header";
import Account from "./components/account";
import Question from "./components/question";
import Function from "./components/function";
import { getUrlParams } from "src/ChatPages/Graph/util.js";

const Guid = () => {
  const [anchorItem, setAnchorItem] = useState({
    account: [
      {
        section: "account",
        active: false,
        key: "login",
        href: "login",
        title: "账号登录",
      },
      {
        section: "account",
        active: false,
        key: "home",
        href: "home",
        title: "首页检索",
      },
      {
        section: "account",
        active: false,
        key: "chat",
        href: "chat",
        title: "对话检索",
      },
      {
        section: "account",
        active: false,
        key: "db",
        href: "db",
        title: "数据库",
      },
    ],
    question: [
      {
        section: "question",
        active: false,
        key: "question",
        href: "question",
        title: "常见问题",
      },
    ],
    function: [
      {
        section: "function",
        active: false,
        key: "search",
        href: "search",
        title: "检索附录",
      },
      {
        section: "function",
        active: false,
        key: "field",
        href: "field",
        title: "领域附录",
      },
    ],
  });
  const [currentRender, setCurrentRender] = useState("account");

  const renderSection = {
    account: <Account />,
    question: <Question />,
    function: <Function />,
  };
  const genExtra = () => {
    return <span className={`${Style.treeIcon} icon-xiangxia iconfont`}></span>;
    // return <IconFont className={'text-18'} type={'icon-icon-jiantou'} />;
  };

  const scrollToAnchor = (anchorName) => {
    if (anchorName) {
      // 找到锚点
      let anchorElement = document.getElementById(anchorName);
      // 如果对应id的锚点存在，就跳转到锚点
      if (anchorElement) {
        const yOffset = -130;
        const y =
          anchorElement.getBoundingClientRect().top +
          window.pageYOffset +
          yOffset;
        // anchorElement.scrollIntoView({block: 'start', behavior: 'smooth'})
        window.scrollTo({ top: y, behavior: "smooth" });
      }
    }
  };

  const changeAnchor = (event, section, item) => {
    setTimeout(() => {
      Object.keys(anchorItem).forEach((anchor) => {
        anchorItem[anchor].forEach((v) => {
          v.active = false;
          if (anchor === section && v.key === item.key) {
            v.active = true;
          }
        });
      });
      setAnchorItem({ ...anchorItem });
    }, 200);
    if (currentRender !== section) {
      window.scrollTo({ top: 0 });
      setCurrentRender(section);
      setTimeout(() => {
        scrollToAnchor(item.href);
      }, 200);
    } else {
      scrollToAnchor(item.href);
    }
  };

  const items = [
    {
      key: "account",
      label: "操作指引",
      children: (
        <>
          {anchorItem.account.map((v) => {
            return (
              <a
                className={classnames(Style.anchor, {
                  [Style.active]: v.active,
                })}
                key={v.key}
                onClick={(event) => changeAnchor(event, "account", v)}
              >
                {v.title}
              </a>
            );
          })}
        </>
      ),
      extra: genExtra(),
    },
    {
      key: "question",
      label: (
        <span
          className={classnames(Style.anchor, {
            [Style.active]: anchorItem.question[0].active,
          })}
          key={"question"}
          onClick={(event) => {
            event.stopPropagation();
            changeAnchor(event, "question", {
              key: "question",
              href: "question",
            });
          }}
        >
          常见问题
        </span>
      ),
    },
    {
      key: "function",
      label: "附录",
      children: (
        <>
          {anchorItem.function.map((v) => {
            return (
              <span
                className={classnames(Style.anchor, {
                  [Style.active]: v.active,
                })}
                key={v.key}
                onClick={(event) => changeAnchor(event, "function", v)}
              >
                {v.title}
              </span>
            );
          })}
        </>
      ),
      extra: genExtra(),
    },
  ];

  const changeCollapse = (key) => {
    console.log(key);
  };

  const initScroll = (key) => {
    scrollToAnchor(key);
  };

  useEffect(() => {
    let activeItem;
    window.onscroll = () => {
      const scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      const anchorItemList = [];
      Object.keys(anchorItem).forEach((k) => {
        anchorItemList.push(...anchorItem[k]);
      });
      console.log(anchorItem);
      anchorItemList.forEach((v) => {
        const ele = document.getElementById(v.href);
        v.active = false;
        if (scrollTop >= ele?.offsetTop - 200) {
          v.active = true;
          activeItem = v;
        }
      });
      if (activeItem) {
        Object.keys(anchorItem).forEach((k) => {
          anchorItem[k].forEach((v) => {
            v.active = false;
          });
        });
        anchorItem[activeItem.section].forEach((v) => {
          v.active = false;
          v.active = v.href === activeItem.href;
        });
      }
      setAnchorItem({ ...anchorItem });
    };
    let key = getUrlParams("target");
    if (key) {
      initScroll(key);
    }
  }, []);

  return (
    <div className={Style.container}>
      <Header />
      <div className={Style.contentContainer}>
        <div className={Style.leftSide}>
          <Collapse
            defaultActiveKey={["account", "function"]}
            onChange={changeCollapse}
            items={items}
            ghost
            expandIcon={(panel) => {
              switch (panel.panelKey) {
                case "account":
                  return (
                    <span
                      className={`${Style.treeIcon} icon-caozuozhiyin iconfont`}
                    ></span>
                  );
                case "question":
                  return (
                    <span
                      className={`${Style.treeIcon} icon-changjianwenti iconfont`}
                    ></span>
                  );

                case "function":
                  return (
                    <span
                      className={`${Style.treeIcon} icon-fulu iconfont`}
                    ></span>
                  );
              }
            }}
          />
        </div>
        <div className={Style.rightSide}>{renderSection[currentRender]}</div>
      </div>
    </div>
  );
};

export default Guid;
