import react, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
  useRef,
  useLayoutEffect,
  useContext,
} from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { getUrlParams } from "src/ChatPages/Graph/util.js";
import useUrlState from "@ahooksjs/use-url-state";
import styles from "./index.less";
import InputClear from "src/image/inputClear.svg";
import AuthApi from "src/services/auth/index";
import UserImg from "src/image/gkx/default_head_img.png";
import { Popover, Tabs, Input, message } from "antd";
import { useSharedState, defaultUserInfo } from "src/UserContext";
import tj from "@itic-sci/teamjob";

function SearchTabs(props: any) {
  const inputRef = props.inputRef;
  const disableSearch = props.disableSearch;
  const [userState, setUserState, showLogin, setShowLogin] = useSharedState();
  const navigate = useNavigate();
  const menu = [
    {
      icon: "icon-sousuo1",
      name: "首页",
      path: "ChatSearch",
      tarck: "kgi_cells_and_genes_navi_home_click",
    },
    {
      icon: "icon-duihua",
      name: "对话",
      path: "Chat",
      tarck: "kgi_cells_and_genes_navi_chat_click",
    },
    {
      icon: "icon-shujuku",
      name: "数据库",
      path: "Screen",
      tarck: "kgi_cells_and_genes_navi_db_click",
    },
  ];

  const logout = async () => {
    let res: any = await AuthApi.logout();
    if (res.code === 0) {
      setUserState(defaultUserInfo);
    } else if (res?.message) {
      message.error(res?.message);
    }
  };
  const handleConfirm = () => {
    let str = (inputRef.current as any).input.value;
    if (str.trim() === getUrlParams("search")) {
      return;
    }
    if (str && str.trim().length) {
      if (str.length > 200) {
        message.error("不能超过200个关键词！");
      } else {
        navigate(`/SearchTabs?tag=${getUrlParams("tag")}&search=${str.trim()}`);
      }
    } else {
      message.error("请输入搜索关键词");
    }
  };
  const handleTrack = () => {
    let tag = getUrlParams("tag");
    if (tag === "paper") {
      tj.track({
        params: {
          // 上报参数
          event_id: "kgi_cells_and_genes_search_result_paper_search_click",
        },
      });
    } else if (tag === "scholar") {
      tj.track({
        params: {
          // 上报参数
          event_id: "kgi_cells_and_genes_search_result_scholar_search_click",
        },
      });
    } else if (tag === "org") {
      tj.track({
        params: {
          // 上报参数
          event_id:
            "kgi_cells_and_genes_search_result_institution_search_click",
        },
      });
    }
  };
  const content = userState.account ? (
    <div className={`${styles.dropdown}`}>
      <div className={styles.accountInfo}>
        <div
          onClick={() => {
            window.open(
              "https://my.itic-sci.com/accountInfo/basicInfo",
              "_blank"
            );
          }}
        >
          <img src={userState.head_url || UserImg} className={styles.avatar} />
          {userState.account}
        </div>
      </div>
      <div className={styles.item}>
        <div
          onClick={() => {
            window.open(
              "https://my.itic-sci.com/accountInfo/basicInfo",
              "_blank"
            );
          }}
        >
          <span className={`${styles.icon} icon-people iconfont`}></span>
          个人中心
        </div>
      </div>
      <div className={styles.item} onClick={logout}>
        <span className={`${styles.icon} icon-tuichudenglu iconfont`}></span>
        退出登录
      </div>
    </div>
  ) : (
    <div className={styles.loginBtn}>
      <p
        onClick={() => !userState.account && setShowLogin(true)}
        className={styles.item}
      >
        去登录
      </p>
    </div>
  );

  useEffect(() => {
    console.log(getUrlParams("tag") as string);
  }, []);
  return (
    <div className={styles.topPart}>
      <span className={styles.logo}></span>
      <span
        className={styles.logoCircle}
        onClick={() => {
          window.open("https://itic-sci.com/", "_blank");
        }}
      ></span>
      {disableSearch && (
        <span className={styles.searchBoxDisable}>检索详情</span>
      )}
      {!disableSearch && (
        <Input
          className={styles.searchBox}
          ref={inputRef}
          defaultValue={getUrlParams("search") as string}
          placeholder="请输入要搜索的内容"
          allowClear={{
            clearIcon: (
              <>
                <img
                  src={InputClear}
                  alt=""
                  className={styles.closeImg}
                  onClick={() => {
                    (inputRef.current as any).input.value = "";
                    // setSearchStr("");
                  }}
                />
                <span className={styles.splitLine}></span>
              </>
            ),
          }}
          onPressEnter={() => {
            if (inputRef.current) {
              // setSearchStr((inputRef.current as any).input.value);
              handleConfirm();
            }
          }}
          suffix={
            <span
              className={`iconfont icon-sousuo ${styles.iconfont}`}
              onClick={() => {
                if (inputRef.current) {
                  handleTrack();
                  // setSearchStr((inputRef.current as any).input.value);
                  handleConfirm();
                }
              }}
            ></span>
          }
        />
      )}

      <div className={styles.tab}>
        {menu.map((item) => (
          <div
            key={item.name}
            className={styles.tabItem}
            onClick={() => {
              tj.track({
                params: {
                  // 上报参数
                  event_id: item.tarck,
                },
              });
              navigate(`/${item.path}`);
            }}
          >
            <span
              className={`${styles.tabItemIcon} iconfont ${item.icon}`}
            ></span>
            <span className={styles.tabItemName}>{item.name}</span>
          </div>
        ))}
      </div>
      {!userState.account && (
        <div
          className={`${styles.unLoginBox} `}
          onClick={() => {
            tj.track({
              params: {
                // 上报参数
                event_id: "kgi_cells_and_genes_navi_login_click",
              },
            });
            !userState.account && setShowLogin(true);
          }}
        >
          <span className={`${styles.statusText}`}>登录/注册</span>
        </div>
      )}
      {userState.account && (
        <Popover
          content={content}
          overlayClassName={styles.headerLogin}
          placement={!userState.account ? "bottomLeft" : "bottomRight"}
          arrow={false}
        >
          {userState.account && (
            <div className={`${styles.loginBox} `}>
              <img
                src={userState.head_url || UserImg}
                className={styles.avatar}
              />

              <span className={`${styles.statusText}`}>已登录</span>
            </div>
          )}
        </Popover>
      )}
    </div>
  );
}

export default SearchTabs;
