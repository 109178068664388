import react, {
  useState,
  useRef,
  useEffect,
  useLayoutEffect,
  useImperativeHandle,
  forwardRef,
  useContext,
} from "react";
import { getFieldGraphData, getNodeSubGraph } from "src/services/api";
import G6 from "@antv/g6";
import _ from "lodash";
import LoadingSrc from "../../image/loading.svg";
import styles from "./index.less";
import HelpLayer from "./HelpLayer/index";
import { UserContext } from "src/Context";
import { message } from "antd";
import Pos from "./posMap";
interface ICenterGraph {
  scale: number;
  setCurrentClickData: any;
  currentClickData: any;
  GraphRef: any;
  // linksData: any;
}

interface IGraphData {
  res: number;
  data: any;
}

interface IGraphNode {
  node_id: string;
  name: string;
  layer?: number;
}

function CenterGraph(props: ICenterGraph, ref: any) {
  const { setCurrentClickData, GraphRef, currentClickData } = props;
  const Ref = useRef<HTMLInputElement>(null);
  const ContinerRef = useRef<HTMLInputElement>(null);
  const flagRef = useRef<boolean>(false); // react18 开发模式下会触发两次 避免重复渲染
  const initRef = useRef<boolean>(false); // react18 开发模式下会触发两次 避免重复渲染
  const dataRef = useRef<any>({}); //
  const zoomRef = useRef<number>(10); //
  const isFullScreen = useRef<boolean>(false); //
  const [isloading, setIsLoading] = useState(false);
  const PosRef = useRef<any>(Pos); //
  const expandKeysRef = useRef<any>({}); // 展开节点信息
  const [data, setData] = useState<any>({});
  const [, forceUpddate] = useState<any>({});
  const [helpWinVisiable, setHelpWinVisiable] = useState<boolean>(false);
  console.log(isFullScreen.current);
  const designDraftWidth = 1366; //设计稿的宽度
  const scale = document.documentElement.clientWidth / designDraftWidth;
  const handleReset = () => {
    setData(dataRef.current.graph);
    zoomRef.current = 10;
    GraphRef.current && GraphRef.current.fitCenter();
  };
  const toolbar: any = new G6.ToolBar({
    className: "g6-toolbar-ul",
    getContent: () => {
      return `
        <ul >
          <div class="iconfont-part">
          <li code='fit'> <span class="iconfont icon-shiyingquanping" title="适应屏幕"></span></li>
          <li code='reset'><span class="iconfont icon-zhongxinzairu" title="重新载入"></span></li>
          <li code='fullScreen'><span id="quanpingzhanshi" class="iconfont  icon-quanpingzhanshi" title="全屏展示"></span></li>
          <li code='help'><span class="iconfont icon-caozuotishi"></span></li>
          </div>
         
        </ul>
      `;
    },
    handleClick: (code, graph) => {
      let cfg = {
        x: getGraphById("1").X,
        y: getGraphById("1").Y,
      };

      if (code === "fit") {
        zoomRef.current = graph.getZoom() * 10;

        graph.fitView();
        return;
      } else if (code === "reset") {
        handleReset();

        return;
      } else if (code === "help") {
        setHelpWinVisiable(true);
        return;
      } else if (code === "fullScreen") {
        const container = ContinerRef.current as any;
        if (isFullScreen.current) {
          try {
            document.fullscreenElement && document.exitFullscreen();
          } catch (error) {
            console.log(error);
          }
          container.classList.remove(styles["screen-wrapper"]);
        } else {
          container.requestFullscreen();
          container.classList.add(styles["screen-wrapper"]);
        }
        let target = document.querySelector("#quanpingzhanshi");
        if (target) {
          if (!isFullScreen.current) {
            target.classList.add("icon-tuichuquanping");
            target.classList.remove("icon-quanpingzhanshi");
            target.setAttribute("title", "退出全屏");
          } else {
            target.classList.remove("icon-tuichuquanping");
            target.classList.add("icon-quanpingzhanshi");
            target.setAttribute("title", "全屏展示");
          }
        }
        isFullScreen.current = !isFullScreen.current;
        forceUpddate({});
        return;
      }
      graph.zoomTo(zoomRef.current / 10, cfg);
    },
  });
  const updateLayout = async (id: string) => {
    const item = GraphRef.current.findById(id);
    const node = item?.getModel();
    if (expandKeysRef.current[node.id]) {
      return false;
    }
    let res: any = {};
    try {
      res = await getNodeSubGraph({
        node_id: node.id,
        node_type: node.node_type,
      });
    } catch (error) {
      message.error("接口网络状态异常，请您稍后再试！");
    }

    if (res.code !== 0) {
      return false;
    }
    let dataNew = _.cloneDeep(data);

    let nodeTemp = res.data.nodes.filter(
      (item: any) => item.node_id !== node.id
    );
    expandKeysRef.current[node.id] = nodeTemp
      .filter((item: any) => item.node_type !== "field")
      .map((item: any) => item.node_id);

    dataNew.nodes = dataNew.nodes.concat(
      calcPos(node.name_zh || node.name, nodeTemp)
    );
    dataNew.edges = dataNew.edges.concat(res.data.edges);
    // GraphRef.current.changeData(dataNew);
    // GraphRef.current.refresh();
    setData(dataNew);
    setTimeout(() => {
      handleClickHighLight(GraphRef.current, item, node.id);
    }, 10);
  };

  const handleNodeExpand = (item: any) => {
    if (item) {
      setCurrentClickData({
        ...item._cfg?.model,
        pos: getGraphById(item?.getModel()?.node_id as string),
      });
      updateLayout(item?.getModel()?.node_id);
    }
  };

  const handleNodeCollapse = (item: any) => {
    let nodes = _.cloneDeep(data.nodes);
    let id: any = item.getModel().id;

    let children = expandKeysRef.current[id] || [];

    function filterNode(list: any) {
      if (list.find((v: string) => expandKeysRef.current[v])) {
        list
          .filter((v: string) => Array.isArray(expandKeysRef.current[v]))
          .map((item: any) => {
            if (expandKeysRef.current[item]) {
              let temp = expandKeysRef.current[item].slice(0);
              expandKeysRef.current[item] = null;
              children.push(...temp);
              filterNode(temp);
            }
          });
      }
    }
    filterNode(children);
    let newNodes = nodes.filter(
      (node: any) => !children.includes(node.node_id)
    );
    setData({
      edges: data.edges,
      nodes: newNodes,
    });
    expandKeysRef.current[id] = null;
  };

  const clickNode = (id: string) => {
    if (GraphRef.current) {
      let item = GraphRef.current.findById(id);
      handleClickHighLight(GraphRef.current, item, id);

      // handleNodeExpand(item);
      // GraphRef.current.focusItem(
      //   GraphRef.current.findById(currentClickData.id)
      // );
    }
  };

  const contextMenu = new G6.Menu({
    getContent(evt: any): any {
      if (evt.item) {
        if (expandKeysRef.current[evt.item.getModel().id]) {
          return `<span class="g6-component-close" data-id="close">收起</span>`;
        }
        return `<span class="g6-component-close" data-id="expand">展开</span>`;
      }
    },
    handleMenuClick: (target, item) => {
      let type = target.getAttribute("data-id");

      if (type === "expand") {
        handleNodeExpand(item);
        return;
      }
      handleNodeCollapse(item);
    },
    // offsetX and offsetY include the padding of the parent container
    // 需要加上父级容器的 padding-left 16 与自身偏移量 10
    offsetX: 16 + 10,
    // 需要加上父级容器的 padding-top 24 、画布兄弟元素高度、与自身偏移量 10
    offsetY: 0,
    // the types of items that allow the menu show up
    // 在哪些类型的元素上响应
    itemTypes: ["node"],
  });

  // 对应新加入的节点，兼容老节点的位置
  function isExitPos(x: number, y: number): any {
    let list: any = Object.values(PosRef.current);
    for (let i = 0; i < list.length; i++) {
      let offsetY = list[i].Y - y;
      let offsetX = list[i].X - x;
      let sqrt = Math.sqrt(offsetY * offsetY + offsetX * offsetX);
      if (sqrt < 50) {
        x -= 100 * Math.random();
        y -= 50 * Math.random();
        return isExitPos(x, y);
      }
    }
    return { x, y };
  }

  function calcPos(name: string, dataList: any) {
    let PosBak: any = PosRef.current;

    // 展开节点距圆心的偏差距离
    let r = 160;
    let originPos = {
      x: PosBak["细胞与基因"].X,
      y: PosBak["细胞与基因"].Y,
    };
    let targetPos = {
      x: PosBak[name].X,
      y: PosBak[name].Y,
    };

    let y = targetPos.y - originPos.y;
    let x = targetPos.x - originPos.x;
    let angle = Math.atan(y / x);
    if (x === y) {
      angle = 0;
      r = 400;
    }
    let sqrt = Math.max(Math.sqrt(y * y + x * x), 40) + r;
    let newX =
      (x < 0 ? -Math.cos(angle) * sqrt : Math.cos(angle) * sqrt) + originPos.x;
    let newY =
      (x < 0 ? -Math.sin(angle) * sqrt : Math.sin(angle) * sqrt) + originPos.y;
    return dataList.map((item: any, index: number) => {
      let l =
        (360 / dataList.length) *
          (index - dataList.length / 2) *
          (Math.PI / 180) +
        angle;
      let x = Math.cos(l) * r + newX;
      let y = Math.sin(l) * r + newY;
      let val = isExitPos(x, y);
      x = val.x;
      y = val.y;
      if (!(PosRef.current as any)[item.name_zh || item.name]) {
        (PosRef.current as any)[item.name_zh || item.name] = {
          name: item.name_zh || item.name,
          Id: item.name_zh || item.name,
          X: x,
          Y: y,
        };
      }
      return {
        fx: x,
        fy: y,
        ...item,
      };
    });
  }
  useImperativeHandle(ref, () => ({
    clickNode: clickNode,
    handleReset: handleReset,
    updateLayout: updateLayout,
    init,
    handleScreenAuto,
  }));

  // 比较两个节点是否相等
  const compareNode = (node1: any, node2: any) => {
    if (node1 && node2) {
      return node1.getModel().node_id === node2.getModel().node_id;
    }
  };

  const getGraphById = (id: string): any => {
    let data = {};
    if (GraphRef.current) {
      let res = GraphRef.current.findById(id);
      let graph = (
        document.querySelector("#graph") as any
      ).getBoundingClientRect();
      if (res && graph) {
        let flag = GraphRef.current.getClientByPoint(
          res.getBBox().centerX,
          res.getBBox().centerY
        );

        data = {
          [`Id`]: id,
          [`X`]: flag.x - graph.left + 20, // graph 横向的偏移量
          [`Y`]: flag.y - graph.top + 12, // graph 纵向的偏移量
        };
      }
    }
    return data;
  };

  const handleClickHighLight = (graph: any, item: any, id: any) => {
    graph.setAutoPaint(false);
    const states = ["highlight", "dark"];

    graph.getNodes().forEach(function (node: any) {
      graph.clearItemStates(node, states);

      graph.setItemState(node, "dark", true);
    });

    let current = GraphRef.current.findById(id);
    graph.setItemState(current, "dark", false);
    graph.setItemState(current, "highlight", true);
    graph.getEdges().forEach(function (edge: any) {
      graph.setItemState(edge, "dark", false);
      if (compareNode(edge.getSource(), item)) {
        graph.setItemState(edge.getTarget(), "dark", false);
        graph.setItemState(edge.getTarget(), "highlight", true);
        graph.setItemState(edge, "highlight", true);
        graph.updateItem(edge, {
          labelCfg: {
            style: {
              opacity: 1,
            },
          },
        });

        edge.toFront();
      } else if (compareNode(edge.getTarget(), item)) {
        graph.setItemState(edge.getSource(), "dark", false);
        graph.setItemState(edge.getSource(), "highlight", true);
        graph.setItemState(edge, "highlight", true);
        graph.updateItem(edge, {
          labelCfg: {
            style: {
              opacity: 1,
            },
          },
        });

        edge.toFront();
      } else {
        graph.setItemState(edge, "highlight", false);
        graph.setItemState(edge, "dark", true);

        graph.updateItem(edge, {
          labelCfg: {
            style: {
              opacity: 0,
            },
          },
        });
      }
    });
    graph.getNodes().forEach(function (node: any) {
      if (node.hasState("highlight")) {
        graph.updateItem(node, {
          labelCfg: {
            style: {
              opacity: 1,
            },
          },
        });
      } else {
        graph.updateItem(node, {
          labelCfg: {
            style: {
              opacity: 0.3,
            },
          },
        });
      }
    });
    graph.paint();
    graph.setAutoPaint(true);
  };

  const tooltip = new G6.Tooltip({
    offsetX: -6,
    fixToNode: [1, 0],
    offsetY: 20,
    getContent(e: any): any {
      let data = e.item.getModel();
      if (document.querySelector(".g6-component-ct")) {
        let target: any = document.querySelector(".g6-component-ct");
        if (target.getAttribute("data-id") === data.id) {
          return target;
        }
      }
      const outDiv = document.createElement("div");
      // outDiv.style.width = "180rem";
      if (data.node_type === "org") {
        outDiv.innerHTML = `
        <div class="g6-component-ct g6-component-ct-org" data-id="${data.id}">
          
          ${
            data.image
              ? ` <img class="g6-component-ct-org-img"  onerror="this.onerror=null;this.src='defaultIndustry.svg';" src="${data.image}"/>`
              : `<img class="g6-component-ct-org-img" src="defaultIndustry.svg" />`
          }
          <div class="g6-component-ct-org-detail">
          <span class="g6-component-ct-org-title text-two-ellipsis"  <span onclick="window.open('${`/graph_detail?id=${data.node_id}&node_type=org`}')" > 
          ${data.name_zh || data.name}</span>
          <p class="g6-component-ct-count">引用数：${
            data.citation
          }   &nbsp 论文数：${
          data.papers === 10000 ? "10000+" : data.papers
        }</p>
        </div>
        
        </div>
       `;
      } else if (data.node_type === "person") {
        outDiv.innerHTML = `
        <div class="g6-component-ct g6-component-ct-person" data-id="${
          data.id
        }">
          
        ${
          data.avatar
            ? `<img class="g6-component-ct-person-img" src="${data.avatar}" onerror="this.onerror=null;this.src='defaultPeople.svg';" />`
            : `<img class="g6-component-ct-person-img" src="defaultPeople.svg" />`
        }
         
            <div class="g6-component-ct-person-detail">
              <span onclick="window.open('${`/graph_detail?id=${data.node_id}&node_type=person`}')" class="g6-component-ct-person-title text-two-ellipsis"  > 
              ${data.name_zh || data.name}</span>
              <p class="g6-component-ct-count">H-index：${
                data.hindex
              }   &nbsp 论文数：${
          data.papers === 10000 ? "10000+" : data.papers
        }&nbsp 引用数：${data.citation}</p>
            </div>
        </div>
       `;
      } else {
        outDiv.innerHTML = data.name_zh || data.name;
      }

      return outDiv;
    },
    itemTypes: ["node"],
  });

  const queryData = async () => {
    try {
      setIsLoading(true);
      let res: any = await getFieldGraphData();
      if (res.code === 0) {
        setData(res.data.graph);
        dataRef.current = res.data;
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);

      message.error("接口网络状态异常，请您稍后再试！");
    }
  };

  useEffect(() => {
    queryData();
    const isIndustryFirstVisit = localStorage.getItem("isIndustryFirstVisit");
    if (!isIndustryFirstVisit) {
      setHelpWinVisiable(true);
      localStorage.setItem("isIndustryFirstVisit", "true");
    }
    return () => {};
  }, []);

  useEffect(() => {
    if (!GraphRef.current) {
      // 添加缩略图
      // const miniMap = new G6.Minimap();
      init();
    }
    return () => {
      GraphRef.current && GraphRef.current.destroy(); //这个是重点
      GraphRef.current = null;
    };
  }, [JSON.stringify(data), scale]);

  // 大屏自适应
  const handleScreenAuto = () => {
    const container = Ref.current;
    if (!GraphRef.current || GraphRef.current.get("destroyed")) return;
    if (!container || !container.clientWidth || !container.clientHeight) return;
    setTimeout(() => {
      GraphRef.current && GraphRef.current.fitView();
    }, 600);
    GraphRef.current.changeSize(container.clientWidth, container.clientHeight);
  };

  const init = async () => {
    const container = Ref.current;

    if (!data || !data.nodes) {
      return false;
    }

    let newData = {
      nodes: data.nodes.map((item: any) => ({
        id: item.node_id.toString(),
        ...item,
      })),
      edges: data.edges.map((item: any) => ({
        source: item.source_id.toString(),
        target: item.target_id.toString(),
        lable: item.name,
        ...item,
      })),
    };

    if (!container) {
      return false;
    }
    const width = container.scrollWidth;
    const height = container.scrollHeight || 500;
    if (!GraphRef.current) {
      const graph = new G6.Graph({
        container: container,
        width,
        height,
        linkCenter: false,
        plugins: [contextMenu, toolbar, tooltip],
        modes: {
          default: ["drag-canvas", "zoom-canvas", "drag-node"],
        },
        defaultEdge: {
          style: {
            stroke: "rgba(52, 126, 180, .6)",
            endArrow: {
              path: G6.Arrow.vee(6, 6, 0),
              d: 0,
              fill: "rgba(52, 126, 180, .6)",
            },
            lineWidth: 1,
          },
        },
        defaultNode: {
          style: {
            fillOpacity: 0.75,
          },
        },

        // 设置节点高亮样式
        edgeStateStyles: {
          highlight: {
            stroke: "#99D9FD",
          },
          dark: {
            stroke: "#263B5F",
          },
        },
        // layout: {
        //   type: "compactBox",
        //   direction: "TB",
        // },
        layout: {
          type: "random",
        },

        // animate: true,
      });
      GraphRef.current = graph;
      setTimeout(() => {
        GraphRef.current && GraphRef.current.fitCenter();
      }, 600);
    }

    const graphNodeMap: any = {
      "0": {
        size: 40,
        type: "circle",
        style: {
          fill: "l(0) 0:#89EAFF 1:#42B0FF",
          lineWidth: 4,
          stroke: "l(80) 0:#42B0FF 1:#89EAFF",
        },
        stateStyles: {
          highlight: {
            opacity: 1,
            fill: "l(0) 0:#89EAFF 1:#42B0FF",
            lineWidth: 4,
            fillOpacity: 1,
            stroke: "l(80) 0:#42B0FF 1:#89EAFF",
          },
          dark: {
            opacity: 0.5,
            fill: "l(0) 0:#89EAFF 1:#42B0FF",
            lineWidth: 4,
            fillOpacity: 0.5,
            stroke: "l(80) 0:#42B0FF 1:#89EAFF",
          },
        },
        labelCfg: {
          style: {
            fill: "#fff",
            fontWeight: 600,
            fontSize: 12 * scale,
          },
          position: "bottom",
        },
      },
      "1": {
        type: "circle",
        size: 24,
        style: {
          fill: "l(0) 0:#AABDFF 1:#2137FF",
          lineWidth: 0,
          stroke: "l(80) 0:#2137FF 1:#AABDFF",
        },
        stateStyles: {
          highlight: {
            opacity: 1,
            fill: "l(0) 0:#AABDFF 1:#2137FF",
            lineWidth: 0,
            fillOpacity: 1,
            stroke: "l(80) 0:#2137FF 1:#AABDFF",
          },
          dark: {
            opacity: 0.5,
            fill: "l(0) 0:#AABDFF 1:#2137FF",
            lineWidth: 0,
            fillOpacity: 0.5,
            stroke: "l(80) 0:#2137FF 1:#AABDFF",
          },
        },
        labelCfg: {
          style: {
            fill: "#fff",
            fontSize: 10 * scale,
            fontWeight: 400,
          },
          position: "bottom",
        },
      },
      "2": {
        type: "circle",
        style: {
          fill: "l(0) 0:#9AC8FF 1:#2666E1",
          lineWidth: 0,
          stroke: "l(80) 0:#9AC8FF 1:#2666E1",
        },
        stateStyles: {
          highlight: {
            opacity: 1,
            fill: "l(0) 0:#9AC8FF 1:#2666E1",
            lineWidth: 0,
            fillOpacity: 1,
            stroke: "l(80) 0:#2666E1 1:#9AC8FF",
          },
          dark: {
            opacity: 0.5,
            fill: "l(0) 0:#9AC8FF 1:#2666E1",
            lineWidth: 0,
            fillOpacity: 0.5,
            stroke: "l(80) 0:#2666E1 1:#9AC8FF",
          },
        },
        size: 20,
        labelCfg: {
          style: {
            fill: "#fff",
            fontSize: 8 * scale,
            fontWeight: 200,
          },
          position: "bottom",
        },
      },
      "3": {
        type: "circle",
        style: {
          fill: "l(0) 0:#B3BCFC 1:#4352DC",
          lineWidth: 2,
          stroke: "l(0) 0:#B3BCFC 1:#4352DC",
        },
        stateStyles: {
          highlight: {
            opacity: 1,
            fill: "l(0) 0:#B3BCFC 1:#4352DC",
            lineWidth: 0,
            fillOpacity: 1,
            stroke: "l(80) 0:#4352DC 1:#B3BCFC",
          },
          dark: {
            opacity: 0.5,
            fill: "l(0) 0:#B3BCFC 1:#4352DC",
            lineWidth: 0,
            fillOpacity: 0.5,
            stroke: "l(80) 0:#4352DC 1:#B3BCFC",
          },
        },
        size: 16,
        labelCfg: {
          style: {
            fill: "#fff",
            fontSize: 8 * scale,
            fontWeight: 200,
          },
          position: "bottom",
        },
      },

      industry: {
        type: "circle",
        size: 24,
        style: {
          fill: "l(0) 0:#AF8EFF 1:#6218FF",
          lineWidth: 0,
          stroke: "l(0) 0:#AF8EFF 1:#6218FF",
        },
        stateStyles: {
          highlight: {
            opacity: 1,
            fill: "l(0) 0:#AF8EFF 1:#6218FF",
            lineWidth: 0,
            fillOpacity: 1,
            stroke: "l(80) 0:#6218FF 1:#AF8EFF",
          },
          dark: {
            opacity: 0.5,
            fill: "l(0) 0:#AF8EFF 1:#6218FF",
            lineWidth: 0,
            fillOpacity: 0.5,
            stroke: "l(80) 0:#6218FF 1:#AF8EFF",
          },
        },
        labelCfg: {
          style: {
            fill: "#fff",
            fontSize: 8 * scale,
            fontWeight: 200,
          },
          position: "bottom",
        },
      },
      scholar: {
        type: "circle",
        style: {
          fill: "l(0) 0:#FFF5AE 1:#FFB342",
          lineWidth: 0,
          stroke: "l(0) 0:#FFF5AE 1:#FFB342",
        },
        stateStyles: {
          highlight: {
            opacity: 1,
            fill: "l(0) 0:#FFF5AE 1:#FFB342",
            lineWidth: 0,
            fillOpacity: 1,
            stroke: "l(80) 0:#FFB342 1:#FFF5AE",
          },
          dark: {
            opacity: 0.5,
            fill: "l(0) 0:#FFF5AE 1:#FFB342",
            lineWidth: 0,
            fillOpacity: 0.5,
            stroke: "l(80) 0:#FFB342 1:#FFF5AE",
          },
        },
        size: 24,
        labelCfg: {
          style: {
            fill: "#fff",
            fontSize: 8 * scale,
            fontWeight: 200,
          },
          position: "bottom",
        },
      },
    };
    GraphRef.current.node(function (node: any) {
      let item = graphNodeMap["1"];

      if (node.expand_cnt > 0) {
        if (node.layer === 1) {
          item = graphNodeMap["1more"];
        }
        if (node.layer === 2) {
          item = graphNodeMap["2more"];
        }
        if (node.layer === 3) {
          item = graphNodeMap["3more"];
        }
      }

      if (node.node_type === "org") {
        item = graphNodeMap.industry;
        // if (node.image) {
        //   item.img = node.image;
        // }
      } else if (node.node_type === "person") {
        item = graphNodeMap.scholar;
        // if (node.avatar) {
        //   item.img = node.avatar;
        // }
      }
      let name = node.name_zh || node.name;
      return {
        label: name.length > 16 ? name.substring(0, 16) + "..." : name,
        ...item,
        // icon: {
        //   ...item.icon,
        //   width: item.size * scale,
        //   height: item.size * scale,
        // },
        size: item.size * scale,
      };
    });

    GraphRef.current.edge(function (node: any) {
      return {
        stroke: "#eaff8f",
        lineWidth: 5,
        label: node.name,
        autoRotate: true,

        labelCfg: {
          style: {
            fill: "#fff",
            opacity: 0,
            fontWeight: 200,
            fontSize: 8 * scale,
          },
        },
      };
    });

    GraphRef.current.data(newData);
    GraphRef.current.on("node:click", function (evt: any) {
      console.log("node:click");
      setTimeout(() => {
        setCurrentClickData({
          ...evt.item._cfg?.model,
          pos: getGraphById(evt.item?.getModel()?.node_id as string),
        });
      }, 200);
      handleClickHighLight(GraphRef.current, evt.item, evt.item.getModel().id);
    });

    GraphRef.current.on("node:dblclick", function (evt: any) {
      console.log("node:click");
      let id = evt.item.getModel().id;
      if (expandKeysRef.current[id]) {
        handleNodeCollapse(evt.item);
      } else {
        handleNodeExpand(evt.item);
      }
    });

    GraphRef.current.on("node:touchstart", function (evt: any) {
      console.log("node:touchstart");
      handleNodeExpand(evt.item);
    });

    GraphRef.current.on("click", function (ev: any) {
      if (!ev.shape) {
        clearAllStats();
        setCurrentClickData({});
      }
    });

    GraphRef.current.render();
    if (Ref.current) {
      (Ref.current.style.opacity as any) = 0;
    }
    GraphRef.current.on("afterlayout", () => {
      GraphRef.current.getNodes().forEach((node: any) => {
        let id: any = node._cfg.model.node_id;
        let name: any = node._cfg.model.name_zh || node._cfg.model.name;
        let xOffset = 300;
        let yOffset = 126;

        // const designDraftHeight = 768; //设计稿的高度
        //根据屏幕的变化适配的比例
        if ((PosRef.current as any)[name]) {
          GraphRef.current.updateItem(node, {
            x: ((PosRef.current as any)[name].X + xOffset) * scale,
            y: ((PosRef.current as any)[name].Y + yOffset) * scale,
          });
        } else if (node.getModel().fx) {
          GraphRef.current.updateItem(node, {
            x: (node.getModel().fx + xOffset) * scale,
            y: (node.getModel().fy + yOffset) * scale,
          });
        }
      });
      setTimeout(() => {
        if (Ref.current) {
          (Ref.current.style.opacity as any) = 1;
          // if (currentClickData.id) {
          //   clickNode(currentClickData.id);
          // }
          if (initRef.current === false) {
            initRef.current = true;
            handleClickHighLight(
              GraphRef.current,
              GraphRef.current.findById("1"),
              GraphRef.current.findById("1").getModel().id
            );
          }

          GraphRef.current.focusItem(
            GraphRef.current.findById(currentClickData.id)
          );
          GraphRef.current.fitCenter();
        }
      }, 100);
    });
  };

  /**
   * 清除节点连线高亮状态
   */
  const clearAllStats = () => {
    if (GraphRef.current) {
      GraphRef.current.setAutoPaint(false);
      GraphRef.current.getNodes().forEach(function (node: any) {
        GraphRef.current.clearItemStates(node);
        GraphRef.current.updateItem(node, {
          labelCfg: {
            style: {
              opacity: 0.6,
            },
          },
        });
      });
      GraphRef.current.getEdges().forEach(function (edge: any) {
        GraphRef.current.clearItemStates(edge);
        GraphRef.current.updateItem(edge, {
          labelCfg: {
            style: {
              opacity: 0,
            },
          },
        });
      });

      GraphRef.current.paint();
      GraphRef.current.setAutoPaint(true);

      // setData(dataRef.current);
    }
  };

  useLayoutEffect(() => {
    if (!flagRef.current) {
      init();
      flagRef.current = true;
    }
  }, []);

  useEffect(() => {
    if (GraphRef.current && currentClickData.id) {
      GraphRef.current.focusItem(
        GraphRef.current.findById(currentClickData.id)
      );
    }
  }, [currentClickData.id]);
  return (
    <div className={styles.CenterGraph} ref={ContinerRef}>
      {!isFullScreen.current && <div className={styles.topShadow}></div>}

      <div
        className={`${styles.graph} ${isFullScreen.current && "screenBg"}`}
        ref={Ref}
        id="graph"
      ></div>
      {isloading && (
        <div className="loading-ct">
          <img src={LoadingSrc} className="loading" alt="" />
        </div>
      )}
      {helpWinVisiable && <HelpLayer setHelpWinVisiable={setHelpWinVisiable} />}
    </div>
  );
}

export default forwardRef(CenterGraph);
