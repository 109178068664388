import React, { createContext } from "react";

const obj = {
  isloading: false,
  setIsLoading: (data) => {
    obj.isloading = data;
  },
  userInfo: {
    token: "",
    account: "",
    id: "",
    aminer_token: "",
    realName: "",
    cardNo: "",
    telephone: "",
    head_url: "",
    libraryStatus: 0,
    libraryMessage: "",
    role: 0,
    isFetchedUserInfo: false,
    asyncRoutestMark: false,
  },
  setUserInfo: (user) => {
    obj.userInfo = user;
  },
};
export const UserContext = createContext(obj);
