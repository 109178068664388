import react, { useState, useRef, useEffect } from "react";
import styles from "./index.less";
import defaultIndustry from "../../image/defaultIndustry.svg";
import { getIndustryList } from "../../services/api";
import CommonTitle from "../CommonTitle";

import _ from "lodash";
import { IListData } from "src/pages/Industry";
interface IIndustryPart {
  currentClickData: any;
}

function IndustryPart(props: IIndustryPart) {
  const { currentClickData } = props;
  const [listData, setListData] = useState<Array<IListData>>([]);
  const listRef = useRef<HTMLDivElement>(null);
  const currentPageRef = useRef(1);
  const listDataRef = useRef([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [status, setStatus] = useState<"expand" | "collapse">("expand");

  const getListData = async (params: any) => {
    let res: any = await getIndustryList({
      node_name: params.name_zh || "细胞与基因",
      node_layer: params.layer,
      size: 24,
      page: currentPage,
      is_sz: 0,
    });
    if (res.code === 0) {
      let temp = res.data.orgs;
      if (currentPage > 1 && listDataRef.current) {
        temp = [...listDataRef.current, ...temp];
      }
      setListData(temp);
      listDataRef.current = temp;
    }
  };
  const handleScreenAuto = (e: any) => {
    let dom = listRef.current;
    if (dom) {
      //文档内容实际高度（包括超出视窗的溢出部分）
      let scrollHeight = Math.max(dom.scrollHeight, dom.scrollHeight);
      //滚动条滚动距离
      let scrollTop = e.target.scrollTop;
      //窗口可视范围高度
      let clientHeight = dom.clientHeight;

      if (clientHeight + scrollTop + 10 >= scrollHeight) {
        if (listDataRef.current.length === currentPageRef.current * 24) {
          currentPageRef.current += 1;

          setCurrentPage(currentPageRef.current);
        }
      }
    }
  };

  //React的生命周期 如果你是vue可以放到mountd或created中
  useEffect(() => {
    if (listRef.current) {
      listRef.current.addEventListener(
        "scroll",
        _.debounce(handleScreenAuto, 200),
        true
      );
      return () => {
        if (listRef.current) {
          listRef.current.removeEventListener(
            "scroll",
            _.debounce(handleScreenAuto, 200),
            true
          );
        }
      };
    }
  }, []);

  useEffect(() => {
    if (listRef.current) {
      listRef.current.scrollTop = 0;
    }
    listDataRef.current = [];
    currentPageRef.current = 1;
    setCurrentPage(1);
  }, [JSON.stringify(currentClickData)]);

  useEffect(() => {
    if (currentClickData.node_type === "field") {
      getListData(currentClickData);
    }
  }, [JSON.stringify(currentClickData), currentPage]);
  return (
    <>
      <div
        className={
          currentClickData.node_type === "field"
            ? styles.IndustryPart
            : styles.hide
        }
      >
        <span className={styles.title}>相关机构</span>
        <CommonTitle title={"相关机构"} className={styles.title} />
        <div className={styles.list} ref={listRef}>
          {Array.isArray(listData) &&
            listData.map((item: any) => (
              <div
                key={item.node_id}
                className={styles.listItem}
                data-id={item.node_id}
              >
                <img
                  className={styles.listImg}
                  src={item.image || ""}
                  alt=""
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = defaultIndustry;
                  }}
                />
                <div className={styles.rightPart}>
                  <span
                    className={styles.listName + " text-ellipsis"}
                    title={item.org_nameZh || item.org_name}
                    onClick={() =>
                      window.open(
                        `/graph_detail?id=${item.aminer_id}&node_type=org`,
                        "_blank"
                      )
                    }
                  >
                    {item.org_nameZh || item.org_name}
                  </span>
                  <p className={styles.countDesc}>
                    引用数：{item.n_citation}&nbsp; 论文数：
                    {item.n_papers === 10000 ? "10000+" : item.n_papers}
                  </p>
                </div>
              </div>
            ))}
        </div>
      </div>
    </>
  );
}

export default IndustryPart;
