import react, { useState, useRef, useEffect } from "react";
import styles from "./index.less";
import { getNodeDetail, getPaperData } from "src/services/api";
import { Spin, message } from "antd";
import IndustryPart from "./IndustryPart";
import _ from "lodash";
interface IFieldWin {
  currentClickData?: any;
}

function FieldWin(props: IFieldWin) {
  const { currentClickData } = props;
  const [loading, setLoading] = useState(false);
  const [rawData, setRawData] = useState<any>({});
  const [pubRawData, setPubRawData] = useState<any>({});
  const queryPersonDetail = async (params: any) => {
    setLoading(true);

    try {
      let res: any = await getNodeDetail({
        data: [params.name_zh || "细胞与基因"],
        node_type: params.node_type,
      });
      if (res.code === 0) {
        let temp = res.data.details || [];
        setRawData(temp[0]);
      }
    } catch (error: any) {
      if (error.code === "ECONNABORTED") {
        message.error("接口超时，请刷新重试", 5);
      } else {
        message.error("请刷新重试");
      }
    }

    setLoading(false);
  };

  useEffect(() => {
    queryPersonDetail(currentClickData);
  }, [JSON.stringify(currentClickData)]);
  return (
    <div className={styles.FieldWin}>
      <Spin spinning={loading}>
        <div className={styles.topInfo}>
          <p
            className={styles.topTitle}
            onClick={() =>
              currentClickData.url && window.open(currentClickData.url)
            }
          >
            {currentClickData.name}
          </p>
          <p className={styles.topDesc}>{rawData.desc}</p>
          {/* <LinkGraph /> */}
        </div>

        {Array.isArray(rawData.relate_fields) &&
          rawData.relate_fields.length && (
            <>
              <p className={styles.labelTitle}>相关领域</p>
              <p className={styles.tags}>
                {rawData.relate_fields.map((item: any) => (
                  <span className={styles.tagsItem} key={item.field_name}>
                    {item.field_name}
                  </span>
                ))}
              </p>
            </>
          )}
        <p className={styles.labelTitle}>相关机构</p>
        <IndustryPart currentClickData={currentClickData} />
      </Spin>
    </div>
  );
}

export default FieldWin;
