import react, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
  useRef,
  useContext,
} from "react";
import _ from "lodash";
import styles from "./index.less";
import ExpandableText from "src/component/ExpandableText/index";
import AuthorList from "src/component/AuthorList/index";
import Empty from "src/image/chatGraph/empty.svg";
import LoadingGif from "src/image/loading.gif";
import { getUrlParams } from "src/ChatPages/Graph/util.js";
import { getPaperList } from "src/services/api";
import { UserContext } from "src/Context";
import { Pagination, Select, message, ConfigProvider, Spin } from "antd";
import { useNavigate, Link, useLocation } from "react-router-dom";
import InputClear from "../../image/inputClear.svg";
import tj from "@itic-sci/teamjob";
import zhCN from "antd/lib/locale/zh_CN";
interface IListData {
  title: string;
  authors: any;
  abstractZh: string;
  abstract: string;
  fields: any;
  keywords: string;
  pub_year: string;
  cite_num: string | number;
  node_id: string;
  mini_fields: any;
}
interface IPaper {
  searchStr: any;
}

const sortTag = [
  {
    key: "weight",
    label: "综合排序",
    track: "kgi_cells_and_genes_search_result_paper_sort_comprehensive_click",
  },
  {
    key: "pub_year",
    label: "最新",
    track: "kgi_cells_and_genes_search_result_paper_sort_newest_click",
  },
  {
    key: "n_citation",
    label: "引用量",
    track: "kgi_cells_and_genes_search_result_paper_sort_cites_click",
  },
];

function Paper(props: IPaper, ref: any) {
  const listRef = useRef<HTMLDivElement>(null);
  const currentPageRef = useRef(1);
  const [isloading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const inputRef = useRef(null);
  const listDataRef = useRef([]);
  const { searchStr } = props;
  const [sortType, setSortType] = useState<"link" | "time">("link");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [currentTag, setCurrentTag] = useState<string>("weight");
  const [total, setTotal] = useState<number>(0);
  const [listData, setListData] = useState<Array<IListData>>([]);
  const [checkBoxState, setCheckBoxState] = useState(false);
  useImperativeHandle(ref, () => ({}));
  const getListData = async () => {
    try {
      setIsLoading(true);

      let res: any = await getPaperList({
        node_name: "细胞与基因",
        node_layer: 0,
        size: pageSize,
        page: currentPage,
        keyword: searchStr,
        sort_field: currentTag,
      });
      setIsLoading(false);

      if (res.code === 0) {
        let temp = res.data.papers;
        setListData(temp);
        setTotal(res.data.total);
        listDataRef.current = temp;
        window.scrollTo(0, 0);
      } else {
        message.error("系统错误，请稍后重试");
      }
    } catch (e: any) {
      console.log(e);
      setIsLoading(false);
      if (e.code === "ECONNABORTED") {
        message.error("接口超时，请刷新重试", 5);
      } else {
        message.error("系统错误，请稍后重试");
      }
    }
  };

  const handleChange = (page: number, pageSize: number) => {
    tj.track({
      params: {
        // 上报参数
        event_id: "kgi_cells_and_genes_search_result_paper_pageflipper_click",
      },
    });
    setPageSize(pageSize);
    setCurrentPage(page);
  };

  useEffect(() => {
    if (listRef.current) {
      listRef.current.scrollTop = 0;
    }
    if (getUrlParams("tag") === "paper") {
      getListData();
    }
  }, [currentPage, pageSize, searchStr, currentTag]);

  // useEffect(() => {
  //   tj.track({
  //     params: {
  //       // 上报参数
  //       event_id: "kgi_cells_and_genes_search_result_paper_enter",
  //     },
  //   });
  // }, []);

  return (
    <>
      <div className={styles.paper}>
        <Spin
          spinning={isloading}
          indicator={
            <img
              src={LoadingGif}
              style={{
                width: 100,
                height: 100,
                display: "inline-block",
                marginTop: 100,
                transform: "translateX(-250px)",
              }}
            />
          }
        >
          <div className={styles.listCt} ref={listRef}>
            {Array.isArray(listData) && !!listData.length && (
              <>
                <div className={styles.totalBar}>
                  共找到 {total} 个相关论文实体
                  <div className={styles.sortCt}>
                    <span className={styles.sortLabel}>
                      <span className="iconfont icon-paixu"></span>
                      <span> 排序方式：</span>
                    </span>
                    <div className={styles.sortItem}>
                      {sortTag.map((item, index) => (
                        <span
                          key={index}
                          onClick={() => {
                            tj.track({
                              params: {
                                // 上报参数
                                event_id: item.track,
                              },
                            });
                            setCurrentPage(1);
                            setCurrentTag(item.key);
                          }}
                          className={
                            currentTag === item.key ? styles.actived : ""
                          }
                        >
                          {item.label}
                        </span>
                      ))}
                    </div>
                  </div>
                </div>
                <div>
                  {listData.map((item: any, index) => (
                    <div key={Math.random()} className={styles.listItem}>
                      <div className={styles.topInfo}>
                        <p
                          className={styles.topTitle + " text-three-ellipsis"}
                          onClick={() => {
                            tj.track({
                              params: {
                                // 上报参数
                                event_id:
                                  "kgi_cells_and_genes_search_result_paper_result_click",
                              },
                            });
                            window.open(
                              `/graph_detail?id=${item.node_id}&node_type=${item.node_type}`
                            );
                          }}
                        >
                          {item.titleZh || item.title || "--"}
                        </p>
                        {/* <LinkGraph /> */}
                      </div>
                      {Array.isArray(item.authors) && !!item.authors.length && (
                        <AuthorList
                          authors={item.authors}
                          theme="white"
                          track="kgi_cells_and_genes_search_result_paper_result_author_click"
                        />
                      )}
                      <div className={styles.bottomInfo}>
                        {/* <div className={styles.tagList}>
                  {Array.isArray(item.mini_fields) &&
                    item.mini_fields.slice(0, 5).map((item: any) => (
                      <span className={styles.tag} key={item}>
                        {item || "--"}
                      </span>
                    ))}
                </div> */}
                        <div className={styles.bottomCount}>
                          <div className={styles.bottomDetail}>
                            <span className={styles.label}>引用量：</span>
                            <span className={styles.count}>
                              {item.cite_num || "0"}
                            </span>
                          </div>
                          <div className={styles.bottomDetail}>
                            <span className={styles.label}>出版时间：</span>
                            <span className={styles.count}>
                              {item.pub_year || "--"}
                            </span>
                          </div>
                          <div className={styles.bottomDetail}>
                            <span className={styles.label}>相关实体：</span>
                            <span className={styles.count}>
                              {item.sub_graph_total || "0"}
                            </span>
                          </div>
                        </div>
                      </div>
                      <ExpandableText
                        text={item.abstractZh || item.abstract}
                        key={item.node_id}
                        track={
                          "kgi_cells_and_genes_search_result_paper_result_more_abstract_click"
                        }
                      />
                    </div>
                  ))}
                </div>
              </>
            )}
          </div>
          {!isloading && Array.isArray(listData) && !listData.length && (
            <div className={styles.emptyPart}>
              <img src={Empty} alt="" />
              <p>找不到相关内容</p>
            </div>
          )}
          {!!total && (
            <div className={styles.footerBar}>
              <ConfigProvider locale={zhCN}>
                <Pagination
                  current={currentPage}
                  total={total}
                  showSizeChanger={false}
                  onChange={handleChange}
                  defaultCurrent={1}
                  className={styles.pagination}
                />
              </ConfigProvider>
            </div>
          )}
        </Spin>
      </div>
    </>
  );
}

export default forwardRef(Paper);
