module.exports = {
  细胞与基因: {
    name: "细胞与基因",
    Id: 1,
    X: 0,
    Y: 0,
  },
  靶点发现验证: {
    name: "靶点发现验证",
    Id: 2,
    X: 71.60501861455019,
    Y: -39.958995347855286,
  },
  基因测序技术: {
    name: "基因测序技术",
    Id: 3,
    X: 177.409046460964,
    Y: -40.62056417396884,
  },
  "NGS测序技术（二代测序）": {
    name: "NGS测序技术（二代测序）",
    Id: 4,
    X: 274.88654451643873,
    Y: -62.93955547834732,
  },
  "单分子测序技术（三代测序）": {
    name: "单分子测序技术（三代测序）",
    Id: 62,
    X: 282,
    Y: -6.907007947191072e-14,
  },
  "纳米孔测序（四代测序）": {
    name: "纳米孔测序（四代测序）",
    Id: 127,
    X: 253.9050521715464,
    Y: -122.70380793506082,
  },
  其他基因检测技术: {
    name: "其他基因检测技术",
    Id: 191,
    X: 127.72236520093594,
    Y: -129.6572305252536,
  },
  基因芯片: {
    name: "基因芯片",
    Id: 192,
    X: 174.0856476637047,
    Y: -221.8517236297894,
  },
  核酸质谱技术: {
    name: "核酸质谱技术",
    Id: 257,
    X: 219.20940072179476,
    Y: -177.40134902303197,
  },
  基因编辑技术: {
    name: "基因编辑技术",
    Id: 267,
    X: 57.69901784964364,
    Y: -172.61177056964135,
  },
  CRISPR工具: {
    name: "CRISPR工具",
    Id: 268,
    X: 118.8726650675316,
    Y: -255.72111664808298,
  },
  碱基编辑器: {
    name: "碱基编辑器",
    Id: 285,
    X: 58.79972599154896,
    Y: -275.80172628777865,
  },
  基因合成技术: {
    name: "基因合成技术",
    Id: 345,
    X: -5.6820315304720275,
    Y: -281.94275042583865,
  },
  递送载体技术: {
    name: "递送载体技术",
    Id: 393,
    X: -29.304488815629487,
    Y: -76.58490017787219,
  },
  病毒载体: {
    name: "病毒载体",
    Id: 394,
    X: -69.86401057130786,
    Y: -273.2087480789958,
  },
  非病毒载体: {
    name: "非病毒载体",
    Id: 455,
    X: -84.13307034258067,
    Y: -161.38657464216277,
  },
  非病毒递送载体: {
    name: "非病毒递送载体",
    Id: 456,
    X: -130.36003206927333,
    Y: -250.06051675324122,
  },
  细胞转导技术: {
    name: "细胞转导技术",
    Id: 498,
    X: -166.92537583832487,
    Y: -72.52529835329177,
  },
  细胞分离: {
    name: "细胞分离",
    Id: 499,
    X: -183.97838451323955,
    Y: -213.71933471705026,
  },
  细胞激活: {
    name: "细胞激活",
    Id: 546,
    X: -227.0375259176608,
    Y: -167.2661407015404,
  },
  细胞扩增培养: {
    name: "细胞扩增培养",
    Id: 605,
    X: -258.64261530993195,
    Y: -112.37436338257297,
  },
  细胞质控: {
    name: "细胞质控",
    Id: 706,
    X: -277.199174701075,
    Y: -51.81329506065893,
  },
  细胞存储: {
    name: "细胞存储",
    Id: 744,
    X: -281.7710249481328,
    Y: 11.361756012110591,
  },
  药物产品: {
    name: "药物产品",
    Id: 768,
    X: -35.748904018261875,
    Y: 73.79712637693353,
  },
  免疫细胞治疗: {
    name: "免疫细胞治疗",
    Id: 769,
    X: -160.1034560665412,
    Y: 86.54988940229273,
  },
  "TCR-T": {
    name: "TCR-T",
    Id: 770,
    X: -271.7455899392934,
    Y: 75.35472346539004,
  },
  "CAR-NK": {
    name: "CAR-NK",
    Id: 4313,
    X: -248.0723879712342,
    Y: 134.10477368926678,
  },
  "CAR-T": {
    name: "CAR-T",
    Id: 4328,
    X: -211.88392438236568,
    Y: 186.08923286511757,
  },
  干细胞: {
    name: "干细胞",
    Id: 4329,
    X: -70.00750184423315,
    Y: 167.99687403499416,
  },
  间充质干细胞治疗: {
    name: "间充质干细胞治疗",
    Id: 4330,
    X: -108.4731621982074,
    Y: 260.30284877949646,
  },
  多能干细胞: {
    name: "多能干细胞",
    Id: 4381,
    X: -163.83389475661485,
    Y: 229.52658871877665,
  },
  类器官: {
    name: "类器官",
    Id: 4423,
    X: -47.63995438832436,
    Y: 277.94681999598123,
  },
  基因治疗: {
    name: "基因治疗",
    Id: 4443,
    X: 31.328118038260374,
    Y: 179.2834320850112,
  },
  基因编辑治疗: {
    name: "基因编辑治疗",
    Id: 4444,
    X: 17.03686733280311,
    Y: 281.4848932917795,
  },
  小核酸药物: {
    name: "小核酸药物",
    Id: 4469,
    X: 79.4317008799782,
    Y: 270.5819744464026,
  },
  基因检测服务: {
    name: "基因检测服务",
    Id: 4498,
    X: 106.97140444545867,
    Y: 147.24509713727687,
  },
  肿瘤诊断基因检测: {
    name: "肿瘤诊断基因检测",
    Id: 4499,
    X: 139.07597116836365,
    Y: 245.31994261285908,
  },
  "生育/遗传病检测": {
    name: "生育/遗传病检测",
    Id: 4560,
    X: 190.3207137509445,
    Y: 208.09138837859447,
  },
  生物育种: {
    name: "生物育种",
    Id: 4679,
    X: 169.37335305396422,
    Y: 66.60831235857272,
  },
  分子标记辅助育种: {
    name: "分子标记辅助育种",
    Id: 4680,
    X: 232.78108891843712,
    Y: 159.1758921506227,
  },
  转基因育种: {
    name: "转基因育种",
    Id: 4777,
    X: 262.43563495174675,
    Y: 103.20628618196433,
  },
  基因编辑育种: {
    name: "基因编辑育种",
    Id: 4881,
    X: 278.85029299548626,
    Y: 42.02991906168119,
  },
};
