import React from "react";

const SharedStateContext = React.createContext();

export const useSharedState = () => {
  const context = React.useContext(SharedStateContext);
  if (!context) {
    throw new Error("useSharedState must be used within SharedStateProvider");
  }
  return context;
};

export const defaultUserInfo = {
  token: "",
  account: "",
  id: "",
  aminer_token: "",
  realName: "",
  cardNo: "",
  telephone: "",
  head_url: "",
  libraryStatus: 0,
  libraryMessage: "",
  role: 0,
  isFetchedUserInfo: false,
  asyncRoutestMark: false,
};

export const SharedStateProvider = ({ children }) => {
  const [userState, setUserState] = React.useState(defaultUserInfo);
  const [showLogin, setShowLogin] = React.useState(false);

  return (
    <SharedStateContext.Provider
      value={[userState, setUserState, showLogin, setShowLogin]}
    >
      {children}
    </SharedStateContext.Provider>
  );
};
