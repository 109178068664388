import React, { useEffect, useRef, useState, useImperativeHandle } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import Header from "./Header";
import Legend from "./legend";
import EntryPage from "../EntryPage";
import { getGraph } from "src/services/api";
import mockData from "./data";
import Scholar from "src/image/chatGraph/scholar.png";
import Org from "src/image/chatGraph/org.png";
import Paper from "src/image/chatGraph/paper.png";
import Field from "src/image/chatGraph/field.png";
import messagePng from "src/image/chatGraph/message.svg";
import PersonWin from "./PersonWin";
import PaperWin from "./PaperWin";
import FieldWin from "./FieldWin";
import NodeToolTip from "./NodeToolTip";
import OrgWin from "./OrgWin";
import {
  Slider,
  Pagination,
  ConfigProvider,
  Spin,
  message,
  Tooltip,
} from "antd";
import _ from "lodash";
import { highLight, cancelHighLight, formatData, getUrlParams } from "./util";
import styles from "./index.less";
import zhCN from "antd/lib/locale/zh_CN";
import tj from "@itic-sci/teamjob";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default React.forwardRef((props, ref) => {
  const type = "";
  const cyRef = useRef();
  const zoomRef = useRef(0.6);
  const [loading, setLoading] = useState(false);
  const [zoom, setZoom] = useState(0.8);
  const [, forceUpdate] = useState({});
  const location = useLocation();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [total, setTotal] = useState(1);
  const [rightPartShow, setRightPartShow] = useState(false);
  const isTouchMove = useRef(false);
  const [data, setData] = useState({});
  const [currentNode, setCurrentNode] = useState({});
  const [rootNode, setRootNode] = useState({});
  const [hoverNode, setHoverNode] = useState({});

  let query = useQuery();
  let id = query.get("id");
  let node_type = query.get("node_type");
  useImperativeHandle(ref, () => ({}));

  useEffect(() => {
    window.defaultRightPartShow = rightPartShow;
  }, [rightPartShow]);

  const drawIt = (zoom) => {
    if (data && window.cytoscape && document.getElementById("cy")) {
      if (zoom && cyRef.current && cyRef.current.zoom() !== zoom) {
        var center = cyRef.current.elements().boundingBox();
        cyRef.current.zoom({
          level: zoom, // the zoom level
          renderedPosition: {
            x: center.x1 + center.w / 2,
            y: center.y1 + center.h / 2,
          },
        });
        return;
      }

      cyRef.current = window.cytoscape({
        container: document.getElementById("cy"),
        motionBlur: false,
        textureOnViewport: false,
        wheelSensitivity: 0.1,
        elements: formatData(data),
        zoom: zoomRef.current,
        minZoom: 0.3,
        maxZoom: 1,
        layout: {
          name: "cola",
          nodeSpacing: "50",
          edgeLengthVal: "20",
          animate: true,
          randomize: false,
          maxSimulationTime: 100,
        },

        style: [
          {
            selector: "node",
            style: {
              shape: "ellipse",
              width: function (e) {
                return e.data("id") === id ? 160 : 100;
              },
              height: function (e) {
                return e.data("id") === id ? 160 : 100;
              },
              "background-color": function (e) {
                return e.data("color");
              },
              "background-fit": "cover",
              "background-image": function (e) {
                if (e.data("avatar")) {
                  return e.data("avatar");
                }
                // if (e.data("url")) {
                //   return e.data("url");
                // }
                if (e.data("node_type") === "paper") {
                  return Paper;
                } else if (e.data("node_type") === "person") {
                  return Scholar;
                } else if (e.data("node_type") === "org") {
                  return Org;
                } else if (e.data("node_type") === "field") {
                  return Field;
                }
              },

              "border-color": function (e) {
                return e.data("borderColor");
              },
              "border-width": function (e) {
                return e.data("id") === id ? 10 : 0;
              },

              "border-opacity": 1,
              label: function (e) {
                var t = e.data("name");
                var maxLen = 20;
                if (t.length > maxLen) {
                  t = t.substr(0, maxLen) + "...";
                }
                return t;
              },
              "line-height": 1.3,
              "z-index-compare": "manual",
              "z-index": 20,
              color: "#394A70",
              padding: function (e) {
                return 0;
              },
              "font-size": 16,
              "font-family": "microsoft yahei",
              "text-wrap": "wrap",
              "text-max-width": 160,
              "text-halign": "center",
              "text-valign": "center",
              "overlay-color": "#fff",
              "overlay-opacity": 0,
              "background-opacity": 1,
              "text-background-color": "#394A70",
              "text-background-shape": "roundrectangle",
              "text-background-opacity": function (e) {
                return 0;
              },
              "text-background-padding": 0,
              "text-margin-y": function (e) {
                return e.data("id") === id ? 114 : 74;
              },
              transform: "rotateZ(-90deg)", // 添加旋转属性
            },
          },

          {
            selector: "edge",
            style: {
              color: function (e) {
                return "#AEB1CB";
              },
              "text-opacity": 0.1,
              "line-style": function (e) {
                return "solid";
              },
              "curve-style": "bezier",
              "control-point-step-size": 20,
              "target-arrow-shape": "triangle",
              "target-arrow-color": function (e) {
                return e.data("color");
              },
              "arrow-scale": 0.8,
              "line-color": "#DADADA",
              "font-size": 12,
              "background-color": function (e) {
                return "#AEB1CB";
              },
              width: "0.6",
              "overlay-color": "#fff",
              "overlay-opacity": 0,
              "font-family": "microsoft yahei",
              transform: "rotateZ(-90deg)", // 添加旋转属性
            },
          },
          {
            selector: ".nodeActive",
            style: {
              "border-color": function (e) {
                return e.data("color");
              },
              "border-width": function (e) {
                return e.data("id") === id ? 16 : 10;
              },
              "border-opacity": 0.5,
            },
          },
          {
            selector: ".edgeShow",
            style: {
              color: "#999",
              "text-opacity": 1,
              "font-weight": 400,
              label: function (e) {
                return e.data("label");
              },
              "font-size": 10,
            },
          },
          {
            selector: ".edgeActive",
            style: {
              color: function (e) {
                return e.data("color");
              },
              "arrow-scale": 0.8,
              width: 1.5,
              "text-opacity": 1,
              "font-size": 12,
              "text-background-color": "#fff",
              "text-background-opacity": 0,
              "text-background-padding": 0,
              "source-text-margin-y": 20,
              "target-text-margin-y": 20,
              "z-index-compare": "manual",
              "z-index": 1,
              "line-color": function (e) {
                return e.data("color");
              },
              "target-arrow-color": function (e) {
                return e.data("color");
              },
              label: function (e) {
                return e.data("label");
              },
            },
          },
          {
            selector: ".hidetext",
            style: {
              "text-opacity": 0,
            },
          },
          {
            selector: ".dull",
            style: {
              "z-index": -1,
              opacity: 0.2,
            },
          },
          {
            selector: ".edgeLevel1",
            style: {
              label: function (e) {
                return e.data("label");
              },
            },
          },
          {
            selector: ".edgeShowText",
            style: {
              label: function (e) {
                return "";

                return e.data("label");
              },
            },
          },
          {
            selector: ".lineFixed",
            style: {
              "overlay-opacity": 0,
            },
          },
        ],
      });

      cyRef.current.on("zoom", function () {
        // zoomRef.current = cyRef.current.zoom();
        setZoom(cyRef.current.zoom());
        // forceUpdate({});
        // let min = type === "full" ? 0.3 : 0.5;
        // if (cyRef.current.zoom() < min) {
        //   cyRef.current.collection("node").addClass("hidetext");
        //   cyRef.current.collection("edge").addClass("hidetext");
        // } else {
        //   cyRef.current.collection("node").removeClass("hidetext");
        //   cyRef.current.collection("edge").removeClass("hidetext");
        // }
        // console.log(zoomRef.current);

        setTimeout(function () {
          cyRef.current.collection("edge").removeClass("lineFixed");
          cyRef.current.collection("edge").addClass("lineFixed");
        }, 400);
      });

      // cyRef.current.on("touchstart", "edge", function (e) {
      //   setTimeout(() => {
      //     if (!isTouchMove.current) {
      //       cyRef.current.collection("node").removeClass("nodeActive");
      //       cancelHighLight(cyRef.current);
      //       if (setCurrentNode) {
      //         setCurrentNode({});
      //       }
      //     }
      //   }, 200);
      // });

      // cyRef.current.on("touchend", "edge", function (e) {
      //   isTouchMove.current = false;
      // });

      const calcPostion = (e, currentNode) => {
        const x = e.x;
        const y = e.y;
        let top = y;
        let left = x + 40;
        let width = 340;
        let height = 460;

        if (currentNode.node_type === "paper") {
          width = 468;
          height = 268;
        }
        // 获取窗口的高度和宽度
        const windowHeight = window.innerHeight;
        const windowWidth = window.innerWidth;

        // 检查是否需要调整弹窗的位置
        if (y > windowHeight - height) {
          top = y - height;
        }
        if (x > windowWidth - width) {
          left = x - width - 40;
        }
        left = Math.min(left, windowWidth - width - 20);
        top = Math.min(top, windowHeight - height - 20);

        return {
          x: Math.max(left, 20),
          y: Math.max(top, 20),
        };
      };

      cyRef.current.on("click", "node", function (e) {
        var node = e.target;
        console.log(node._private.data);
        if (setCurrentNode) {
          setTimeout(() => {
            setCurrentNode({
              // position: calcPostion(e.renderedPosition, node._private.data),
              ...node._private.data,
            });
          }, 0);
        }
        highLight(node._private.data.id, cyRef.current);
        node.addClass("nodeActive");
        cyRef.current.resize();
      });

      // 点击画布
      cyRef.current.on("tap", function (e) {
        let id = getUrlParams("id");
        const targetNode = cyRef.current.getElementById(id);

        if (e.target === cyRef.current) {
          cyRef.current.collection("node").removeClass("nodeActive");
          cancelHighLight(cyRef.current);
          if (setCurrentNode) {
            let target = data.nodes.find((item) => item.node_id === id);
            console.log(target);
            setTimeout(() => {
              setCurrentNode(target);
            }, 0);

            highLight(id, cyRef.current);
            targetNode.addClass("nodeActive");
          }
        }
      });

      // 画布拖动
      cyRef.current.on("pan", function (e) {
        isTouchMove.current = true;
        cyRef.current.collection("edge").removeClass("lineFixed");
        cyRef.current.collection("edge").addClass("lineFixed");
      });
      cyRef.current.ready(function () {
        const fn = () => {
          let id = getUrlParams("id");
          if (id === "0") {
            id = "1";
          }
          cyRef.current.collection("edge").addClass("lineFixed");
          cyRef.current.collection("edge").addClass("edgeShowText");
          const targetNode = cyRef.current.getElementById(id);
          cyRef.current.center(targetNode);
          if (targetNode?._private?.data && targetNode._private.data.id) {
            highLight(targetNode._private.data.id, cyRef.current);
            targetNode.addClass("nodeActive");
          }
        };
        setTimeout(
          function () {
            fn();
          },
          type === "full" ? 800 : 300
        );
      });
    }
  };

  const handleChange = (page, size) => {
    setPageSize(size);
    setCurrentPage(page);
  };
  const handleScreenAuto = () => {
    if (cyRef.current && cyRef.current.resize) {
      if (data && data.nodes) {
        drawIt();
      }
    }
  };

  const getData = async () => {
    setLoading(true);
    let params = {
      page_size: pageSize,
      page: currentPage,
      project_id: "cells_and_genes",
      node_type: node_type,
      node_id: id,
    };
    try {
      let res = await getGraph(params);

      // let res = mockData;
      if (res.code === 0) {
        setData(res.data);
        setTotal(res.total);
        if (setCurrentNode) {
          let node = res.data.nodes.find((item) => item.node_id === id);
          setCurrentNode(node);
          if (!rootNode.node_id) {
            setRootNode(node);
          }
        }
      }
    } catch (error) {
      console.log(error);
      if (error.code === "ECONNABORTED") {
        message.error("接口超时，请刷新重试", 5);
      } else {
        message.error("请刷新重试");
      }
    }
    setLoading(false);
  };
  useEffect(() => {
    // window.defaultShowList = [];
    // 监听
    window.addEventListener("resize", _.debounce(handleScreenAuto, 600));
    // 销毁
    return () =>
      window.removeEventListener("resize", _.debounce(handleScreenAuto, 600));
  });
  useEffect(() => {
    if (data && data.nodes) {
      drawIt();
    }
  }, [JSON.stringify(data), rightPartShow]);
  useEffect(() => {
    if (data && data.nodes) {
      drawIt(zoomRef.current);
    }
  }, [zoomRef.current]);
  useEffect(() => {
    getData();
  }, [pageSize, currentPage]);

  const handleTrack = () => {
    if (node_type === "paper") {
      tj.track({
        params: {
          // 上报参数
          event_id: "kgi_cells_and_genes_paper_detail_enter",
        },
      });
    } else if (node_type === "person") {
      tj.track({
        params: {
          // 上报参数
          event_id: "kgi_cells_and_genes_scholar_detail_enter",
        },
      });
    } else if (node_type === "org") {
      tj.track({
        params: {
          // 上报参数
          event_id: "kgi_cells_and_genes_institution_detail_enter",
        },
      });
    }
  };

  const handleFocusTrack = (type) => {
    if (type === "paper") {
      tj.track({
        params: {
          // 上报参数
          event_id: "kgi_cells_and_genes_detail_paper_info_focus_click",
        },
      });
    } else if (type === "person") {
      tj.track({
        params: {
          // 上报参数
          event_id: "kgi_cells_and_genes_detail_scholar_info_focus_click",
        },
      });
    } else if (type === "org") {
      tj.track({
        params: {
          // 上报参数
          event_id: "kgi_cells_and_genes_detail_institution_info_focus_click",
        },
      });
    }
  };
  useEffect(() => {
    handleTrack();
  }, []);
  return (
    <Spin spinning={loading}>
      <div className={styles.graphWapper}>
        <Header className={styles.header} currentNode={rootNode}></Header>
        <div className={styles.part}>
          <div className={styles.leftPart}>
            {currentNode && currentNode.node_type === "person" && (
              <PersonWin currentClickData={currentNode} />
            )}
            {currentNode && currentNode.node_type === "org" && (
              <OrgWin currentClickData={currentNode} />
            )}
            {currentNode && currentNode.node_type === "paper" && (
              <PaperWin currentClickData={currentNode} />
            )}
            {currentNode && currentNode.node_type === "field" && (
              <FieldWin currentClickData={currentNode} />
            )}
          </div>
          <div className={styles.middlePart}>
            <Legend />
            <div id="cy" className={styles.container}></div>

            {total > 20 && (
              <div className={styles.footerBar}>
                <div className={styles.totalBar}>共 {total} 个相关节点 </div>

                <ConfigProvider locale={zhCN}>
                  <Pagination
                    total={total}
                    showSizeChanger
                    defaultPageSize="20"
                    pageSizeOptions={["20", "50", 100]}
                    onChange={handleChange}
                    defaultCurrent={1}
                    className={styles.pagination}
                  />
                </ConfigProvider>
              </div>
            )}

            {hoverNode && hoverNode.position && (
              <NodeToolTip currentNode={hoverNode} />
            )}
            <div className={styles.slider}>
              <Slider
                vertical
                min={0.3}
                max={1.0}
                step={0.1}
                defaultValue={zoomRef.current}
                value={zoom}
                tooltip={{ open: false }}
                onChange={(val) => {
                  zoomRef.current = val;
                  forceUpdate({});
                }}
              />
            </div>
            {currentNode && currentNode.node_type !== "field" && (
              <>
                <Tooltip
                  title="聚焦节点"
                  color={"#77809b"}
                  arrow={false}
                  placement="bottom"
                >
                  <div
                    className={`${styles.iconContainer}`}
                    onClick={() => {
                      handleFocusTrack(currentNode.node_type);
                      navigate(
                        `/graph_detail?id=${currentNode.node_id}&node_type=${currentNode.node_type}`
                      );
                    }}
                  >
                    <span
                      className={`${styles.focusBtn} iconfont icon-Frame`}
                    ></span>
                    <span className={`${styles.iconText}`}>聚焦</span>
                  </div>
                </Tooltip>
                <Tooltip
                  title="查看详情"
                  color={"#77809b"}
                  arrow={false}
                  placement="bottom"
                >
                  <div
                    className={`${styles.iconDetailContainer}`}
                    onClick={() => {
                      console.log(currentNode.node_type);
                      if (currentNode.node_type === "paper") {
                        tj.track({
                          params: {
                            // 上报参数
                            event_id:
                              "kgi_cells_and_genes_detail_paper_info_details_click",
                          },
                        });
                      } else if (currentNode.node_type === "person") {
                        tj.track({
                          params: {
                            // 上报参数
                            event_id:
                              "kgi_cells_and_genes_detail_scholar_info_details_click",
                          },
                        });
                      }
                      currentNode.url && window.open(currentNode.url);
                    }}
                  >
                    <span
                      className={`${styles.viewDetailBtn} iconfont icon-xiangqing`}
                    ></span>
                    <span className={`${styles.iconText}`}>详情</span>
                  </div>
                </Tooltip>
              </>
            )}

            {!rightPartShow && (
              <Tooltip title="展开对话窗口" color={"#77809b"}>
                <img
                  src={messagePng}
                  className={styles.messagePng}
                  onClick={() => {
                    tj.track({
                      params: {
                        // 上报参数
                        event_id: "kgi_cells_and_genes_detail_chat_click",
                      },
                    });
                    setRightPartShow(!rightPartShow);
                  }}
                />
              </Tooltip>
            )}
          </div>

          <div
            className={`${styles.rightPart} ${
              rightPartShow && styles.rightPartShow
            }`}
          >
            <div className={styles.chatTop}>
              <p className={styles.chatTitle}>对话检索</p>
              <div className={styles.chatIcon}>
                <Tooltip title="帮助" color={"#6f737c"}>
                  <span
                    className={`iconfont icon-bangzhu`}
                    onClick={() => {
                      tj.track({
                        params: {
                          // 上报参数
                          event_id:
                            "kgi_cells_and_genes_detail_chat_expand_help_click",
                        },
                      });
                      window.open("/Guide", "_blank");
                    }}
                  ></span>
                </Tooltip>
                <Tooltip title="全屏" color={"#6f737c"}>
                  <span
                    className={`iconfont icon-quanping`}
                    onClick={() => {
                      let chatHistory = localStorage.getItem("chatHistory");
                      try {
                        chatHistory = JSON.parse(chatHistory);
                      } catch (error) {
                        console.log(error);
                      }
                      if (chatHistory && chatHistory.length) {
                        window.defaultShowList = chatHistory;
                      }
                      tj.track({
                        params: {
                          // 上报参数
                          event_id:
                            "kgi_cells_and_genes_detail_chat_expand_full_screen_click",
                        },
                      });
                      navigate("/Chat");
                    }}
                  ></span>
                </Tooltip>
                <Tooltip title="收起" color={"#6f737c"}>
                  <span
                    className={`iconfont icon-shouqi`}
                    onClick={() => {
                      tj.track({
                        params: {
                          // 上报参数
                          event_id:
                            "kgi_cells_and_genes_detail_chat_expand_fold_click",
                        },
                      });
                      setRightPartShow(!rightPartShow);
                    }}
                  ></span>
                </Tooltip>
              </div>
            </div>

            <div className={styles.chatContent}>
              <EntryPage pageType="small" />
            </div>
          </div>
        </div>
      </div>
    </Spin>
  );
});
