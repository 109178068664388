import React from "react";
import styled from "styled-components";

const StyledOverlap = styled.div`
  height: 1355px;
  left: 340px;
  position: absolute;
  top: 1686px;
  width: 920px;

  & .element-13 {
    background-color: #ffffff;
    border-radius: 8px;
    background-size: 100% 100%;
    height: 1355px;
    left: 0;
    position: absolute;
    top: 0;
    width: 920px;
  }

  & .div-3 {
    color: transparent;
    font-family: "PingFang SC-Regular", Helvetica;
    font-size: 14px;
    font-weight: 400;
    left: 68px;
    letter-spacing: 0;
    line-height: 19.6px;
    position: absolute;
    top: 90px;
    width: 832px;

    & .text-wrapper-10 {
      color: #333333;
    }

    & .text-wrapper-11 {
      color: #316efc;
      font-family: "PingFang SC-Medium", Helvetica;
      font-weight: 500;
    }
  }

  & .div-4 {
    color: transparent;
    font-family: "PingFang SC-Regular", Helvetica;
    font-size: 14px;
    font-weight: 400;
    left: 67px;
    letter-spacing: 0;
    line-height: 19.6px;
    position: absolute;
    top: 936px;
    width: 832px;

    & .text-wrapper-10 {
      color: #333333;
    }

    & .text-wrapper-11 {
      color: #316efc;
      font-family: "PingFang SC-Medium", Helvetica;
      font-weight: 500;
    }
  }

  & .div-5 {
    color: transparent;
    font-family: "PingFang SC-Regular", Helvetica;
    font-size: 14px;
    font-weight: 400;
    left: 68px;
    letter-spacing: 0;
    line-height: 19.6px;
    position: absolute;
    top: 540px;
    width: 832px;

    & .text-wrapper-10 {
      color: #333333;
    }

    & .text-wrapper-11 {
      color: #316efc;
      font-family: "PingFang SC-Medium", Helvetica;
      font-weight: 500;
    }
  }

  & .element-14 {
    height: 30px;
    left: 20px;
    position: absolute;
    top: 40px;
    width: 127px;

    & .text-wrapper-12 {
      color: #316efc;
      font-family: "PingFang SC-Regular", Helvetica;
      font-size: 20px;
      font-weight: 400;
      left: 47px;
      letter-spacing: 0;
      line-height: 28px;
      position: absolute;
      top: 1px;
      white-space: nowrap;
    }

    & .element-15 {
      height: 30px;
      left: 0;
      overflow: hidden;
      position: absolute;
      top: 0;
      width: 41px;

      & .overlap-group-5 {
        height: 30px;
        left: -1px;
        position: relative;
        width: 42px;

        & .element-16 {
          height: 10px;
          left: 29px;
          position: absolute;
          top: 20px;
          width: 13px;
        }

        & .element-17 {
          height: 30px;
          left: 1px;
          position: absolute;
          top: 0;
          width: 32px;
        }

        & .element-18 {
          color: #ffffff;
          font-family: "DINPro-Bold", Helvetica;
          font-size: 22px;
          font-weight: 700;
          left: 0;
          letter-spacing: 0;
          line-height: 26px;
          position: absolute;
          text-align: center;
          top: 1px;
          width: 34px;
        }
      }
    }
  }

  & .img-wrapper {
    background-color: #263b5f08;
    height: 347px;
    left: 68px;
    position: absolute;
    top: 169px;
    width: 832px;

    & .element-19 {
      height: 331px;
      left: 150px;
      position: absolute;
      top: 12px;
      width: 532px;
    }
  }

  & .element-20 {
    background-color: #263b5f08;
    height: 332px;
    left: 69px;
    position: absolute;
    top: 580px;
    width: 832px;

    & .frame-3 {
      height: 300px;
      left: 77px;
      position: relative;
      top: 16px;
      width: 678px;

      & .overlap-group-6 {
        height: 316px;
        left: -8px;
        position: relative;
        top: -4px;
        width: 694px;

        & .overlap-group-7 {
          height: 316px;
          left: 0;
          position: absolute;
          top: 0;
          width: 694px;

          & .element-21 {
            height: 146px;
            left: 0;
            position: absolute;
            top: 0;
            width: 366px;
          }

          & .element-22 {
            height: 241px;
            left: 254px;
            object-fit: cover;
            position: absolute;
            top: 75px;
            width: 440px;
          }
        }

        & .image-2 {
          height: 40px;
          left: 206px;
          position: absolute;
          top: 152px;
          width: 41px;
        }
      }
    }
  }

  & .element-23 {
    background-color: #263b5f08;
    height: 335px;
    left: 69px;
    position: absolute;
    top: 996px;
    width: 832px;

    & .overlap-group-8 {
      height: 319px;
      left: 126px;
      position: relative;
      top: 12px;
      width: 580px;

      & .element-24 {
        height: 297px;
        left: 0;
        position: absolute;
        top: 0;
        width: 459px;
      }

      & .element-25 {
        height: 116px;
        left: 394px;
        position: absolute;
        top: 203px;
        width: 186px;
      }
    }

    & .image-3 {
      height: 48px;
      left: 456px;
      position: absolute;
      top: 159px;
      width: 48px;
    }
  }
`;

export const Overlap = () => {
  return (
    <StyledOverlap id="chat">
      <div className="element-13">
        <p className="div-3">
          <span className="text-wrapper-10">首页点击</span>
          <span className="text-wrapper-11">【对话】</span>
          <span className="text-wrapper-10">
            按钮进入对话检索页面，您可以通过自然语言向我提问，支持查询细胞与基因领域的论文、学者、机构、专利和领域信息（比如：2022年清华大学在细胞与基因领域发表过哪些论文？），同时您还可以连续追问，让模型帮您总结论文摘要、提炼方法结论、追溯作者机构、推荐相似论文等（比如：第二篇论文主要讲了什么？用了什么方法？由什么机构发表...
            ...）
          </span>
        </p>
        <p className="div-4">
          <span className="text-wrapper-10">点击生成答案下方</span>
          <span className="text-wrapper-11">【复制】</span>
          <span className="text-wrapper-10">和</span>
          <span className="text-wrapper-11">【重新生成】</span>
          <span className="text-wrapper-10">
            按钮，可实现文本复制和生成新答案；点击
          </span>
          <span className="text-wrapper-11">【点赞】</span>
          <span className="text-wrapper-10">/</span>
          <span className="text-wrapper-11">【点踩】</span>
          <span className="text-wrapper-10">
            按钮，及时对模型生成的结果进行反馈，您的每一个反馈都将帮助模型学习更多知识，持续进步，从而更快更好地帮助您获取信息，提升效率，解决问题。
          </span>
        </p>
        <p className="div-5">
          <span className="text-wrapper-10">在生成答案处点击</span>
          <span className="text-wrapper-11">【检索详情】</span>
          <span className="text-wrapper-10">
            查看模型检索条件，获取列表详情信息；
          </span>
        </p>
        <div className="element-14">
          <div className="text-wrapper-12">对话检索</div>
          <div className="element-15">
            <div className="overlap-group-5">
              <img
                className="element-16"
                alt="Element"
                src="/assets/account/1-3.svg"
              />
              <img
                className="element-17"
                alt="Element"
                src="/assets/account/1-4.svg"
              />
              <div className="element-18">03</div>
            </div>
          </div>
        </div>
        <div className="img-wrapper">
          <img
            className="element-19"
            alt="Element"
            src="/assets/account/image.png"
          />
        </div>
      </div>
      <div className="element-20">
        <div className="frame-3">
          <div className="overlap-group-6">
            <div className="overlap-group-7">
              <img
                className="element-21"
                alt="Element"
                src="/assets/account/1-2x.png"
              />
              <img
                className="element-22"
                alt="Element"
                src="/assets/account/1-3.png"
              />
            </div>
            <img
              className="image-2"
              alt="Image"
              src="/assets/account/image-82.png"
            />
          </div>
        </div>
      </div>
      <div className="element-23">
        <div className="overlap-group-8">
          <img
            className="element-24"
            alt="Element"
            src="/assets/account/1-4-2x.png"
          />
          <img
            className="element-25"
            alt="Element"
            src="/assets/account/1-1-2x.png"
          />
          <img
            className="image-3"
            alt="Image"
            src="/assets/account/image-82-1.png"
          />
        </div>
      </div>
    </StyledOverlap>
  );
};
