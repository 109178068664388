export const exampleList: any = {
  paper: [
    "有哪些关于基因芯片领域的论文可以参考？",
    "我想了解，麻省理工学院在今年的论文总量是多少？",
    "有哪些论文在细胞激活领域被引用次数最多？",
    "请问一下，北京大学在2009年发表论文数是多少？",
    "请问一下，加州理工的论文总量是多少？",
    "请问TCR-T领域有哪些高引论文？",
    "基因编辑技术领域引用量最多的论文是哪一篇？",
    "我想了解，复旦大学在靶点发现验证领域今年的论文总量是多少？",
    "请问免疫细胞治疗领域引用量最高的论文是哪篇？",
    "请问一下，在2006年，基因治疗领域引用量最多的论文？",
    "清华的教授们今年一共发表了多少篇论文？",
    "小核酸药物领域有哪些论文？",
    "华大基因今年发了哪些论文？",
    "莱顿大学2022年发了哪些论文？",
    "请问一下生物育种领域的论文有哪些？",
    "请问一下，有哪些论文研究方向属于基因测序？",
  ],
  patent: [
    "北大在基因芯片领域有哪些专利？",
    "请问清华大学有多少个专利？",
    "在细胞转导技术领域，申请年份大于2010年的专利数量是多少？",
    "清华在生物育种发表的专利有哪些？",
    "基因合成有哪些专利呢？",
    "请问一下生物育种领域的专利有哪些？",
    "在基因检测领域，有哪些专利？第一篇专利用了什么技术？",
    "请问一下基因测序有哪些子领域，分别有哪些专利呢？",
    "基因合成有哪些专利呢？",
    "2018年，基因测序领域有哪些专利?",
    "近五年内，在基因治疗领域，耶鲁大学发表过哪些专利?",
  ],
  scholar: [
    "耶鲁大学的学者中，论文数最多的是谁？",
    "请问一下研究细胞分离或者细胞存储的学者的hindex最高的是哪位？",
    "请问耶鲁大学有多少位致力于细胞转导技术研究的学者？",
    "能否告诉我基因测序领域中，h指数最高的学者是哪些？",
    "请问在细胞扩增培养领域，哈佛大学的学者中，论文数最多的是谁？",
    "请问清华大学中有哪些学者的引用量最大？",
    "剑桥发文最多的学者是谁？",
    "请问一下研究细胞扩增培养或者细胞激活的学者有哪些？",
    "请问一下，有哪些学者和李英睿合作过？",
    "NGS测序技术有哪些学者？",
    "2020年，斯坦福大学在有哪些学者的引用量最大?",
    "耶鲁大学在基因合成领域有哪些高引用学者？",
  ],
  org: [
    "请列举出在生物育种领域，论文数最多的机构是哪些？",
    "请问在领域是基因芯片或者领域是基因测序方面，有哪些值得推荐的机构？",
    "肿瘤诊断基因检测领域或是遗传病领域有哪些值得推荐的机构呢？",
    "2019年，在细胞与基因领域发表论文超过200篇的有哪些机构？",
    "2018年，在基因药物产品领域发表论文最多的机构有哪些？",
    "2021年，在生物育种领域发表论文引用量超过100的机构有哪些？",
  ],
};
