import React from "react";
import styled from "styled-components";

const StyledOverlap = styled.div`
  background-color: #ffffff;
  border-radius: 8px;
  height: 808px;
  left: 340px;
  position: absolute;
  top: 172px;
  width: 920px;

  & .div {
    height: 84px;
    left: 39px;
    position: absolute;
    top: 146px;
    width: 861px;
  }

  & .text-wrapper {
    color: #333333;
    font-family: "PingFang SC-Regular", Helvetica;
    font-size: 14px;
    font-weight: 400;
    left: 61px;
    letter-spacing: 0;
    line-height: 19.6px;
    position: absolute;
    top: 45px;
    width: 801px;
  }

  & .text-wrapper-2 {
    color: #316efc;
    font-family: "PingFang SC-Medium", Helvetica;
    font-size: 16px;
    font-weight: 500;
    left: 61px;
    letter-spacing: 0;
    line-height: 28px;
    position: absolute;
    top: 5px;
    white-space: nowrap;
  }

  & .element {
    color: #316efc4c;
    font-family: "DINPro-Bold", Helvetica;
    font-size: 36px;
    font-weight: 700;
    left: 0;
    letter-spacing: 0;
    line-height: 26px;
    position: absolute;
    text-align: center;
    top: -1px;
    white-space: nowrap;
  }

  & .frame-2 {
    height: 84px;
    left: 39px;
    position: absolute;
    top: 254px;
    width: 861px;
  }

  & .frame-3 {
    height: 84px;
    left: 39px;
    position: absolute;
    top: 362px;
    width: 861px;
  }

  & .frame-4 {
    height: 84px;
    left: 39px;
    position: absolute;
    top: 682px;
    width: 861px;
  }

  & .frame-5 {
    height: 66px;
    left: 39px;
    position: absolute;
    top: 592px;
    width: 861px;
  }

  & .frame-6 {
    height: 66px;
    left: 39px;
    position: absolute;
    top: 470px;
    width: 861px;
  }

  & .text-wrapper-3 {
    color: #316efc;
    font-family: "PingFang SC-Medium", Helvetica;
    font-size: 16px;
    font-weight: 500;
    left: 61px;
    letter-spacing: 0;
    line-height: 28px;
    position: absolute;
    top: 5px;
    width: 798px;
  }

  & .text-wrapper-4 {
    color: #333333;
    font-family: "PingFang SC-Regular", Helvetica;
    font-size: 14px;
    font-weight: 400;
    left: 98px;
    letter-spacing: 0;
    line-height: 19.6px;
    position: absolute;
    top: 548px;
    width: 801px;
  }

  & .frame-7 {
    height: 102px;
    left: 39px;
    position: absolute;
    top: 20px;
    width: 861px;
  }

  & .overlap-group-2 {
    height: 101px;
    left: 59px;
    position: absolute;
    top: 0;
    width: 803px;
  }

  & .overlap-group-3 {
    height: 52px;
    left: 0;
    position: absolute;
    top: 49px;
    width: 803px;
  }

  & .text-wrapper-5 {
    color: #333333;
    font-family: "PingFang SC-Regular", Helvetica;
    font-size: 14px;
    font-weight: 400;
    left: 2px;
    letter-spacing: 0;
    line-height: 19.6px;
    position: absolute;
    top: 12px;
    width: 801px;
  }

  & .img {
    height: 33px;
    left: 0;
    position: absolute;
    top: 0;
    width: 28px;
  }

  & .frame-8 {
    height: 24px;
    left: 354px;
    position: absolute;
    top: 9px;
    width: 28px;
  }

  & .overlap-group-4 {
    height: 56px;
    left: 2px;
    position: absolute;
    top: 0;
    width: 304px;
  }

  & .text-wrapper-6 {
    color: #316efc;
    font-family: "PingFang SC-Medium", Helvetica;
    font-size: 16px;
    font-weight: 500;
    left: 0;
    letter-spacing: 0;
    line-height: 28px;
    position: absolute;
    top: 17px;
    white-space: nowrap;
  }

  & .overlap-group-wrapper {
    background-color: #ffffff;
    border: 1px solid;
    border-color: #316efc26;
    border-radius: 45px;
    height: 56px;
    left: 99px;
    position: absolute;
    top: 0;
    width: 56px;
  }

  & .overlap-group-5 {
    height: 44px;
    left: 14px;
    position: relative;
    top: 7px;
    width: 28px;
  }

  & .frame-9 {
    height: 28px;
    left: 0;
    position: absolute;
    top: 0;
    width: 28px;
  }

  & .text-wrapper-7 {
    color: #263b5fcc;
    font-family: "PingFang SC-Regular", Helvetica;
    font-size: 12px;
    font-weight: 400;
    left: 2px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 27px;
  }

  & .element-2 {
    color: #316efc4c;
    font-family: "DINPro-Bold", Helvetica;
    font-size: 36px;
    font-weight: 700;
    height: 26px;
    left: 0;
    letter-spacing: 0;
    line-height: 26px;
    position: absolute;
    text-align: center;
    top: 11px;
    white-space: nowrap;
  }
`;

export const Overlap = () => {
  return (
    <StyledOverlap>
      <div className="div">
        <div className="text-wrapper">
          当您所查询的实体未被专题知识库收录，模型模糊处理后，会推荐相似内容（领域词、学者）供参考；如若模型无法回答，请您尝试更换提问内容。
        </div>
        <div className="text-wrapper-2">
          对话提问得到回复“未找到搜索信息... ...”
        </div>
        <div className="element">Q2</div>
      </div>
      <div className="frame-2">
        <div className="text-wrapper">
          在多轮问答时，当前后提问内容无关联或关联性不大，受前设问题影响，模型未能正确识别新提问内容，导致回复异常。您可以清空对话，重新提问。
        </div>
        <div className="text-wrapper-2">
          多轮问答中，会出现模型回复不准确或回复错误，以及问题识别异常等情况。
        </div>
        <div className="element">Q3</div>
      </div>
      <div className="frame-3">
        <div className="text-wrapper">
          因问题描述模糊，模型解析失败，导致错误识别检索条件。您可点击【重试】或清空对话重新提问；也可以换种方式提问，准确描述问题条件。
        </div>
        <div className="text-wrapper-2">模型提示查询失败... ...</div>
        <div className="element">Q4</div>
      </div>
      <div className="frame-4">
        <div className="text-wrapper">
          您可以点击【重新生成】按钮，获取不同答案；您也可以选择【点踩】提交问题反馈。我们非常重视每一位用户反馈的意见，并会及时做出优化调整。
        </div>
        <div className="text-wrapper-2">
          当您对回复内容不满意或认为回复不准确时？
        </div>
        <div className="element">Q7</div>
      </div>
      <div className="frame-5">
        <div className="text-wrapper">
          该情况存在模型服务异常可能，请您直接点击重试或者清空对话。
        </div>
        <div className="text-wrapper-2">
          对话提问时，模型回复“很抱歉，查询服务出了一点问题。您可以稍后重试或者我们先聊点别的~
          ”
        </div>
        <div className="element">Q6</div>
      </div>
      <div className="frame-6">
        <div className="text-wrapper-3">
          对话提问时，得到回复“很抱歉，出了一点问题。导致我们无法完成您的请求。请检查您的网络连接或稍后重试...
          ...”
        </div>
        <div className="element">Q5</div>
      </div>
      <div className="text-wrapper-4">
        系统更新中，模型报错，请您稍后重试或及时与我们技术团队联系。
      </div>
      <div className="frame-7">
        <div className="overlap-group-2">
          <div className="overlap-group-3">
            <div className="text-wrapper-5">
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;是聚焦节点按钮。在知识图谱中选中某一节点，点击&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;
              按钮，图谱会展示以该知识节点为核心的关联图谱信息，左侧详情页面也会自动切换。
            </div>
            <img
              className="img"
              alt="Frame"
              src="/assets/question/frame-516.svg"
            />
            <img
              className="frame-8"
              alt="Frame"
              src="/assets/question/frame-517.svg"
            />
          </div>
          <div className="overlap-group-4">
            <div className="text-wrapper-6">
              图谱详情页的&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;按钮，是什么功能？
            </div>
            <div className="overlap-group-wrapper">
              <div className="overlap-group-5">
                <img
                  className="frame-9"
                  alt="Frame"
                  src="/assets/question/frame.svg"
                />
                <div className="text-wrapper-7">聚焦</div>
              </div>
            </div>
          </div>
        </div>
        <div className="element-2">Q1</div>
      </div>
    </StyledOverlap>
  );
};
