import react, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
  useRef,
  useContext,
} from "react";
import _ from "lodash";
import styles from "./index.less";
import DefaultIndustry from "src/image/chatGraph/defaultListOrg.png";
import DefaultScholar from "src/image/chatGraph/defaultListPeople.png";
import { useNavigate, Link, useLocation } from "react-router-dom";
import Empty from "src/image/chatGraph/empty.svg";
import ExpandableText from "src/component/ExpandableText/index";
import LoadingGif from "src/image/loading.gif";
import { getUrlParams } from "src/ChatPages/Graph/util.js";
import tj from "@itic-sci/teamjob";

import { getScholarList } from "src/services/api";
import { UserContext } from "src/Context";
import { Pagination, Spin, message, ConfigProvider, Popover } from "antd";
import zhCN from "antd/lib/locale/zh_CN";
export interface IListData {
  scholar_name: string;
  avatar: string;
  org_name: string;
  region: string;
  npubs: number;
  profile: string;
  org_nameZh: string;
  scholar_nameZh: string;
  city: string;
  ncitation: string;
  hindex: number;
}
interface IScholar {
  searchStr: string;
}

const sortTag = [
  {
    key: "weight",
    label: "综合排序",
    track: "kgi_cells_and_genes_search_result_scholar_sort_comprehensive_click",
  },
  {
    key: "n_citation",
    label: "引用量",
    track: "kgi_cells_and_genes_search_result_scholar_sort_cites_click",
  },
  {
    key: "h_index",
    label: "H-index",
    track: "kgi_cells_and_genes_search_result_scholar_sort_h-index_click",
  },

  {
    key: "n_pubs",
    label: "论文数",
    track: "kgi_cells_and_genes_search_result_scholar_sort_papers_click",
  },
];
function Scholar(props: IScholar) {
  const { searchStr } = props;
  const listRef = useRef<HTMLDivElement>(null);
  const currentPageRef = useRef(1);
  const [isloading, setIsLoading] = useState(false);
  const listDataRef = useRef([]);
  const navigate = useNavigate();
  const [currentTag, setCurrentTag] = useState<string>("weight");

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [total, setTotal] = useState<number>(0);
  const [listData, setListData] = useState<Array<IListData>>([]);
  const getListData = async () => {
    try {
      setIsLoading(true);

      let res: any = await getScholarList({
        node_name: "细胞与基因",
        node_layer: 0,
        size: pageSize,
        page: currentPage,
        sort_field: currentTag,
        keyword: searchStr,
      });
      setIsLoading(false);

      if (res.code === 0) {
        let temp = res.data.scholars;
        window.scrollTo(0, 0);

        setListData(temp);
        setTotal(res.data.total);
        listDataRef.current = temp;
      } else {
        message.error("系统错误，请稍后重试");
      }
    } catch (e: any) {
      console.log(e);
      setIsLoading(false);
      if (e.code === "ECONNABORTED") {
        message.error("接口超时，请刷新重试", 5);
      } else {
        message.error("系统错误，请稍后重试");
      }
    }
  };

  const handleChange = (page: number, pageSize: number) => {
    tj.track({
      params: {
        // 上报参数
        event_id: "kgi_cells_and_genes_search_result_scholar_pageflipper_click",
      },
    });
    setPageSize(pageSize);
    setCurrentPage(page);
  };

  useEffect(() => {
    if (listRef.current) {
      listRef.current.scrollTop = 0;
    }
    if (getUrlParams("tag") === "scholar") {
      getListData();
    }
  }, [currentPage, pageSize, searchStr, currentTag]);
  const renderPop = (author: any) => {
    return (
      <div key={author.node_id} className={styles.orgItem}>
        <img
          src={author.org_card.image || DefaultIndustry}
          alt=""
          onClick={() =>
            window.open(`/graph_detail?id=${author.org_id}&node_type=org`)
          }
          className={styles.leftIcon}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = DefaultIndustry;
          }}
        />
        <div className={styles.rightPart}>
          <div className={styles.topInfo}>
            <p
              title={author.org_card.org_nameZh || author.org_card.org_name}
              className={styles.topTitle + " text-ellipsis "}
              onClick={() => {
                if (author.aminer_id) {
                  window.open(
                    `/graph_detail?id=${author.org_id}&node_type=org`,
                    "_blank"
                  );
                }
              }}
            >
              {author.org_card.org_nameZh || author.org_card.org_name}
            </p>
          </div>
          <div className={styles.bottomInfo}>
            <div className={styles.bottomCount}>
              <div className={styles.bottomDetail}>
                <span className={styles.label}>论文数：</span>
                <span className={styles.count}>
                  {author.org_card.n_papers === 10000
                    ? "10000+"
                    : author.org_card.n_papers || "0"}
                </span>
              </div>
              <div className={styles.bottomDetail}>|</div>
              <div className={styles.bottomDetail}>
                <span className={styles.label}>引用数：</span>
                <span className={styles.count}>
                  {author.org_card.n_citation || "0"}
                </span>
              </div>
              <div className={styles.bottomDetail}>|</div>
              <div
                className={`${styles.bottomDetail} ${styles.bottomDetailLeft}`}
              >
                <span className={styles.label}>相关实体：</span>
                <span className={styles.count}>
                  {author.org_card.sub_graph_total || "0"}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className={styles.scholar}>
        <Spin
          spinning={isloading}
          indicator={
            <img
              src={LoadingGif}
              style={{
                width: 100,
                height: 100,
                display: "inline-block",
                marginTop: 100,
                transform: "translateX(-250px)",
              }}
            />
          }
        >
          <div className={styles.listCt} ref={listRef}>
            {Array.isArray(listData) && !!listData.length && (
              <>
                <div className={styles.totalBar}>
                  共找到 {total} 个相关学者实体
                  <div className={styles.sortCt}>
                    <span className={styles.sortLabel}>
                      <span className="iconfont icon-paixu"></span>
                      <span> 排序方式：</span>
                    </span>
                    <div className={styles.sortItem}>
                      {sortTag.map((item, index) => (
                        <span
                          key={index}
                          onClick={() => {
                            tj.track({
                              params: {
                                // 上报参数
                                event_id: item.track,
                              },
                            });
                            setCurrentPage(1);
                            setCurrentTag(item.key);
                          }}
                          className={
                            currentTag === item.key ? styles.actived : ""
                          }
                        >
                          {item.label}
                        </span>
                      ))}
                    </div>
                  </div>
                </div>
                <div>
                  {listData.map((item: any, index) => (
                    <div key={index} className={styles.listItem}>
                      <img
                        src={item.avatar || DefaultScholar}
                        alt=""
                        onClick={() => {
                          tj.track({
                            params: {
                              // 上报参数
                              event_id:
                                "kgi_cells_and_genes_search_result_scholar_result_click",
                            },
                          });
                          window.open(
                            `/graph_detail?id=${item.node_id}&node_type=${item.node_type}`
                          );
                        }}
                        className={styles.leftIcon}
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null; // prevents looping
                          currentTarget.src = DefaultScholar;
                        }}
                      />
                      <div className={styles.rightPart}>
                        <div className={styles.topInfo}>
                          <p
                            className={styles.topTitle + " text-two-ellipsis "}
                            onClick={() => {
                              tj.track({
                                params: {
                                  // 上报参数
                                  event_id:
                                    "kgi_cells_and_genes_search_result_scholar_result_click",
                                },
                              });
                              window.open(
                                `/graph_detail?id=${item.node_id}&node_type=${item.node_type}`
                              );
                            }}
                          >
                            {item.scholar_nameZh || item.scholar_name}
                          </p>
                          {item.scholar_nameZh && item.scholar_name && (
                            <span className={styles.region}>
                              （{item.scholar_name}）
                            </span>
                          )}
                        </div>
                        {item.org_card && (
                          <Popover
                            arrow={false}
                            key={item.scholar_name}
                            content={renderPop(item)}
                            placement="bottom"
                            trigger="hover"
                          >
                            <p
                              className={`${styles.belongOrg} ${
                                styles.belongOrgActive
                              } ${
                                (
                                  item.org_card.org_nameZh ||
                                  item.org_card.org_name
                                ).length > 120 && styles.belongOrgLong
                              } text-two-ellipsis`}
                              onClick={() => {
                                tj.track({
                                  params: {
                                    // 上报参数
                                    event_id:
                                      "kgi_cells_and_genes_search_result_scholar_result_author_click",
                                  },
                                });
                                window.open(
                                  `/graph_detail?id=${item.org_id}&node_type=org`
                                );
                              }}
                            >
                              {item.org_card.org_nameZh ||
                                item.org_card.org_name}
                            </p>
                          </Popover>
                        )}

                        {!item.org_card && (
                          <p
                            className={`${styles.belongOrg} ${styles.belongOrgLong} text-two-ellipsis`}
                            // onClick={() =>
                            //   item.org_id &&
                            //   window.open(
                            //     "https://brain.itic-sci.com/institution/" + item.org_id
                            //   )
                            // }
                            title={item.org_nameZh || item.org_name || "--"}
                          >
                            {item.org_nameZh || item.org_name}
                          </p>
                        )}
                        <div className={styles.bottomInfo}>
                          <div className={styles.bottomCount}>
                            <div
                              className={`${styles.bottomDetail} ${styles.bottomDetailLeft}`}
                            >
                              <span className={styles.label}>H-index：</span>
                              <span className={styles.count}>
                                {item.hindex || "0"}
                              </span>
                            </div>
                            <div className={styles.bottomDetail}>
                              <span className={styles.label}>引用量：</span>
                              <span className={styles.count}>
                                {item.ncitation || "0"}
                              </span>
                            </div>
                            <div className={styles.bottomDetail}>
                              <span className={styles.label}>论文数：</span>
                              <span className={styles.count}>
                                {item.npubs === 10000
                                  ? "10000+"
                                  : item.npubs || "0"}
                              </span>
                            </div>
                            <div className={styles.bottomDetail}>
                              <span className={styles.label}>相关实体：</span>
                              <span className={styles.count}>
                                {item.sub_graph_total || "0"}
                              </span>
                            </div>
                          </div>
                        </div>

                        <ExpandableText
                          text={item.profile}
                          key={item.node_id}
                          track="kgi_cells_and_genes_search_result_scholar_result_more_abstract_click"
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </>
            )}
          </div>
          {!isloading && Array.isArray(listData) && !listData.length && (
            <div className={styles.emptyPart}>
              <img src={Empty} alt="" />
              <p>找不到相关内容</p>
            </div>
          )}
          {!!total && (
            <div className={styles.footerBar}>
              <ConfigProvider locale={zhCN}>
                <Pagination
                  current={currentPage}
                  total={total}
                  onChange={handleChange}
                  showSizeChanger={false}
                  defaultCurrent={1}
                  className={styles.pagination}
                />
              </ConfigProvider>
            </div>
          )}
        </Spin>
      </div>
    </>
  );
}

export default forwardRef(Scholar);
