import react, { useState, useRef, useEffect } from "react";
import styles from "./index.less";
import CommonTitle from "../CommonTitle";
import empty from "../../image/gkx/empty.svg";
import { getNodeDetail } from "../../services/api";
import _ from "lodash";
interface IFieldDetailPart {
  currentClickData: any;
  treedata: any;
  GraphCmpRef: any;
  setCurrentClickData: any;
}
function findNode(node: any, id: string) {
  if (node.node_id === id) {
    return node;
  }

  for (var i = 0; i < node.children.length; i++) {
    var result: any = findNode(node.children[i], id);
    if (result) {
      return result;
    }
  }

  return null;
}
function FieldDetailPart(props: IFieldDetailPart) {
  const { currentClickData, treedata, GraphCmpRef, setCurrentClickData } =
    props;
  const [rawData, setRawData] = useState<any>({});
  const [list, setList] = useState<any>([]);

  const getData = async (params: any) => {
    let res: any = await getNodeDetail({
      data: [params.name_zh || "细胞与基因"],
      node_type: params.node_type,
    });
    if (res.code === 0) {
      let temp = res.data.details || [];
      setRawData(temp[0]);
    }
  };

  useEffect(() => {
    if (treedata && treedata[0]) {
      setList(findNode(treedata[0], currentClickData.id || "1"));
    }
  }, [JSON.stringify(currentClickData), JSON.stringify(treedata)]);
  useEffect(() => {
    if (currentClickData.node_type === "field") {
      getData(currentClickData);
    }
  }, [JSON.stringify(currentClickData)]);

  return (
    <>
      <div
        className={
          currentClickData.node_type === "field"
            ? styles.FieldDetailPart
            : styles.hide
        }
      >
        <span className={styles.title}>{currentClickData.name_zh}</span>

        <div className={styles.detail}>
          <CommonTitle title="领域简介" className={styles.introduceLable} />
          {!!rawData?.desc?.length && (
            <span className={styles.introduce}>{rawData.desc}</span>
          )}
          {!rawData?.desc?.length && (
            <div className={styles.emptyBox}>
              <img className={styles.emptyImg} src={empty} alt="" />
              <span className={styles.emptyText}>暂无领域简介</span>
            </div>
          )}
          <CommonTitle title="相关领域" className={styles.relativeLable} />
          <div className={styles.listCt}>
            {list &&
              list.relate_fields &&
              list.relate_fields.slice(0, 6).map((item: any) => (
                <div
                  className={styles.itemList}
                  onClick={() => {
                    setCurrentClickData(item);
                    // GraphCmpRef.current.handleReset();

                    GraphCmpRef.current.clickNode(item.node_id);
                    GraphCmpRef.current.updateLayout(item.node_id);
                  }}
                >
                  <span className={styles.itemText}>{item.name_zh}</span>
                  <span
                    className={`icon-caret-right iconfont ${styles.iconfont}`}
                  ></span>
                </div>
              ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default FieldDetailPart;
