import react, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
  useRef,
  useLayoutEffect,
  useContext,
} from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { getUrlParams } from "src/ChatPages/Graph/util.js";
import useUrlState from "@ahooksjs/use-url-state";
import styles from "./index.less";
import InputClear from "src/image/inputClear.svg";
import Scholar from "./Tables/Scholar";
import Header from "./Header";
import AuthApi from "src/services/auth/index";
import Industry from "./Tables/Industry";
import UserImg from "src/image/gkx/default_head_img.png";
import tj from "@itic-sci/teamjob";

import Paper from "./Tables/Paper";
import { Popover, Tabs, Input, message } from "antd";
import { useSharedState, defaultUserInfo } from "src/UserContext";

function SearchTabs(props: any) {
  const { setCurrentTab, currentTab } = props;
  const location = useLocation();
  const inputRef = useRef(null);
  const [searchStr, setSearchStr] = useState<string>(
    getUrlParams("search") as string
  );
  const [userState, setUserState, showLogin, setShowLogin] = useSharedState();

  const [urlParams, setUrlParams] = useUrlState({
    tag: "",
    search: "",
  });

  const navigate = useNavigate();

  const items = [
    {
      key: "paper",
      label: `论文`,
      // children: <Paper searchStr={searchStr} />,
    },
    {
      key: "scholar",
      label: `学者`,
      // children: <Scholar searchStr={searchStr} />,
    },
    {
      key: "org",
      label: `机构`,
      // children: <Industry searchStr={searchStr} />,
    },
  ];

  const handleTrack = (key: any) => {
    let tag = getUrlParams("tag");
    if (tag === "paper") {
      if (key === "scholar") {
        tj.track({
          params: {
            // 上报参数
            event_id:
              "kgi_cells_and_genes_search_result_paper_switch_to_scholar_click",
          },
        });
      } else if (key === "org") {
        tj.track({
          params: {
            // 上报参数
            event_id:
              "kgi_cells_and_genes_search_result_paper_switch_to_institution_click",
          },
        });
      }
    } else if (tag === "scholar") {
      if (key === "paper") {
        tj.track({
          params: {
            // 上报参数
            event_id:
              "kgi_cells_and_genes_search_result_scholar_switch_to_paper_click",
          },
        });
      } else if (key === "org") {
        tj.track({
          params: {
            // 上报参数
            event_id:
              "kgi_cells_and_genes_search_result_scholar_switch_to_institution_click",
          },
        });
      }
    } else if (tag === "org") {
      if (key === "paper") {
        tj.track({
          params: {
            // 上报参数
            event_id:
              "kgi_cells_and_genes_search_result_institution_switch_to_paper_click",
          },
        });
      } else if (key === "scholar") {
        tj.track({
          params: {
            // 上报参数
            event_id:
              "kgi_cells_and_genes_search_result_institution_switch_to_scholar_click",
          },
        });
      }
    }
  };
  const handleInitTrack = (key: any) => {
    let tag = getUrlParams("tag");
    if (tag === "paper") {
      tj.track({
        params: {
          // 上报参数
          event_id: "kgi_cells_and_genes_search_result_paper_enter",
        },
      });
    } else if (tag === "scholar") {
      tj.track({
        params: {
          // 上报参数
          event_id: "kgi_cells_and_genes_search_result_scholar_enter",
        },
      });
    } else if (tag === "org") {
      tj.track({
        params: {
          // 上报参数
          event_id: "kgi_cells_and_genes_search_result_institution_enter",
        },
      });
    }
  };
  const onChange = (key: string) => {
    let str = (inputRef.current as any).input.value;
    if (str && str.trim().length) {
      handleTrack(key);

      setUrlParams({
        tag: key,
        search: str.trim(),
      });
    } else {
      message.error("请输入搜索关键词");
    }

    // navigate(
    //   `/SearchTabs?tag=${key}&search=${(inputRef.current as any).input.value}`
    // );
  };

  useEffect(() => {
    handleInitTrack("");
    console.log(getUrlParams("tag") as string);
  }, []);
  return (
    <div className={styles.searchTabs}>
      <Header inputRef={inputRef} />
      <Tabs
        defaultActiveKey={getUrlParams("tag") as string}
        activeKey={getUrlParams("tag") as string}
        destroyInactiveTabPane={true}
        items={items}
        onChange={onChange}
      />
      <div className={styles.tabCt}>
        <div
          className={
            (getUrlParams("tag") as string) === "paper" ? "" : styles.hide
          }
        >
          <Paper searchStr={searchStr} />
        </div>
        <div
          className={
            (getUrlParams("tag") as string) === "org" ? "" : styles.hide
          }
        >
          <Industry searchStr={searchStr} />
        </div>
        <div
          className={
            (getUrlParams("tag") as string) === "scholar" ? "" : styles.hide
          }
        >
          <Scholar searchStr={searchStr} />
        </div>
      </div>
    </div>
  );
}

export default SearchTabs;
