import react, { useState, useEffect } from "react";
import styles from "./index.less";
import { getTreeData } from "../../services/api";
import { Tree } from "antd";
import openSvg from "src/image/common/caret-right.svg";
import closeSvg from "src/image/common/caret-down.svg";
interface ILeftList {
  setCurrentClickData: any;
  leftCurrentClickData: any;
  data: any;
  stats: any;
  pageType: string;
}

interface ILeftListItem {
  name: string;
  name_zh: string;
  node_id: string;
}

interface ITopItem {
  key: string;
  name: string;
  countKey?: string;
  unit?: string;
}

const listMap: Array<ITopItem> = [
  {
    key: "overview",
    name: "领域",
  },
  {
    key: "industry",
    name: "机构",
    countKey: "org_count",
    unit: "所",
  },
  {
    key: "scholar",
    name: "学者",
    countKey: "scholar_count",

    unit: "位",
  },
  {
    key: "paper",
    name: "论文",
    countKey: "paper_count",

    unit: "篇",
  },
  {
    key: "patent",
    name: "专利",
    countKey: "patent_count",

    unit: "篇",
  },
];

function LeftList(props: ILeftList) {
  const { setCurrentClickData, leftCurrentClickData, data, stats, pageType } =
    props;
  const [currentKey, setCurrentKey] = useState<string>("1");
  const [status, setStatus] = useState<"expand" | "collapse">("expand");
  // useEffect(() => {
  //   setCurrentKey(leftCurrentClickData.id);
  // }, [JSON.stringify(leftCurrentClickData)]);
  const handleClick = (key: string, name: string, item: any) => {
    setCurrentKey(key);
    setCurrentClickData({ id: key, name, ...item });
  };

  const currentItem: any = listMap.find((v) => v.key === pageType) || {};
  return (
    <>
      {status === "expand" && (
        <div className={styles.leftList}>
          <div className={styles.topBar}>
            <span className={styles.topBarTitle}>相关{currentItem.name}</span>
            <span className={styles.topBarCount}>
              {stats[currentItem.countKey]}&nbsp;
              {currentItem.unit}
            </span>
          </div>
          <span
            className={`${styles.rootNode} ${
              currentKey === "1" ? styles.rootNodeActive : ""
            }`}
            onClick={() => {
              handleClick("1", "细胞与基因", {});
            }}
          >
            细胞与基因
          </span>
          {!!data.length && (
            <Tree
              className={styles.list}
              defaultExpandedKeys={["1"]}
              switcherIcon={(node: any) => {
                return node.expanded ? (
                  <span
                    className={`${styles.iconfont} icon-caret-down iconfont`}
                  ></span>
                ) : (
                  <span
                    className={`${styles.iconfont} icon-caret-right iconfont`}
                  ></span>
                );
              }}
              selectedKeys={[currentKey]}
              blockNode
              onSelect={(selectedKeys, node: any) => {
                let item = node.node;
                handleClick(item.node_id, item.name_zh, item);
              }}
              treeData={data[0].children}
            />
          )}
        </div>
      )}
      {/* 
      {status === "collapse" && (
        <div className={styles.leftListCollapse}>
          <span
            className={styles.openBtn}
            onClick={() => setStatus("expand")}
          ></span>

          <span className={styles.openTitle}>细胞与基因相关节点</span>
        </div>
      )} */}
    </>
  );
}

export default LeftList;
