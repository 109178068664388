import react, { useState, useRef, useEffect } from "react";
import styles from "./index.less";
import moment from "moment";
import LoadingGif from "src/image/loading.gif";
import defaultIndustry from "../../image/chatGraph/newsEmpty.svg";
import { Pagination, Select, message, ConfigProvider, Spin } from "antd";
import Empty from "src/image/chatGraph/newsListEmpty.svg";
import { getChatNews } from "../../services/api";
import _ from "lodash";
import tj from "@itic-sci/teamjob";

function NewsPart() {
  const [listData, setListData] = useState<Array<any>>([]);
  const listRef = useRef<HTMLDivElement>(null);
  const [isloading, setIsLoading] = useState(true);
  const currentPageRef = useRef(1);
  const listDataRef = useRef([]);
  const itemWidth = 352;
  const [currentIndex, setCurrentIndex] = useState(0);
  const [containerWidth, setContainerWidth] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const updateContainerWidth = () => {
      if (containerRef.current) {
        setContainerWidth(containerRef.current.offsetWidth);
      }
    };

    window.addEventListener("resize", updateContainerWidth);
    updateContainerWidth();

    return () => window.removeEventListener("resize", updateContainerWidth);
  }, []);
  // const [currentPage, setCurrentPage] = useState<number>(1);
  // const [currentIndex, setCurrentIndex] = useState(0);

  // const handlePrev = () => {
  //   setCurrentIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : 0));
  // };

  // const handleNext = () => {
  //   setCurrentIndex((prevIndex) =>
  //     prevIndex < listData.length - 3 ? prevIndex + 1 : prevIndex
  //   );
  // };

  // const translateX = currentIndex * -372; // 每次移动100px

  const canScrollLeft = currentIndex > 0;
  const canScrollRight =
    currentIndex < listData.length - Math.floor(containerWidth / itemWidth);

  const scroll = (direction: "left" | "right") => {
    if (direction === "left" && canScrollLeft) {
      setCurrentIndex(currentIndex - 1);
    } else if (direction === "right" && canScrollRight) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const getListData = async () => {
    setIsLoading(true);
    try {
      let res: any = await getChatNews({
        page: currentPageRef.current,
      });
      setIsLoading(false);

      if (res.code === 200) {
        let temp = res.data.data;
        setListData(temp);
        listDataRef.current = temp;
      }
    } catch (e: any) {
      // if (e.code === "ECONNABORTED") {
      //   message.error("接口超时，请刷新重试", 5);
      // } else {
      //   message.error("系统错误，请稍后重试");
      // }
      setIsLoading(false);
    }
  };
  // const handleScreenAuto = (e: any) => {
  //   let dom = listRef.current;
  //   if (dom) {
  //     //文档内容实际高度（包括超出视窗的溢出部分）
  //     let scrollHeight = Math.max(dom.scrollHeight, dom.scrollHeight);
  //     //滚动条滚动距离
  //     let scrollTop = e.target.scrollTop;
  //     //窗口可视范围高度
  //     let clientHeight = dom.clientHeight;

  //     if (clientHeight + scrollTop + 10 >= scrollHeight) {
  //       currentPageRef.current += 1;

  //       setCurrentPage(currentPageRef.current);
  //     }
  //   }
  // };

  // //React的生命周期 如果你是vue可以放到mountd或created中
  // useEffect(() => {
  //   if (listRef.current) {
  //     listRef.current.addEventListener(
  //       "scroll",
  //       _.debounce(handleScreenAuto, 200),
  //       true
  //     );
  //     return () => {
  //       if (listRef.current) {
  //         listRef.current.removeEventListener(
  //           "scroll",
  //           _.debounce(handleScreenAuto, 200),
  //           true
  //         );
  //       }
  //     };
  //   }
  // }, []);
  useEffect(() => {
    getListData();
  }, []);
  return (
    <div className={styles.info}>
      <div className={styles.infoTop}>
        {Array.isArray(listData) && listData.length > 0 && (
          <span className={styles.infoTitle}>产业资讯</span>
        )}

        {/* cells_and_genes */}
        {Array.isArray(listData) && listData.length > 0 && (
          <div
            className={styles.infoJumpBtn}
            onClick={() => {
              tj.track({
                params: {
                  // 上报参数
                  event_id: "kgi_cells_and_genes_home_industryinfo_more_click",
                },
              });
              window.open(
                "https://future_industry.itic-sci.com/news/?domainId=1",
                "_blank"
              );
            }}
          >
            <span>查看更多</span>
            <span className="icon-right iconfont"></span>
          </div>
        )}
      </div>
      <div className={styles.horizontalScroll}>
        <div
          onClick={() => scroll("left")}
          className={styles["arrow"]}
          style={{ opacity: !canScrollLeft ? 0 : 1 }}
        >
          <span className="icon-left iconfont"></span>
        </div>
        <div className={styles.scrollContainer}>
          <div ref={containerRef} className={styles.scrollItemsContainer}>
            <div
              className={styles["carousel-inner"]}
              style={{
                transform: `translateX(-${
                  currentIndex * itemWidth +
                  (currentIndex ? currentIndex * 32 : 0)
                }px)`,
              }}
            >
              {!!listData.length &&
                listData.map((item, index) => (
                  <div
                    key={index}
                    className={styles["carousel-item"]}
                    onClick={() => {
                      tj.track({
                        params: {
                          // 上报参数
                          event_id:
                            "kgi_cells_and_genes_home_industryinfo_click",
                        },
                      });
                      window.open(item.infoUrl, "_blank");
                    }}
                  >
                    <div className={styles.rightPart}>
                      <p
                        className={styles.listName + " text-two-ellipsis"}
                        title={item.infoName}
                      >
                        {item.infoName}
                      </p>
                      {item.infoContent && (
                        <p
                          className={styles.infoContent + " text-two-ellipsis"}
                          title={item.infoContent}
                        >
                          {item.infoContent}
                        </p>
                      )}

                      <p className={styles.infoBottom}>
                        {item.sourceSiteName && (
                          <span className={styles.infoBottomSource}>
                            {item.sourceSiteName}
                          </span>
                        )}
                        {item.createTime && (
                          <div className={styles.infoBottomTime}>
                            <span
                              className={`${styles.iconTime} iconfont icon-time`}
                            ></span>
                            <span>
                              {moment(item.createTime).format("YYYY-MM-DD")}
                            </span>
                          </div>
                        )}
                      </p>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
        <div
          onClick={() => scroll("right")}
          className={styles["arrow"]}
          style={{ opacity: !canScrollRight ? 0 : 1 }}
        >
          <span className="icon-right iconfont"></span>
        </div>
      </div>
    </div>
  );
}

export default NewsPart;
