import react, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
  useRef,
  useContext,
} from "react";
import _ from "lodash";
import styles from "./index.less";
import SearchPart from "../../component/SearchPart/index";
import LinkGraph from "src/component/LinkGraph/index";
import { getPaperList } from "../../services/api";
import { UserContext } from "src/Context";
import { Pagination, Select, Input, ConfigProvider, message } from "antd";
import InputClear from "../../image/inputClear.svg";
import AuthorList from "src/component/AuthorList/index";
import zhCN from "antd/lib/locale/zh_CN";
import Empty from "src/image/ListEmpty.svg";
import ExpandableText from "src/component/ExpandableTextScreen/index";

interface IListData {
  title: string;
  authors: any;
  abstractZh: string;
  abstract: string;
  fields: any;
  keywords: string;
  pub_year: string;
  cite_num: string | number;
  node_id: string;
  mini_fields: any;
}
interface IPaper {
  leftCurrentClickData: any;
  pageType: string;
}

const tagMap = [
  {
    key: "paper",
    label: "论文",
  },
];

function Paper(props: IPaper, ref: any) {
  const listRef = useRef<HTMLDivElement>(null);
  const currentPageRef = useRef(1);
  const { isloading, setIsLoading } = useContext(UserContext);
  const inputRef = useRef(null);
  const listDataRef = useRef([]);
  const [searchStr, setSearchStr] = useState<string>("");
  const { leftCurrentClickData, pageType } = props;
  const [sortType, setSortType] = useState<"link" | "time">("link");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [total, setTotal] = useState<number>(1);
  const [listData, setListData] = useState<Array<IListData>>([]);
  const [checkBoxState, setCheckBoxState] = useState(false);
  useImperativeHandle(ref, () => ({}));
  const getListData = async (params: any) => {
    setIsLoading(true);
    let res: any = {};
    try {
      res = await getPaperList({
        node_name: params.name_zh,
        node_layer: params.layer,
        size: pageSize,
        is_sz: checkBoxState ? 1 : 0,
        sort_field: sortType === "link" ? "cite_num" : "pub_year",
        page: currentPage,
        keyword: searchStr.trim(),
      });
    } catch (error) {
      message.error("接口网络状态异常，请您稍后再试！");
    }

    setIsLoading(false);

    if (res.code === 0) {
      let temp = res.data.papers;

      setListData(temp);
      setTotal(res.data.total);
      listDataRef.current = temp;
    }
  };

  const handleChange = (page: number, pageSize: number) => {
    setPageSize(pageSize);
    setCurrentPage(page);
  };

  useEffect(() => {
    if (listRef.current) {
      listRef.current.scrollTop = 0;
    }
    getListData(leftCurrentClickData);
  }, [currentPage, pageSize]);

  useEffect(() => {
    if (currentPage !== 1) {
      setCurrentPage(1);
    } else {
      if (listRef.current) {
        listRef.current.scrollTop = 0;
      }
      getListData(leftCurrentClickData);
    }
  }, [
    JSON.stringify(leftCurrentClickData),
    checkBoxState,
    sortType,
    searchStr,
  ]);

  return (
    <>
      {/* <SearchPart
        searchTag={tagMap}
        searchStr={searchStr}
        setSearchStr={setSearchStr}
      /> */}
      <div className={styles.paper}>
        <div className={styles.topBar}>
          <span className={styles.title}>细胞与基因</span>

          <div className={styles.sortCt}>
            <div className={styles.checkBox}>
              <span
                onClick={() => setCheckBoxState(!checkBoxState)}
                className={`${""}  ${
                  checkBoxState ? styles.checkBoxImgActive : styles.checkBoxImg
                }`}
              ></span>
              <span className={styles.checkBoxText}>只看深圳</span>
            </div>
            <Select
              defaultValue="link"
              className={styles.selectBox}
              onChange={(value: any) => {
                setSortType(value);
              }}
              suffixIcon={
                <span
                  className={`iconfont icon-down ${styles.iconfont}`}
                ></span>
              }
              options={[
                { value: "time", label: "时间" },

                { value: "link", label: "引用量" },
              ]}
            />
            <Input
              className={styles.searchBox}
              ref={inputRef}
              placeholder="请输入要搜索的内容"
              allowClear={{
                clearIcon: (
                  <img
                    src={InputClear}
                    alt=""
                    className={styles.closeImg}
                    onClick={() => {
                      setSearchStr("");
                    }}
                  />
                ),
              }}
              onPressEnter={() => {
                if (inputRef.current) {
                  setSearchStr((inputRef.current as any).input.value);
                }
              }}
              suffix={
                <span
                  className={`iconfont icon-sousuo ${styles.iconfont}`}
                  onClick={() => {
                    if (inputRef.current) {
                      setSearchStr((inputRef.current as any).input.value);
                    }
                  }}
                ></span>
              }
            />
          </div>
        </div>
        {Array.isArray(listData) && !!listData.length && (
          <>
            <div className={styles.listCt} ref={listRef}>
              {listData.map((item: any, index) => (
                <div key={item.node_id} className={styles.listItem}>
                  <div className={styles.topInfo}>
                    <p
                      className={styles.topTitle + " text-two-ellipsis"}
                      onClick={() =>
                        window.open(
                          `/graph_detail?id=${item.aminer_id}&node_type=${item.node_type}`
                        )
                      }
                    >
                      {item.title}
                    </p>
                    {/* <LinkGraph /> */}
                  </div>
                  {Array.isArray(item.authors) && !!item.authors.length && (
                    <AuthorList authors={item.authors} theme="black" />
                  )}

                  {!!(item.abstractZh || item.abstract) && (
                    <ExpandableText
                      text={item.abstractZh || item.abstract}
                      key={item.node_id}
                      index={index}
                      pageType={pageType}
                    />
                  )}

                  <div className={styles.bottomInfo}>
                    <div className={styles.tagList}>
                      {Array.isArray(item.mini_fields) &&
                        item.mini_fields.slice(0, 5).map((item: any) => (
                          <span className={styles.tag} key={item}>
                            {item || "-"}
                          </span>
                        ))}
                    </div>
                    <div className={styles.bottomCount}>
                      <div className={styles.bottomDetail}>
                        <span className={styles.label}>引用量：</span>
                        <span className={styles.count}>
                          {item.cite_num || 0}
                        </span>
                      </div>
                      <div className={styles.bottomDetail}>
                        <span className={styles.label}>发表日期：</span>
                        <span className={styles.count}>
                          {item.pub_year || "--"}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className={styles.footerBar}>
              <ConfigProvider locale={zhCN}>
                <Pagination
                  total={total}
                  showSizeChanger
                  pageSize={pageSize}
                  current={currentPage}
                  onChange={handleChange}
                  defaultCurrent={1}
                  className={styles.pagination}
                  showQuickJumper
                />
              </ConfigProvider>
              <div className={styles.totalBar}>共 {total} 个结果 </div>
            </div>
          </>
        )}
        {Array.isArray(listData) && listData.length === 0 && (
          <div className={styles.emptyPart}>
            <img src={Empty} alt="" />
            <p>找不到相关内容</p>
          </div>
        )}
      </div>
    </>
  );
}

export default forwardRef(Paper);
