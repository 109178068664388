import react, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
  useRef,
  useContext,
} from "react";
import _ from "lodash";
import styles from "./index.less";
import DefaultIndustry from "src/image/chatGraph/defaultListOrg.png";
import Empty from "src/image/chatGraph/empty.svg";
import LoadingGif from "src/image/loading.gif";
import { getIndustryList } from "src/services/api";
import { UserContext } from "src/Context";
import { getUrlParams } from "src/ChatPages/Graph/util.js";

import { Pagination, message, Spin, ConfigProvider } from "antd";
import zhCN from "antd/lib/locale/zh_CN";
import InputClear from "../../image/inputClear.svg";
import { useNavigate, Link, useLocation } from "react-router-dom";
import tj from "@itic-sci/teamjob";

export interface IListData {
  org_nameZh: string;
  org_name: string;
  establishdate: string;
  location: string;
  mini_fields: any;
  n_citation: string;
  image: string;
  node_id: string;
  fields: any;
  n_papers: string;
}
interface IIndustry {
  searchStr: any;
}
const sortTag = [
  {
    key: "weight",
    label: "综合排序",
    track:
      "kgi_cells_and_genes_search_result_institution_sort_comprehensive_click",
  },
  {
    key: "n_citation",
    label: "引用量",
    track: "kgi_cells_and_genes_search_result_institution_sort_cites_click",
  },
  {
    key: "n_pubs",
    label: "论文数",
    track: "kgi_cells_and_genes_search_result_institution_sort_papers_click",
  },
];
function Industry(props: IIndustry, ref: any) {
  const listRef = useRef<HTMLDivElement>(null);
  const currentPageRef = useRef(1);
  const [currentTag, setCurrentTag] = useState<string>("weight");
  const [isloading, setIsLoading] = useState(false);
  const listDataRef = useRef([]);
  const inputRef = useRef(null);
  const { searchStr } = props;

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const navigate = useNavigate();

  const [total, setTotal] = useState<number>(0);
  const [listData, setListData] = useState<Array<IListData>>([]);
  const [checkBoxState, setCheckBoxState] = useState(false);
  useImperativeHandle(ref, () => ({}));
  const getListData = async () => {
    try {
      setIsLoading(true);

      let res: any = await getIndustryList({
        node_name: "细胞与基因",
        node_layer: 0,
        size: pageSize,
        page: currentPage,
        sort_field: currentTag,
        keyword: searchStr,
      });
      setIsLoading(false);

      if (res.code === 0) {
        let temp = res.data.orgs;
        window.scrollTo(0, 0);

        setListData(temp);
        setTotal(res.data.total);
        listDataRef.current = temp;
      } else {
        message.error("系统错误，请稍后重试");
      }
    } catch (e: any) {
      console.log(e);
      if (e.code === "ECONNABORTED") {
        message.error("接口超时，请刷新重试", 5);
      } else {
        message.error("系统错误，请稍后重试");
      }
      setIsLoading(false);
    }
  };

  const handleChange = (page: number, pageSize: number) => {
    tj.track({
      params: {
        // 上报参数
        event_id:
          "kgi_cells_and_genes_search_result_institution_pageflipper_click",
      },
    });
    setPageSize(pageSize);
    setCurrentPage(page);
  };

  useEffect(() => {
    if (listRef.current) {
      listRef.current.scrollTop = 0;
    }
    if (getUrlParams("tag") === "org") {
      getListData();
    }
  }, [currentPage, pageSize, searchStr, currentTag]);

  useEffect(() => {
    setCurrentPage(1);
  }, [searchStr]);

  return (
    <>
      <div className={styles.scholar}>
        <Spin
          spinning={isloading}
          indicator={
            <img
              src={LoadingGif}
              style={{
                width: 100,
                height: 100,
                display: "inline-block",
                marginTop: 100,
                transform: "translateX(-250px)",
              }}
            />
          }
        >
          <div className={styles.listCt} ref={listRef}>
            {Array.isArray(listData) && !!listData.length && (
              <>
                <div className={styles.totalBar}>
                  共找到 {total} 个相关机构实体
                  <div className={styles.sortCt}>
                    <span className={styles.sortLabel}>
                      <span className="iconfont icon-paixu"></span>
                      <span> 排序方式：</span>
                    </span>
                    <div className={styles.sortItem}>
                      {sortTag.map((item, index) => (
                        <span
                          key={index}
                          onClick={() => {
                            tj.track({
                              params: {
                                // 上报参数
                                event_id: item.track,
                              },
                            });
                            setCurrentPage(1);
                            setCurrentTag(item.key);
                          }}
                          className={
                            currentTag === item.key ? styles.actived : ""
                          }
                        >
                          {item.label}
                        </span>
                      ))}
                    </div>
                  </div>
                </div>
                <div>
                  {listData.map((item: any, index) => (
                    <div key={index} className={styles.listItem}>
                      <img
                        src={item.image || DefaultIndustry}
                        alt=""
                        onClick={() => {
                          tj.track({
                            params: {
                              // 上报参数
                              event_id:
                                "kgi_cells_and_genes_search_result_institution_result_click",
                            },
                          });
                          window.open(
                            `/graph_detail?id=${item.node_id}&node_type=${item.node_type}`
                          );
                        }}
                        className={styles.leftIcon}
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null; // prevents looping
                          currentTarget.src = DefaultIndustry;
                        }}
                      />
                      <div className={styles.rightPart}>
                        <div className={styles.topInfo}>
                          <p
                            className={styles.topTitle + " text-two-ellipsis "}
                            title={item.org_nameZh || item.org_name}
                            onClick={() => {
                              tj.track({
                                params: {
                                  // 上报参数
                                  event_id:
                                    "kgi_cells_and_genes_search_result_institution_result_click",
                                },
                              });
                              window.open(
                                `/graph_detail?id=${item.node_id}&node_type=${item.node_type}`
                              );
                            }}
                          >
                            {item.org_nameZh || item.org_name || "--"}
                          </p>
                          {item.org_nameZh && item.org_name && (
                            <span className={styles.region}>
                              （{item.org_name}）
                            </span>
                          )}

                          {/* <LinkGraph /> */}
                        </div>

                        <div className={styles.bottomInfo}>
                          <div className={styles.bottomCount}>
                            <div className={styles.bottomDetail}>
                              <span className={styles.label}>引用量：</span>
                              <span className={styles.count}>
                                {item.n_citation || "0"}
                              </span>
                            </div>
                            <div className={styles.bottomDetail}>
                              <span className={styles.label}>论文数：</span>
                              <span className={styles.count}>
                                {" "}
                                {item.n_papers === 10000
                                  ? "10000+"
                                  : item.n_papers || "0"}
                              </span>
                            </div>
                            <div className={styles.bottomDetail}>
                              <span className={styles.label}>相关实体：</span>
                              <span className={styles.count}>
                                {item.sub_graph_total || "--"}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className={styles.tagList}>
                          {Array.isArray(item.mini_fields) &&
                            item.mini_fields.slice(0, 5).map((item: any) => (
                              <span className={styles.tag} key={item}>
                                {item || "--"}
                              </span>
                            ))}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </>
            )}
          </div>
          {!isloading && Array.isArray(listData) && !listData.length && (
            <div className={styles.emptyPart}>
              <img src={Empty} alt="" />
              <p>找不到相关内容</p>
            </div>
          )}
          {!!total && (
            <div className={styles.footerBar}>
              <ConfigProvider locale={zhCN}>
                <Pagination
                  total={total}
                  current={currentPage}
                  showSizeChanger={false}
                  onChange={handleChange}
                  defaultCurrent={1}
                  className={styles.pagination}
                />
              </ConfigProvider>
            </div>
          )}
        </Spin>
      </div>
    </>
  );
}

export default forwardRef(Industry);
