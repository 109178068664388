import * as echarts from "echarts";
import react, { useState, useRef, useEffect, useLayoutEffect } from "react";
import styles from "./index.less";
import { getPersonInterest } from "src/services/api";
import _ from "lodash";
import { Spin, message } from "antd";
import Empty from "src/image/chatGraph/empty.svg";
function unique2DArray(arr) {
  const stringifiedArray = arr.map((innerArr) => JSON.stringify(innerArr));
  const uniqueStringifiedArray = [...new Set(stringifiedArray)];
  return uniqueStringifiedArray.map((str) => JSON.parse(str));
}

const Chart = (props) => {
  let { id, setLoading } = props;
  const el = useRef(null);
  const [chartData, setChartData] = useState([]);
  const chartRef = useRef(null);
  const formatData = (data) => {
    let legend = [];
    let res = [];
    try {
      res = data.map((item) => {
        Array.isArray(item.year) &&
          item.year.forEach((v) => {
            v.push(item.t);
            legend.push(item.t);
            v[0] = v[0].toString();
          });
        return item.year || [];
      });
      let sortData = res.flat(1).sort((a, b) => a[0] - b[0]);
      let insertBeforeItem = legend.map((item) => [
        sortData[0][0] - 2 + "",
        0,
        item,
      ]);
      let insertAfterItem = legend.map((item) => [
        parseInt(sortData[sortData.length - 1][0]) + 2 + "",
        0,
        item,
      ]);
      let arrNew = [...insertBeforeItem, ...sortData, ...insertAfterItem];

      return unique2DArray(arrNew);
    } catch (error) {
      console.log(error);
    }
  };
  const init = () => {
    var chartDom = el.current;
    chartRef.current = echarts.init(chartDom);
    var option;

    option = {
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "line",
          lineStyle: {
            color: "rgba(131, 130, 140, 0.15)",
            width: 1,
            type: "solid",
          },
        },
      },
      grid: {
        // 图表容器的左边距
        left: 30,
        // 图表容器的右边距
        right: 30,
        // 图表容器的下边距
        bottom: 20,
      },
      legend: {
        left: 0,
        data: chartData.map((item) => item.t),
        formatter: function (name) {
          // 截取前 4 个字符
          var shortName = name.substr(0, 6);
          // 如果文本过长，用...表示
          if (name.length > 6) {
            return shortName + "...";
          } else {
            return shortName;
          }
        },
        tooltip: {
          show: true,
        },
        icon: "circle",
        itemWidth: 12, // 设置宽度
        orient: "horizontal",
        itemHeight: 10, // 设置高度
        align: "left",
        itemGap: 8, // 设置间距
        textStyle: {
          color: "#B5B5B5",
          fontSize: 12,
          fontWeight: "normal",
          fontStyle: "normal",
          fontFamily: "Arial",
        },
      },

      singleAxis: {
        bottom: 20,
        axisTick: {
          show: false,
        },
        axisLabel: {},
        type: "time",
        axisLine: {
          show: false,
        },
        axisPointer: {
          animation: true,
          label: {
            show: true,
            color: "#b5b5b5",
          },
        },
        splitLine: {
          show: false,
          lineStyle: {
            type: "dashed",
            opacity: 0.2,
          },
        },
      },
      series: [
        {
          type: "themeRiver",
          label: {
            show: false,
          },
          boundaryGap: ["20%", "0%"],
          color: ["#FF5C00", "#FFC806", "#00FFA3", "#00C2FF"],
          emphasis: {
            itemStyle: {
              shadowBlur: 20,
              shadowColor: "#B5B5B5",
            },
          },
          itemStyle: {
            emphasis: {
              shadowBlur: 0, // 去掉 hover 时的阴影
              shadowColor: "rgba(0, 0, 0, 0)", // 设置阴影颜色为透明
            },
          },
          data: formatData(chartData),
        },
      ],
    };

    option && chartRef.current.setOption(option);
  };

  const handleScreenAuto = () => {
    if (chartRef.current) {
      chartRef.current.resize();
    }
  };

  useEffect(() => {
    handleScreenAuto();
    // 监听
    window.addEventListener("resize", _.debounce(handleScreenAuto, 600));
    // 销毁
    return () =>
      window.removeEventListener("resize", _.debounce(handleScreenAuto, 600));
  });

  const getData = async () => {
    setLoading(true);
    let nameMap = {};
    let temp = [];
    try {
      let res = await getPersonInterest([
        {
          action: "PersonInterest.Get",
          parameters: { id, is_year: true },
        },
      ]);
      // let res = mockData;
      if (res.data[0].data && res.data[0].data.data) {
        let rawData = res.data[0].data.data;
        for (let i = 0; i < rawData.length; i++) {
          if (!nameMap[rawData[i].t]) {
            temp.push(rawData[i]);
            nameMap[rawData[i].t] = true;
          }
        }
        setChartData(temp.slice(0, 4));
      }
    } catch (error) {
      if (error.code === "ECONNABORTED") {
        message.error("接口超时，请刷新重试", 5);
      } else {
        message.error("请刷新重试");
      }
    }

    setLoading(false);
  };

  useEffect(() => {
    if (chartData.length) {
      init();
    }
  }, [chartData]);
  useLayoutEffect(() => {
    getData();
  }, [id]);
  return (
    <>
      {chartData.length ? (
        <div ref={el} className={styles.chart}></div>
      ) : (
        <div className={styles.emptyPart}>
          <img src={Empty} alt="" />
          <p>暂无数据</p>
        </div>
      )}
    </>
  );
};
export default Chart;
