import React, { useEffect } from "react";
import styles from "./index.less";
import { useNavigate, Link, useLocation } from "react-router-dom";

function IntermediateInPage() {
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const pathValue = queryParams.get("path");
  console.log(pathValue);
  useEffect(() => {
    const timer = setTimeout(() => {
      navigate(`/${pathValue}`, { replace: true });
    }, 638); // 2 seconds delay

    return () => clearTimeout(timer);
  }, []);

  return <div className={styles.intermediate}></div>;
}

export default IntermediateInPage;
