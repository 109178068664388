import react, { useState, useMemo, useEffect } from "react";
import styles from "./index.less";
import { getAiNodeDetail, getPaperData } from "src/services/api";
import { Spin, message, Popover } from "antd";
import { useSharedState } from "src/UserContext";
import pdfIcon from "src/image/chatGraph/pdfIcon.svg";
import ExpandableText from "src/component/ExpandableMutiText/index";
import AuthorList from "src/component/AuthorList/index";
import _ from "lodash";
import tj from "@itic-sci/teamjob";

interface IPaperWin {
  currentClickData?: any;
}

const version_map: any = {
  mag: "https://academic.microsoft.com/paper/:id",
  dblp: "https://dblp.uni-trier.de/rec/:id.html",
  arxiv: "https://arxiv.org/abs/:id",
  sciencedirect: "https://www.sciencedirect.com/science/article/pii/:id",
  ascelibrary: "https://ascelibrary.org/doi/:id",
  msp: "https://msp.org/:id.xhtml",
  pubmed: "https://pubmed.ncbi.nlm.nih.gov/:id",
  springer: "https://link.springer.com/:id",
  acm: [
    {
      pattern: /\d+\.\d+\/.*/,
      url: "https://dl.acm.org/doi/abs/:id",
    },
    {
      pattern: /\d+/,
      url: "https://dl.acm.org/citation.cfm?id=:id",
    },
  ],
};
function PaperWin(props: IPaperWin) {
  const { currentClickData } = props;
  const [loading, setLoading] = useState(false);
  const [expandTags, setExpandTags] = useState(false);
  const [showTranslate, setShowTranslate] = useState(false);
  const [rawData, setRawData] = useState<any>({});
  // const [pubRawData, setPubRawData] = useState<any>({});
  const [userState, setUserState, showLogin, setShowLogin] = useSharedState();

  const queryPersonDetail = async (params: any) => {
    setLoading(true);

    try {
      let res: any = await getAiNodeDetail({
        entity_id: params.node_id,
        entity_type: "paper",
      });
      // let pubRawDataRes = await getPaperData({
      //   id: params.node_id,
      // });
      // if (pubRawDataRes.data && pubRawDataRes.data.length) {
      //   setPubRawData(pubRawDataRes?.data[0]?.pub || {});
      // }
      console.log(res);
      // let res = mockData;
      if (res.code === 0) {
        let temp = res.data;
        setRawData(temp);
      }
    } catch (error: any) {
      if (error.code === "ECONNABORTED") {
        message.error("接口超时，请刷新重试", 5);
      } else {
        message.error("请刷新重试");
      }
    }

    setLoading(false);
  };
  const links = useMemo(() => {
    const reg = new RegExp("https://(.*?)(?=/)");
    const src_arr: any = [];
    if (!Array.isArray(rawData.versions)) {
      return [];
    }
    let arr = rawData.versions
      .map((item: any) => {
        const { src, sid } = item;
        const url: any = version_map[src];

        let link: any = "";
        if (src_arr.includes(src)) {
          link = "";
        } else if (typeof url === "string") {
          link = url.replace(":id", sid);
        } else if (Array.isArray(url)) {
          // url.forEach(t => {

          // })
          for (let i = 0; i < url.length; i += 1) {
            const { pattern, url: u } = url[i];
            if (pattern.test(sid)) {
              link = u.replace(":id", sid);
              break;
            }
          }
        }
        src_arr.push(src);
        return {
          label: link.match(reg) && link.match(reg)[1],
          url: link,
        };
      })
      .filter((item: any) => item && item.label && item.url);
    if (arr.length && rawData.doi) {
      arr.unshift({
        label: `https://doi.org/${rawData?.doi}`,
        url: `https://doi.org/${rawData?.doi}`,
      });
    }
    return arr;
  }, [rawData.versions]);

  useEffect(() => {
    queryPersonDetail(currentClickData);
  }, [JSON.stringify(currentClickData)]);

  return (
    <div className={styles.PaperWin}>
      <Spin spinning={loading}>
        <div className={styles.topInfo}>
          <p
            className={styles.topTitle}
            onClick={() => {
              tj.track({
                params: {
                  // 上报参数
                  event_id: "kgi_cells_and_genes_detail_paper_info_title_click",
                },
              });

              currentClickData.url && window.open(currentClickData.url);
            }}
          >
            {currentClickData.name_zh || currentClickData.name}
          </p>
          {/* <LinkGraph /> */}
        </div>
        {Array.isArray(currentClickData.mini_fields) &&
          currentClickData.mini_fields.length && (
            <p className={styles.tags}>
              {currentClickData.mini_fields
                .slice(0, !expandTags ? 6 : 9999)
                .map((item: any, index: any) => (
                  <span className={styles.tagsItem} key={index}>
                    {item}
                  </span>
                ))}
              {!expandTags && currentClickData.mini_fields.length > 6 && (
                <span
                  className={styles.downBtn + " iconfont icon-xiangxia"}
                  onClick={() => setExpandTags(true)}
                ></span>
              )}
            </p>
          )}

        {Array.isArray(rawData.authors) &&
          rawData.authors.length &&
          Array.isArray(rawData.authors) && (
            <AuthorList
              authors={rawData.authors}
              theme="white"
              btnTheme="active"
              leftCount={1}
              rightCount={4}
            />
          )}

        <div>
          <div className={styles.countItem}>
            <span className={styles.countLabel}>引用量：</span>
            <span
              className={`${styles.countNum} ${
                rawData.n_citation && styles.countNumActive
              }`}
            >
              {rawData.n_citation || "0"}
            </span>
          </div>
          <div className={styles.countItem}>
            <span className={styles.countLabel}>出版时间：</span>
            <span
              className={`${styles.countNum} ${
                rawData.year && styles.countNumActive
              }`}
            >
              {rawData.year || "--"}
            </span>
          </div>
        </div>
        <div>
          <div className={styles.countItem}>
            <span className={styles.countLabel}>期刊：</span>
            <span
              className={`${styles.countNum} ${
                rawData.year && styles.countNumActive
              }`}
            >
              {rawData?.venue || "--"}
            </span>
          </div>
        </div>

        <div className={styles.linkPart}>
          {rawData.pdf && (
            <div className={styles.linkItem}>
              <img src={pdfIcon} alt="" />
              <span
                className={styles.linkName}
                onClick={() => {
                  if (userState.account) {
                    tj.track({
                      params: {
                        // 上报参数
                        event_id:
                          "kgi_cells_and_genes_detail_paper_info_pdf_click",
                      },
                    });
                    window.open(rawData.pdf, "_blank");
                  } else {
                    setShowLogin(true);
                  }
                }}
              >
                查看PDF
              </span>
            </div>
          )}
          {links && !!links.length && (
            <Popover
              placement="bottomLeft"
              overlayClassName="linksPopover"
              arrow={false}
              content={
                <div>
                  {links.map((item: any) => (
                    <p
                      key={item.url}
                      className="linkUrl text-ellipsis"
                      onClick={() => {
                        tj.track({
                          params: {
                            // 上报参数
                            event_id:
                              "kgi_cells_and_genes_detail_paper_info_link_click",
                          },
                        });
                        window.open(item.url, "_blank");
                      }}
                    >
                      {item.label}
                    </p>
                  ))}
                </div>
              }
              trigger="click"
            >
              <div className={styles.linkItem}>
                <span className={`${styles.icon} icon-lianjie iconfont`}></span>
                <span className={styles.linkName}>原文链接</span>
                <span
                  className={`${styles.xiangxia} icon-xiangxia iconfont`}
                ></span>
              </div>
            </Popover>
          )}
        </div>

        {!!rawData.abstract && (
          <ExpandableText text={rawData.abstract || "-"} />
          // <p className={styles.abstract}>
          //   {pubRawData.abstract_zh || pubRawData.abstract || "-"}
          // </p>
        )}

        {!showTranslate && rawData.abstract_translated && (
          <span
            className={styles.translateBtn}
            onClick={() => {
              if (userState.account) {
                tj.track({
                  params: {
                    // 上报参数
                    event_id:
                      "kgi_cells_and_genes_detail_paper_info_translation_click",
                  },
                });
                setShowTranslate(true);
              } else {
                setShowLogin(true);
              }
            }}
          >
            查看译文
          </span>
        )}
        {showTranslate && (
          <>
            <p className={styles.translateText}>
              {rawData.abstract_translated}
            </p>
            <span
              className={styles.translateBtn}
              onClick={() => {
                setShowTranslate(false);
              }}
            >
              收起译文
            </span>
          </>
        )}
      </Spin>
    </div>
  );
}

export default PaperWin;
