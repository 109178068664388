import React from "react";
import styled from "styled-components";

const StyledFrame = styled.div`
  background-image: url(/assets/account/1-4.png);
  background-size: 100% 100%;
  height: 142px;
  left: 341px;
  position: absolute;
  top: 72px;
  width: 919px;

  & .text-wrapper {
    color: #263b5f;
    font-family: "Alibaba PuHuiTi 2.0-55Regular", Helvetica;
    font-size: 24px;
    font-weight: 400;
    left: 40px;
    letter-spacing: 0;
    line-height: 26px;
    position: absolute;
    top: 53px;
    width: 98px;
  }

  & .text-wrapper-2 {
    color: #263b5f;
    font-family: "Alibaba PuHuiTi 2.0-55Regular", Helvetica;
    font-size: 16px;
    font-weight: 400;
    left: 40px;
    letter-spacing: 0;
    line-height: 26px;
    position: absolute;
    top: 87px;
    white-space: nowrap;
  }
`;

export const Frame = () => {
  return (
    <StyledFrame>
      <div className="text-wrapper">使用手册</div>
      <div className="text-wrapper-2">
        助您轻松玩转专题知识库。如您在使用时遇到任何问题，欢迎随时与我们联系。
      </div>
    </StyledFrame>
  );
};
