import React, { useState } from "react";
import styles from "./index.less";

const Legend = () => {
  const option = [
    {
      name: "领域",
      color: "#5ED05C",
      type: "circle",
    },
    {
      name: "论文",
      color: "#F7BA1E",
      type: "circle",
    },
    {
      name: "学者",
      color: "#3F89F8",
      type: "circle",
    },
    {
      name: "机构",
      color: "#FF6565",
      type: "circle",
    },
  ];
  return (
    <div className={`${styles.legend} `}>
      {option.map((item) => (
        <div key={item.name} className={styles.name}>
          <span
            style={{ backgroundColor: item.color }}
            className={`iconfont ${
              item.type === "circle" ? styles["circle-legend"] : "icon-caidan"
            } ${styles.btnIcon}`}
          ></span>
          <span className={styles.text}>{item.name}</span>
        </div>
      ))}
    </div>
  );
};

export default Legend;
