import React from "react";

const Screen = () => {
  return (
    <div
      className="bg-[#ebf4ff] flex flex-row justify-center w-full"
      id="function"
    >
      <div className="bg-[#ebf4ff] w-[1400px] h-[1392px] relative">
        <div
          id="search"
          className="absolute w-[920px] h-[593px] top-[172px] left-[340px] bg-white rounded-[8px]"
        >
          <div className="w-[832px] top-[90px] left-[68px] [font-family:'PingFang_SC-Regular',Helvetica] font-normal text-[#333333] text-[14px] leading-[19.6px] absolute tracking-[0]">
            支持对细胞与基因领域内的论文、学者、机构、专利和领域进行提问检索：
          </div>
          <p className="w-[808px] top-[173px] left-[92px] [font-family:'PingFang_SC-Regular',Helvetica] font-normal text-transparent text-[14px] leading-[19.6px] absolute tracking-[0]">
            <span className="text-[#333333]">可通过</span>
            <span className="text-[#316efc]">领域、机构、学者</span>
            <span className="text-[#333333]">查询，</span>
            <span className="text-[#316efc]">年份</span>
            <span className="text-[#333333]">
              描述（大于、等于、最高、最低）、
            </span>
            <span className="text-[#316efc]">引用量</span>
            <span className="text-[#333333]">（大于、最高）</span>
          </p>
          <p className="w-[808px] top-[253px] left-[92px] [font-family:'PingFang_SC-Regular',Helvetica] font-normal text-transparent text-[14px] leading-[19.6px] absolute tracking-[0]">
            <span className="text-[#316efc]">领域、机构、论文发表量</span>
            <span className="text-[#333333]">（大于、等于、最高、最低）、</span>
            <span className="text-[#316efc]">论文引用量</span>
            <span className="text-[#333333]">
              （大于、等于、最高、最低）、H指数)、（大于、等于）、
            </span>
            <span className="text-[#316efc]">年份论文量、年份引用量</span>
          </p>
          <p className="w-[808px] top-[353px] left-[92px] [font-family:'PingFang_SC-Regular',Helvetica] font-normal text-transparent text-[14px] leading-[19.6px] absolute tracking-[0]">
            <span className="text-[#316efc]">领域、论文量</span>
            <span className="text-[#263b5f]">（大于、等于、最高、最低）、</span>
            <span className="text-[#316efc]">论文引用量</span>
            <span className="text-[#263b5f]">（大于、等于、最高、最低）、</span>
            <span className="text-[#316efc]">年份论文量、年份引用量</span>
          </p>
          <div className="w-[808px] top-[433px] left-[92px] [font-family:'PingFang_SC-Regular',Helvetica] font-normal text-[#316efc] text-[14px] leading-[19.6px] absolute tracking-[0]">
            领域、年份、机构
          </div>
          <p className="w-[808px] top-[513px] left-[92px] [font-family:'PingFang_SC-Regular',Helvetica] font-normal text-transparent text-[14px] leading-[19.6px] absolute tracking-[0]">
            <span className="text-[#263b5f]">涵盖细胞与基因下7个</span>
            <span className="text-[#316efc]">二级领域</span>
            <span className="text-[#263b5f]">
              （细胞转导、基因编辑、生物育种、基因检测、递送载体、基因合成、靶点发现验证），和33个
            </span>
            <span className="text-[#316efc]">三级领</span>
            <span className="text-[#263b5f]">域... ...</span>
          </p>
          <div className="top-[145px] left-[92px] [font-family:'PingFang_SC-Medium',Helvetica] font-medium text-[#263b5f] text-[16px] leading-[19.6px] whitespace-nowrap absolute tracking-[0]">
            查论文
          </div>
          <div className="top-[225px] left-[92px] [font-family:'PingFang_SC-Medium',Helvetica] font-medium text-[#263b5f] text-[16px] leading-[19.6px] whitespace-nowrap absolute tracking-[0]">
            查学者
          </div>
          <div className="top-[325px] left-[92px] [font-family:'PingFang_SC-Medium',Helvetica] font-medium text-[#263b5f] text-[16px] leading-[19.6px] whitespace-nowrap absolute tracking-[0]">
            查机构
          </div>
          <div className="top-[405px] left-[92px] [font-family:'PingFang_SC-Medium',Helvetica] font-medium text-[#263b5f] text-[16px] leading-[19.6px] whitespace-nowrap absolute tracking-[0]">
            查专利
          </div>
          <div className="top-[485px] left-[92px] [font-family:'PingFang_SC-Medium',Helvetica] font-medium text-[#263b5f] text-[16px] leading-[19.6px] whitespace-nowrap absolute tracking-[0]">
            查领域
          </div>
          <div className="absolute w-[127px] h-[30px] top-[40px] left-[20px]">
            <div className="top-px left-[47px] [font-family:'PingFang_SC-Regular',Helvetica] font-normal text-[#316efc] text-[20px] leading-[28px] whitespace-nowrap absolute tracking-[0]">
              检索附录
            </div>
            <img
              className="absolute w-[41px] h-[30px] top-0 left-0"
              alt="Element"
              src="/assets/function/5-1.svg"
            />
          </div>
          <div className="top-[154px] absolute w-[16px] h-[2px] left-[68px] bg-[#263b5f]" />
          <div className="top-[234px] absolute w-[16px] h-[2px] left-[68px] bg-[#263b5f]" />
          <div className="top-[334px] absolute w-[16px] h-[2px] left-[68px] bg-[#263b5f]" />
          <div className="top-[414px] absolute w-[16px] h-[2px] left-[68px] bg-[#263b5f]" />
          <div className="top-[494px] absolute w-[16px] h-[2px] left-[68px] bg-[#263b5f]" />
        </div>
        <div
          id="field"
          className="absolute w-[920px] h-[582px] top-[785px] left-[340px] bg-white rounded-[8px]"
        >
          <div className="absolute w-[127px] h-[30px] top-[40px] left-[20px]">
            <div className="top-px left-[47px] [font-family:'PingFang_SC-Regular',Helvetica] font-normal text-[#316efc] text-[20px] leading-[28px] whitespace-nowrap absolute tracking-[0]">
              领域附录
            </div>
            <img
              className="absolute w-[41px] h-[30px] top-0 left-0"
              alt="Element"
              src="/assets/function/5.svg"
            />
          </div>
          <img
            className="w-[832px] h-[440px] top-[102px] left-[68px] absolute object-cover"
            alt="Image"
            src="/assets/function/image-81.png"
          />
        </div>
        <div
          style={{ backgroundImage: `url(/assets/function/1.png)` }}
          className="absolute w-[919px] h-[142px] top-[10px] left-[341px]  bg-[100%_100%]"
        >
          <div className="w-[98px] top-[53px] left-[40px] [font-family:'PingFang_SC-Regular',Helvetica] font-normal text-[#263b5f] text-[24px] leading-[26px] absolute tracking-[0]">
            使用手册
          </div>
          <div className="top-[87px] left-[40px] [font-family:'PingFang_SC-Regular',Helvetica] font-normal text-[#263b5f] text-[16px] leading-[26px] whitespace-nowrap absolute tracking-[0]">
            助您轻松玩转专题知识库。如您在使用时遇到任何问题，欢迎随时与我们联系。
          </div>
        </div>
      </div>
    </div>
  );
};
export default Screen;
