import React, { useState } from "react";
import styles from "./index.less";
import { message, Button, Popover } from "antd";
import DefaultScholar from "src/image/chatGraph/defaultListPeople.png";
import DefaultScholarBlack from "src/image/paper/defaultScholar.svg";
import tj from "@itic-sci/teamjob";

interface Props {
  authors: any[];
  theme?: string;
  track?: string;
  btnTheme?: string;
  leftCount?: number;
  rightCount?: number;
}

const AuthorList: React.FC<Props> = ({
  authors,
  theme,
  track,
  btnTheme,
  leftCount = 3,
  rightCount = 2,
}) => {
  const [showAll, setShowAll] = useState(false);
  const leftAuthors = showAll ? authors : authors.slice(0, leftCount);
  const rightAuthors = showAll
    ? []
    : authors.slice(leftCount, leftCount + rightCount);
  const remainingAuthors = authors.length - leftCount - rightCount;

  const handleShowAllClick = () => {
    tj.track({
      params: {
        // 上报参数
        event_id:
          "kgi_cells_and_genes_search_result_paper_result_more_author_click",
      },
    });
    setShowAll(true);
  };

  const renderPop = (author: any) => {
    if (!author.aminer_id) {
      return false;
    }
    return (
      <div
        key={author.node_id}
        className={`${styles.listItem} ${
          theme === "black" && styles.listItemBlack
        }`}
      >
        <img
          src={
            (author.card && author.card.avatar) ||
            (theme === "black" ? DefaultScholarBlack : DefaultScholar)
          }
          alt=""
          onClick={() => {
            tj.track({
              params: {
                // 上报参数
                event_id:
                  "kgi_cells_and_genes_search_result_paper_result_author_click",
              },
            });
            window.open(
              `/graph_detail?id=${author.aminer_id}&node_type=person`
            );
          }}
          className={styles.leftIcon}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src =
              theme === "black" ? DefaultScholarBlack : DefaultScholar;
          }}
        />
        <div className={styles.rightPart}>
          <div className={styles.topInfo}>
            <p
              className={styles.topTitle}
              onClick={() => {
                if (author.aminer_id) {
                  tj.track({
                    params: {
                      // 上报参数
                      event_id:
                        "kgi_cells_and_genes_search_result_paper_result_author_click",
                    },
                  });
                  window.open(
                    `/graph_detail?id=${author.aminer_id}&node_type=person`,
                    "_blank"
                  );
                } else {
                  window.open(
                    `https://brain.itic-sci.com/search/person/?q=${
                      author.card
                        ? author.card.nameZh || author.card.name
                        : author.nameZh || author.name
                    }`,
                    "_blank"
                  );
                }
              }}
            >
              {author.card
                ? author.card.nameZh || author.card.name
                : author.nameZh || author.name}
            </p>
          </div>
          {author.aminer_id && (
            <>
              <div className={styles.bottomInfo}>
                <div className={styles.bottomCount}>
                  <div
                    className={`${styles.bottomDetail} ${styles.bottomDetailLeft}`}
                  >
                    <span className={styles.label}>H-index：</span>
                    <span className={styles.count}>
                      {author.card.hindex || "0"}
                    </span>
                  </div>
                  |
                  <div className={styles.bottomDetail}>
                    <span className={styles.label}>引用量：</span>
                    <span className={styles.count}>
                      {author.card.ncitation || "0"}
                    </span>
                  </div>
                  |
                  <div className={styles.bottomDetail}>
                    <span className={styles.label}>论文数：</span>
                    <span className={styles.count}>
                      {author.card.npubs === 10000
                        ? "10000+"
                        : author.card.npubs || "0"}
                    </span>
                  </div>
                </div>
              </div>
              {author.card.position && (
                <p className={styles.belongOrg + " text-ellipsis "}>
                  {author.card.position}
                </p>
              )}
              {(author.card.orgZh || author.card.org) && (
                <p
                  className={styles.belongOrg + " text-ellipsis "}
                  title={author.card.orgZh || author.card.org || "--"}
                >
                  {author.card.orgZh || author.card.org}
                </p>
              )}
            </>
          )}
          {!author.aminer_id && (
            <span
              className={styles.jumpLink}
              onClick={() =>
                window.open(
                  `https://brain.itic-sci.com/search/person/?q=${
                    author.nameZh || author.name
                  }`,
                  "_blank"
                )
              }
            >
              跳转至门户搜索
            </span>
          )}
        </div>
      </div>
    );
  };

  return (
    <div
      className={`${styles.authorList}  ${
        showAll && styles.authorListShowAll
      } ${theme === "black" && styles.authorListBlack}  ${
        btnTheme === "active" && styles.noMargin
      }`}
    >
      {leftAuthors.map((author, index) => (
        <Popover
          arrow={false}
          key={author.nameZh || author.name}
          overlayClassName={theme === "black" ? styles.popover : undefined}
          content={renderPop(author)}
          placement="bottom"
          trigger="hover"
        >
          <div
            key={index}
            onClick={() => {
              track &&
                tj.track({
                  params: {
                    // 上报参数
                    event_id: track,
                  },
                });
              author.aminer_id &&
                window.open(
                  `/graph_detail?id=${author.aminer_id}&node_type=person`
                );
            }}
            className={`${styles.authorName} ${
              author.aminer_id && styles.authorNameActive
            }`}
          >
            {author.nameZh || author.name}
          </div>
        </Popover>
      ))}
      {remainingAuthors > 0 && !showAll && (
        <button
          className={
            btnTheme === "active" ? styles.showMoreActive : styles.showMore
          }
          onClick={handleShowAllClick}
        >
          +{remainingAuthors} 位作者
        </button>
      )}
      {rightAuthors.map((author, index) => (
        <Popover
          arrow={false}
          key={author.nameZh || author.name}
          overlayClassName={theme === "black" ? styles.popover : undefined}
          content={renderPop(author)}
          placement="bottom"
          trigger="hover"
        >
          <div
            key={index}
            onClick={() => {
              track &&
                tj.track({
                  params: {
                    // 上报参数
                    event_id: track,
                  },
                });
              author.aminer_id &&
                window.open(
                  `/graph_detail?id=${author.aminer_id}&node_type=person`
                );
            }}
            className={`${styles.authorName} ${
              author.aminer_id && styles.authorNameActive
            }`}
          >
            {author.nameZh || author.name}
          </div>
        </Popover>
      ))}
    </div>
  );
};

export default AuthorList;
