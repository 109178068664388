import React, { useEffect, useState, useRef } from "react";
import ReactFullpage from "@fullpage/react-fullpage";
import { useNavigate, Link, useLocation } from "react-router-dom";
import ChatSearch from "./ChatSearch";
import switchIcon from "src/image/switchIcon.svg";
import homeBg from "src/image/homeBg.mp4";
import homeBgPng from "src/image/chatGraph/bg.png";
import tj from "@itic-sci/teamjob";

import home1 from "src/image/home1.png";
import home2 from "src/image/home2.png";
import home3 from "src/image/home3.png";
import styles from "./home.less";
const Fullpage = () => {
  const [currentIndex, setCurrentIndex] = useState(1);
  const navigate = useNavigate();

  const handleClick = (index) => {
    fullpage_api.moveTo(index);
    setCurrentIndex(index);
    if (document.querySelector(".fp-watermark")) {
      document.querySelector(".fp-watermark").style.display = "none";
    }
  };
  const onLeave = (a, b, c) => {
    setCurrentIndex(b.index + 1);
    if (document.querySelector(".fp-watermark")) {
      document.querySelector(".fp-watermark").style.display = "none";
    }
  };
  useEffect(() => {
    handleClick(1);
  }, []);
  return (
    <>
      <ReactFullpage
        //fullpage options
        licenseKey={"YOUR_KEY_HERE"}
        onLeave={onLeave}
        scrollingSpeed={1200} /* Options here */
        render={({ state, fullpageApi }) => {
          return (
            <ReactFullpage.Wrapper
              anchors={["firstPage", "secondPage", "thirdPage"]}
            >
              <div className="section">
                <ChatSearch
                  handleClick={(index) => {
                    handleClick(index);
                  }}
                />
              </div>
              <div className={`section ${styles.secondPartCt}`}>
                <div className={styles.secondPart}>
                  <div className={styles.leftPart}>
                    <div className={styles.title}>专题知识库</div>
                    <div className={styles.desc}>
                      专题知识库是国际科技信息中心在大模型时代全面拥抱AI，赋能领域科研工作推出的创新探索产品。首期上线的细胞与基因专题知识库，整合领域内600万+知识实体，构建了首个集对话搜索和知识图谱于一体的智能搜索问答系统，为用户提供推进问答式增强检索、生成式知识服务，以及可视化展示多源信息及关联关系的图谱服务。目前，专题知识库所有功能均免费开放，欢迎体验。
                    </div>
                    <div
                      className={styles.btn}
                      onClick={() => {
                        tj.track({
                          params: {
                            // 上报参数
                            event_id: "kgi_cells_and_genes_home_trynow_1_click",
                          },
                        });
                        navigate("/Chat");
                      }}
                    >
                      立即体验
                    </div>
                  </div>

                  <div className={styles.videoPart}>
                    <video
                      playsInline
                      data-keepplaying
                      poster={homeBgPng}
                      autoPlay
                      muted
                      loop
                      className={styles.video}
                    >
                      <source src={homeBg} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                </div>
              </div>
              <div className="section  [background:#0f1959]">
                <div className={styles.threePart}>
                  <div className={styles.threePartTop}>
                    <div className=" [font-family:'PingFang_SC-Medium',Helvetica] font-medium text-white text-[48px] tracking-[0] leading-[71.3px] whitespace-nowrap">
                      产品亮点
                    </div>
                    <div className={styles.subTitle}>
                      <div className=" [font-family:'PingFang_SC-Regular',Helvetica] font-normal text-[#ffffff80] text-[24px] tracking-[0] leading-[35.6px] whitespace-nowrap">
                        高效
                      </div>
                      <div className=" [font-family:'PingFang_SC-Regular',Helvetica] font-normal text-[#ffffff80] text-[24px] tracking-[0] leading-[35.6px] whitespace-nowrap">
                        专业
                      </div>
                      <div className="  [font-family:'PingFang_SC-Regular',Helvetica] font-normal text-[#ffffff80] text-[24px] tracking-[0] leading-[35.6px] whitespace-nowrap">
                        准确
                      </div>
                    </div>
                  </div>
                  <div className={styles.threePartMiddle}>
                    <div className="w-[480px] h-[480px] bg-[#032a8370] rounded-[4px] border-2 border-solid border-transparent">
                      <div className="relative w-[483px] h-[483px] -top-px -left-px bg-[#032a8370] rounded-[4px] shadow-[0px_5px_25px_rgba(7, 24, 64, 0.80)]">
                        <img
                          className="absolute w-[480px] h-[296px] top-px left-px object-cover"
                          alt="Image"
                          src={home1}
                        />
                        <div className="absolute w-[480px] h-[271px] top-[123px] left-px [background:linear-gradient(180deg,rgba(57.69,99.04,206.23,0)_0%,rgb(3,37,115)_72.4%)]" />
                        <div className="top-[254px] left-[152px] [font-family:'PingFang_SC-Medium',Helvetica] font-medium text-white text-[24px] leading-[35.6px] whitespace-nowrap absolute tracking-[0]">
                          领域知识数据库
                        </div>
                        <div className="w-[416px]  text-justify text-left top-[302px] left-[33px] [font-family:'PingFang_SC-Regular',Helvetica] font-normal text-[#ffffffcc] text-[16px] leading-[25.6px] absolute tracking-[0]">
                          以国家学科分类标准、实地调研结果与专家论证为基，国际科技信息中心领域知识数据库深度融合细胞与基因领域600万+知识实体，覆盖41个分支领域的知识逻辑架构，收录182万篇论文、380万专家信息、2万研究机构及34万项专利。用户通过简单搜索，可快速获取知识，洞悉领域现状及发展趋势。
                        </div>
                      </div>
                    </div>
                    <div className="w-[480px] h-[480px] bg-[#032a8370] rounded-[4px] border-2 border-solid border-transparent">
                      <div className="relative w-[483px] h-[483px] -top-px -left-px bg-[#032a8370] rounded-[4px] shadow-[0px_5px_25px_rgba(7, 24, 64, 0.80)]">
                        <img
                          className="absolute w-[480px] h-[296px] top-px left-px object-cover"
                          src={home3}
                          alt="Image"
                        />
                        <div className="absolute w-[480px] h-[271px] top-[123px] left-px [background:linear-gradient(180deg,rgba(57.69,99.04,206.23,0)_0%,rgb(3,37,115)_72.4%)]" />
                        <div className="top-[254px] left-[152px] [font-family:'PingFang_SC-Medium',Helvetica] font-medium text-white text-[24px] leading-[35.6px] whitespace-nowrap absolute tracking-[0]">
                          可视化知识图谱
                        </div>
                        <div className="w-[416px]  text-justify  top-[302px] left-[33px] [font-family:'PingFang_SC-Regular',Helvetica] font-normal text-[#ffffffcc] text-[16px] leading-[25.6px] absolute tracking-[0]">
                          可视化知识图谱工具是国际科技信息中心结合多源数据，以高效数据理解、关系抽取和数据建模技术为底座，打造的自动化构建可视化服务工具。通过该工具，用户可以快速聚焦知识实体，获取关联信息，实现多场景知识网络可视化探索和关联信息分析，极大提高知识查询的高效性、灵活性。
                        </div>
                      </div>
                    </div>
                    <div className="w-[480px] h-[480px] bg-[#032a8370] rounded-[4px] border-2 border-solid border-transparent">
                      <div className="relative w-[483px] h-[483px] -top-px -left-px bg-[#032a8370] rounded-[4px] shadow-[0px_5px_25px_rgba(7, 24, 64, 0.80)]">
                        <img
                          className="absolute w-[480px] h-[296px] top-px left-px object-cover"
                          src={home2}
                          alt="Image"
                        />
                        <div className="absolute w-[480px] h-[271px] top-[123px] left-px [background:linear-gradient(180deg,rgba(57.69,99.04,206.23,0)_0%,rgb(3,37,115)_72.4%)]" />
                        <div className="top-[254px] left-[152px] [font-family:'PingFang_SC-Medium',Helvetica] font-medium text-white text-[24px] leading-[35.6px] whitespace-nowrap absolute tracking-[0]">
                          大模型知识检索
                        </div>
                        <div className="w-[416px] text-justify top-[302px] left-[33px] [font-family:'PingFang_SC-Regular',Helvetica] font-normal text-[#ffffffcc] text-[16px] leading-[25.6px] absolute tracking-[0]">
                          大模型知识检索是国际科技信息中心基于人工智能大语言模型搭建的高效问答检索系统，依据长短记忆原理，准确解析用户语义意图，实现智能搜索问答。目前已支持的多轮连续对话式搜索，可实现用户在回溯获取详细数据时提高服务精度的效果，提升科研工作的效率和交互性使用体验。
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.threePartBottom}>
                    <div className={styles.threePartGuide}>
                      <div className={styles.threePartGuideTop}>
                        <div className={styles.threePartLeft}>
                          <div className="text-white text-[32px] leading-[47.5px] [font-family:'PingFang_SC-Regular',Helvetica] font-normal tracking-[0] whitespace-nowrap">
                            使用手册
                          </div>
                          <div className="text-[#ffffff99] text-[24px] leading-[35.6px] [font-family:'PingFang_SC-Regular',Helvetica] font-normal tracking-[0] whitespace-nowrap">
                            轻松玩转专题知识库～
                          </div>
                        </div>

                        <div
                          onClick={() => {
                            tj.track({
                              params: {
                                // 上报参数
                                event_id:
                                  "kgi_cells_and_genes_home_trynow_2_click",
                              },
                            });
                            navigate(`/Guide`);
                          }}
                          className={`${styles.goGuide} "inline-flex cursor-pointer items-center justify-center gap-[10px] px-[56px] py-[12px]  rounded-[44px] border-2 border-solid border-[#ffffffb2]"`}
                        >
                          <div className="relative w-fit mt-[-2.00px] text-white text-[24px] leading-[43.2px] [font-family:'PingFang_SC-Regular',Helvetica] font-normal tracking-[0] whitespace-nowrap">
                            立即上手
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <p className=" [font-family:'PingFang_SC-Regular',Helvetica] font-normal text-[#ffffff80] text-[12px] tracking-[0] leading-[normal]">
                      <span className="[font-family:'PingFang_SC-Regular',Helvetica] font-normal text-[#ffffff80] text-[12px] tracking-[0]">
                        Copyright © 2022-2023 国际科技信息中心 版权所有
                      </span>
                      <a
                        href="https://beian.miit.gov.cn/"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        <span className="underline"> 粤ICP备2022127751号</span>
                      </a>
                    </p> */}
                  </div>
                </div>
              </div>
            </ReactFullpage.Wrapper>
          );
        }}
      />
      <div className="fixed w-[32px] h-[200px] right-[40px] top-[45vh]">
        <img
          className="top-[-28px] cursor-pointer absolute  w-[32px] h-[32px] left-[0px] "
          alt="Frame"
          style={{ top: `${38 * (currentIndex - 1) - 28}px` }}
          onClick={() => handleClick(currentIndex)}
          src={switchIcon}
        />
        <div
          onClick={() => handleClick(1)}
          className="top-[-16px] cursor-pointer absolute w-[10px] h-[10px] left-[12px] hover:bg-[#6C88C7CC] bg-[#6c87c666] rounded-[5px]"
        />
        <div
          onClick={() => handleClick(2)}
          className="top-[22px] cursor-pointer absolute w-[10px] h-[10px] left-[12px]  hover:bg-[#6C88C7CC] bg-[#6c87c666] rounded-[5px]"
        />
        <div
          onClick={() => handleClick(3)}
          className="top-[56px] cursor-pointer absolute w-[10px] h-[10px] left-[12px]  hover:bg-[#6C88C7CC] bg-[#6c87c666] rounded-[5px]"
        />
      </div>
    </>
  );
};

export default Fullpage;
