import react, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
  useRef,
  useContext,
} from "react";
import _ from "lodash";
import moment from "moment";
import styles from "./index.less";
import DefaultIndustry from "src/image/defaultIndustry.svg";
import { getCompanyList } from "../../services/api";
import { UserContext } from "src/Context";
import { Pagination, Select, Input, ConfigProvider, message } from "antd";
import zhCN from "antd/lib/locale/zh_CN";
import InputClear from "../../image/inputClear.svg";
import LeftTree from "./LeftTree";
import DetailModal from "./DetailModal";
import Empty from "src/image/ListEmpty.svg";

export interface IListData {
  org_nameZh: string;
  org_name: string;
  establishdate: string;
  location: string;
  mini_fields: any;
  n_citation: string;
  image: string;
  node_id: string;
  fields: any;
  n_papers: string;
}
interface IIndustry {
  stats: any;
}

function Industry(props: IIndustry, ref: any) {
  const listRef = useRef<HTMLDivElement>(null);
  const currentPageRef = useRef(1);
  const { isloading, setIsLoading } = useContext(UserContext);
  const listDataRef = useRef([]);
  const inputRef = useRef(null);

  const [searchStr, setSearchStr] = useState<string>("");
  const [leftCurrentClickData, setLeftCurrentClickData] = useState<any>({});
  const [sortType, setSortType] = useState<any>("registered_capital&true");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [total, setTotal] = useState<number>(1);
  const [listData, setListData] = useState<Array<any>>([]);
  const [checkBoxState, setCheckBoxState] = useState(false);

  // 弹窗
  const [visiable, setVisiable] = useState<boolean>(false);
  const [visiableData, setVisiableData] = useState<any>({});

  useImperativeHandle(ref, () => ({}));
  const getListData = async (params: any) => {
    setIsLoading(true);
    let res: any = {};
    try {
      res = await getCompanyList({
        province: params.father || params.title,
        city: params.father && params.title,
        sort_field: sortType.split("&")[0],
        reverse: sortType.split("&")[1],
        size: pageSize,
        opening_only: checkBoxState,
        page: currentPage,
        keyword: searchStr.trim(),
      });
    } catch (error) {
      message.error("接口网络状态异常，请您稍后再试！");
    }

    setIsLoading(false);

    if (res.code === 0) {
      let temp = res.data.enterprises;

      setListData(temp);
      setTotal(res.data.total);
      listDataRef.current = temp;
    }
    setIsLoading(false);
  };

  const handleChange = (page: number, pageSize: number) => {
    setPageSize(pageSize);
    setCurrentPage(page);
  };

  const handleClick = (data: any) => {
    setVisiableData(data);
    setVisiable(true);
  };

  useEffect(() => {
    let html = document.querySelector("html");
    if (html) {
      if (visiable) {
        html.style.overflow = "hidden";
      } else {
        html.style.overflow = "auto";
      }
    }
  }, [visiable]);

  useEffect(() => {
    if (listRef.current) {
      listRef.current.scrollTop = 0;
    }
    getListData(leftCurrentClickData);
  }, [currentPage, pageSize]);

  useEffect(() => {
    if (currentPage !== 1) {
      setCurrentPage(1);
    } else {
      if (listRef.current) {
        listRef.current.scrollTop = 0;
      }
      getListData(leftCurrentClickData);
    }
  }, [
    JSON.stringify(leftCurrentClickData),
    checkBoxState,
    sortType,
    searchStr,
  ]);

  return (
    <>
      <LeftTree
        stats={props.stats}
        setCurrentClickData={(params: any) => {
          setLeftCurrentClickData(params);
        }}
      />
      <div className={styles.company}>
        <div className={styles.topBar}>
          <span className={styles.title}>细胞与基因</span>

          <div className={styles.sortCt}>
            <div className={styles.checkBox}>
              <span
                onClick={() => setCheckBoxState(!checkBoxState)}
                className={`${""}  ${
                  checkBoxState ? styles.checkBoxImgActive : styles.checkBoxImg
                }`}
              ></span>
              <span className={styles.checkBoxText}>只看存续</span>
            </div>
            <Select
              defaultValue="registered_capital&true"
              className={styles.selectBox}
              onChange={(value: any) => {
                setSortType(value);
              }}
              suffixIcon={
                <span
                  className={`iconfont icon-down ${styles.iconfont}`}
                ></span>
              }
              options={[
                { value: "registered_capital&true", label: "注册资本从高到低" },
                {
                  value: "registered_capital&false",
                  label: "注册资本从低到高",
                },
                { value: "establish_date&false", label: "成立日期从早到晚" },
                { value: "establish_date&true", label: "成立日期从晚到早" },
              ]}
            />
            <Input
              className={styles.searchBox}
              ref={inputRef}
              placeholder="请输入要搜索的内容"
              allowClear={{
                clearIcon: (
                  <img
                    src={InputClear}
                    alt=""
                    className={styles.closeImg}
                    onClick={() => {
                      setSearchStr("");
                    }}
                  />
                ),
              }}
              onPressEnter={() => {
                if (inputRef.current) {
                  setSearchStr((inputRef.current as any).input.value);
                }
              }}
              suffix={
                <span
                  className={`iconfont icon-sousuo ${styles.iconfont}`}
                  onClick={() => {
                    if (inputRef.current) {
                      setSearchStr((inputRef.current as any).input.value);
                    }
                  }}
                ></span>
              }
            />
          </div>
        </div>
        {Array.isArray(listData) && !!listData.length && (
          <>
            {listData.length && (
              <div className={styles.listCt} ref={listRef} key={searchStr}>
                {listData.map((item: any, index) => (
                  <div
                    key={Math.random()}
                    className={styles.listItem}
                    onClick={() => {}}
                  >
                    <div className={styles.topInfo}>
                      <p
                        className={styles.topTitle + " text-two-ellipsis"}
                        // onClick={() => item.url && window.open(item.url)}
                        title={item.org_name}
                      >
                        <span
                          className={styles.titleText}
                          onClick={() => handleClick(item)}
                        >
                          {item.org_name}
                        </span>

                        {item.org_status && (
                          <>
                            {(item.org_status === "存续" ||
                              item.org_status === "开业" ||
                              item.org_status === "正常" ||
                              item.org_status === "在业") && (
                              <span className={styles.tabActive}>
                                {item.org_status}
                              </span>
                            )}
                            {!(
                              item.org_status === "存续" ||
                              item.org_status === "开业" ||
                              item.org_status === "正常" ||
                              item.org_status === "在业"
                            ) && (
                              <span className={styles.tabNormal}>
                                {item.org_status}
                              </span>
                            )}
                          </>
                        )}
                      </p>
                      {/* <LinkGraph /> */}
                    </div>

                    <div className={styles.infoPart}>
                      {item.legal_represent && (
                        <span className={styles.info}>
                          法定代表人：{item.legal_represent}
                        </span>
                      )}
                      {item.registered_capital && (
                        <span className={styles.info}>
                          注册资本：{item.registered_capital}
                        </span>
                      )}

                      {item.establish_date && (
                        <span className={styles.info}>
                          成立日期：{item.establish_date}
                        </span>
                      )}
                    </div>
                    <div className={styles.infoPart}>
                      {item.address && (
                        <span className={styles.info}>
                          注册地址：{item.address}
                        </span>
                      )}
                      {item.website && (
                        <span className={styles.info}>
                          网址：
                          {item.website ? (
                            <span
                              className={styles.infoActive}
                              onClick={() =>
                                window.open(item.website, "_blank")
                              }
                            >
                              {item.website}
                            </span>
                          ) : (
                            "--"
                          )}
                        </span>
                      )}
                    </div>
                    {Array.isArray(item.fields) && !!item.fields.length && (
                      <div className={styles.bottomInfo}>
                        <div className={styles.tagList}>
                          {Array.isArray(item.fields) &&
                            item.fields
                              .filter((v: any) => !!v)
                              .slice(0, 5)
                              .map((itemField: any) => (
                                <span className={styles.tag} key={itemField}>
                                  {itemField}
                                </span>
                              ))}
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            )}
            <div className={styles.footerBar}>
              <ConfigProvider locale={zhCN}>
                <Pagination
                  total={total}
                  showSizeChanger
                  pageSize={pageSize}
                  current={currentPage}
                  onChange={handleChange}
                  defaultCurrent={1}
                  className={styles.pagination}
                  showQuickJumper
                />
              </ConfigProvider>
              <div className={styles.totalBar}>共 {total} 个结果 </div>
            </div>
          </>
        )}
        {Array.isArray(listData) && listData.length === 0 && (
          <div className={styles.emptyPart}>
            <img src={Empty} alt="" />
            <p>找不到相关内容</p>
          </div>
        )}
      </div>
      {visiable && (
        <DetailModal data={visiableData} setVisiable={setVisiable} />
      )}
    </>
  );
}

export default forwardRef(Industry);
