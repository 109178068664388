import react, { useState, useRef, useEffect } from "react";
import styles from "./index.less";
import OrgWin from "./OrgWin";
import ScholarWin from "./ScholarWin";
import { getIndustryList } from "../../services/api";
import _ from "lodash";
interface IOrgDetailPart {
  currentClickData: any;
}

function OrgDetailPart(props: IOrgDetailPart) {
  const { currentClickData } = props;

  useEffect(() => {}, [JSON.stringify(currentClickData)]);
  return (
    <>
      <div
        className={
          currentClickData.node_type === "org"
            ? styles.OrgDetailPart
            : styles.hide
        }
      >
        <OrgWin currentClickData={currentClickData} />
        <ScholarWin currentClickData={currentClickData} />
      </div>
    </>
  );
}

export default OrgDetailPart;
