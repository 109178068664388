import React, { useState, useRef, useEffect } from "react";
import styles from "./index.less";
import tj from "@itic-sci/teamjob";

const ExpandableMutiText = ({ text }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isOverflown, setIsOverflown] = useState(false);
  const textRef = useRef(null);

  useEffect(() => {
    const checkOverflow = () => {
      const element = textRef.current;
      setIsOverflown(element.scrollHeight > element.clientHeight);
    };

    checkOverflow();
    window.addEventListener("resize", checkOverflow);

    return () => {
      window.removeEventListener("resize", checkOverflow);
    };
  }, [text]);

  const toggleExpansion = () => {
    !isExpanded &&
      tj.track({
        params: {
          // 上报参数
          event_id: "kgi_cells_and_genes_detail_paper_info_more_abstract_click",
        },
      });
    setIsExpanded(!isExpanded);
  };

  const renderExpandButton = () => {
    if (isOverflown) {
      return (
        <>
          {!isExpanded && (
            <span
              onClick={toggleExpansion}
              className={`${styles["expandable-btn"]}`}
            >
              更多
              <span
                className={`${styles["expandable-icon"]} iconfont icon-xiangxia`}
              ></span>
            </span>
          )}

          {isExpanded && (
            <span
              className={`${styles["collapse-link"]}`}
              onClick={toggleExpansion}
            >
              收起
            </span>
          )}
        </>
      );
    }
  };

  return (
    <div
      className={`${styles["expandable-text"]} ${
        isExpanded ? styles.expanded : ""
      }`}
      ref={textRef}
    >
      {text}
      {renderExpandButton()}
    </div>
  );
};

export default ExpandableMutiText;
