import react, { useState, useRef, useEffect } from "react";
import styles from "./index.less";
import defaultPeople from "../../../image/chatGraph/scholar.png";
import loadingGif from "../../../image/loading.gif";
import Chart from "./Chart";
import { getAiNodeDetail } from "src/services/api";
import { Spin, Skeleton } from "antd";
import emptyChart from "../../../image/emptyChart.svg";
import _ from "lodash";
import tj from "@itic-sci/teamjob";

interface IPersonWin {
  currentClickData?: any;
}

function PersonWin(props: IPersonWin) {
  const { currentClickData } = props;
  const [loading, setLoading] = useState(false);
  const [rawData, setRawData] = useState<any>({});
  const queryPersonDetail = async (params: any) => {
    let res: any = await getAiNodeDetail({
      entity_id: params.node_id,
      entity_type: "person",
    });
    if (res.code === 0) {
      let temp = res.data;
      setRawData(temp);
    }
  };

  const formatNum = (number: "number") => {
    return typeof number === "number" ? number : "--";
  };

  useEffect(() => {
    queryPersonDetail(currentClickData);
  }, [JSON.stringify(currentClickData)]);
  return (
    <div className={styles.PersonWin}>
      <img
        className={styles.listImg}
        src={currentClickData.avatar || ""}
        alt=""
        onClick={() => {
          tj.track({
            params: {
              // 上报参数
              event_id: "kgi_cells_and_genes_detail_scholar_info_name_click",
            },
          });
          currentClickData.url && window.open(currentClickData.url);
        }}
        onError={({ currentTarget }) => {
          currentTarget.onerror = null; // prevents looping
          currentTarget.src = defaultPeople;
        }}
      />
      <p
        className={styles.listName}
        onClick={() => {
          tj.track({
            params: {
              // 上报参数
              event_id: "kgi_cells_and_genes_detail_scholar_info_name_click",
            },
          });
          currentClickData.url && window.open(currentClickData.url);
        }}
      >
        {currentClickData.name_zh || currentClickData.name}
      </p>

      {currentClickData &&
        (currentClickData.org_nameZh || currentClickData.org_name) && (
          <p
            className={styles.subName}
            title={currentClickData.org_nameZh || currentClickData.org_name}
          >
            {currentClickData.org_nameZh || currentClickData.org_name}
          </p>
        )}

      <div className={styles.countPart}>
        <div className={styles.countItem}>
          <p className={styles.count}>{formatNum(currentClickData.hindex)}</p>
          <p className={styles.countType}>H-index</p>
        </div>
        <div className={styles.countItem}>
          <p className={styles.count}>
            {currentClickData.papers === 10000
              ? "10000+"
              : formatNum(currentClickData.papers)}
          </p>
          <p className={styles.countType}>论文数</p>
        </div>
        <div className={styles.countItem}>
          <p className={styles.count}>{formatNum(currentClickData.citation)}</p>
          <p className={styles.countType}>引用数</p>
        </div>
      </div>
      <div className={styles.chartpart}>
        {/* <p className={styles.title}>研究兴趣</p> */}

        <img
          src={loadingGif}
          style={{ width: 150, display: loading ? "inline-block" : "none" }}
        />

        <div style={{ display: loading ? "none" : "block" }}>
          <Chart id={currentClickData.node_id} setLoading={setLoading} />
        </div>
      </div>
      {(rawData?.contact?.bio || rawData?.contact?.bio_zh) && (
        <div className={styles.introduce}>
          {(rawData?.contact?.bio || rawData?.contact?.bio_zh || "")
            .split("<br>")
            .map((v: any, index: any) => (
              <p className={styles.text} key={index}>
                {v}
              </p>
            ))}
        </div>
      )}
    </div>
  );
}

export default PersonWin;
