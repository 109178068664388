import react, { useState, useEffect } from "react";
import styles from "./index.less";
import { getProvinceTreeData } from "../../../services/api";
import { Tree } from "antd";
import openSvg from "src/image/common/caret-right.svg";
import closeSvg from "src/image/common/caret-down.svg";
interface ILeftList {
  setCurrentClickData: any;
  stats: any;
}

interface ILeftListItem {
  name: string;
  name_zh: string;
  node_id: string;
}

interface ITopItem {
  key: string;
  name: string;
  countKey?: string;
  unit?: string;
}

function LeftList(props: ILeftList) {
  const { setCurrentClickData, stats } = props;
  const [currentKey, setCurrentKey] = useState<string>("1");
  const [status, setStatus] = useState<"expand" | "collapse">("expand");
  const [data, setData] = useState<any>([]);
  // useEffect(() => {
  //   setCurrentKey(leftCurrentClickData.id);
  // }, [JSON.stringify(leftCurrentClickData)]);
  const handleClick = (key: string, name: string, item: any) => {
    console.log(key);
    setCurrentKey(key);
    setCurrentClickData({ id: key, name, ...item });
  };
  const formatTreeData = (rootList: any, father: any) => {
    let children = [];
    for (let i = 0; i < rootList.length; i++) {
      let temp: any = {
        title: rootList[i].name,
        label: rootList[i].name,
        key: rootList[i].id,
        value: rootList[i].id,
        father,
        ...rootList[i],
      };
      if (rootList[i].children && rootList[i].children.length) {
        temp.isLeaf = false;
        let child = formatTreeData(rootList[i].children, rootList[i].name);
        if (child && child.length) {
          temp.children = formatTreeData(
            rootList[i].children,
            rootList[i].name
          );
        }
      } else {
        temp.isLeaf = true;
      }
      children.push(temp);
    }
    return children;
  };
  const initData = async () => {
    let res: any = await getProvinceTreeData();
    if (res.code === 0) {
      let formatData = formatTreeData(res.data.area_tree, "");
      setData(formatData);
    }
  };
  useEffect(() => {
    initData();
  }, []);

  return (
    <>
      {status === "expand" && (
        <div className={styles.leftList}>
          <div className={styles.topBar}>
            <span className={styles.topBarTitle}>相关企业</span>
            <span className={styles.topBarCount}>
              {stats.enterprise_count}&nbsp; 所
            </span>
          </div>
          <span
            className={`${styles.rootNode} ${
              currentKey === "1" ? styles.rootNodeActive : ""
            }`}
            onClick={() => {
              handleClick("1", "全国范围", {});
            }}
          >
            全国范围
          </span>
          {!!data.length && (
            <Tree
              className={styles.list}
              defaultExpandedKeys={["1"]}
              switcherIcon={(node: any) => {
                return node.expanded ? (
                  <span
                    className={`${styles.iconfont} icon-caret-down iconfont`}
                  ></span>
                ) : (
                  <span
                    className={`${styles.iconfont} icon-caret-right iconfont`}
                  ></span>
                );
              }}
              selectedKeys={[currentKey]}
              blockNode
              onSelect={(selectedKeys, node: any) => {
                let item = node.node;
                handleClick(item.key, item.name, item);
              }}
              treeData={data}
            />
          )}
        </div>
      )}
    </>
  );
}

export default LeftList;
