import * as echarts from "echarts";
import react, { useState, useRef, useEffect, useLayoutEffect } from "react";
import styles from "./index.less";
import { getOrgInfo } from "../../../services/api";
import _ from "lodash";

const Chart = (props) => {
  let { id } = props;
  let scale = window.innerWidth / 1920;
  const el = useRef(null);
  const [chartData, setChartData] = useState([]);
  const chartRef = useRef(null);
  const init = () => {
    var chartDom = el.current;
    if (!chartRef.current) {
      chartRef.current = echarts.init(chartDom);
    }
    var option;
    option = {
      // width: 220 * scale,
      // height: 70 * scale,
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "cross",
          crossStyle: {
            color: "#c1c1c1",
          },
        },
      },
      grid: {
        // 图表容器的左边距
        left: 50,
        // 图表容器的右边距
        right: 50,
        // 图表容器的上边距
        top: 30,
        // 图表容器的下边距
        bottom: 20,
      },

      xAxis: [
        {
          type: "category",
          data: chartData.map((item) => item.key),
          axisLine: {
            lineStyle: {
              color: "#c1c1c1",
            },
          },
          axisLabel: {
            color: "#c1c1c1",
          },
        },
      ],
      yAxis: [
        {
          type: "value",
          name: "Publications",
          splitLine: {
            show: false,
          },
          axisLine: {
            lineStyle: {
              color: "#c1c1c1",
            },
          },
          axisLabel: {
            width: 200,
            color: "#c1c1c1",
            formatter: function (value) {
              if (value >= 1000000) {
                // 如果数值大于 1000000，则使用简写方式显示，例如：1M
                return (value / 1000000).toFixed(1) + "M";
              } else if (value >= 1000) {
                // 如果数值大于 1000，则使用简写方式显示，例如：1K
                return (value / 1000).toFixed(1) + "K";
              } else {
                // 如果数值小于等于 1000，则直接显示
                return value.toFixed(1);
              }
            },
          },
        },
        {
          type: "value",
          name: "Citations",
          splitLine: {
            show: false,
          },
          axisLine: {
            lineStyle: {
              color: "#c1c1c1",
            },
          },
          axisLabel: {
            color: "#c1c1c1",
            width: 100,
            formatter: function (value) {
              if (value >= 1000000) {
                // 如果数值大于 1000000，则使用简写方式显示，例如：1M
                return (value / 1000000).toFixed(1) + "M";
              } else if (value >= 1000) {
                // 如果数值大于 1000，则使用简写方式显示，例如：1K
                return (value / 1000).toFixed(1) + "K";
              } else {
                // 如果数值小于等于 1000，则直接显示
                return value.toFixed(1);
              }
            },
          },
        },
      ],
      series: [
        {
          name: "Publications",
          type: "bar",
          // color: "#3284FF",
          tooltip: {
            valueFormatter: function (value) {
              return value;
            },
          },
          data: chartData.map((item) => item.doc_count),
        },
        {
          name: "Citations",
          type: "line",
          yAxisIndex: 1,
          lineStyle: {
            width: 1,
          },
          // color: "#fff",
          tooltip: {
            valueFormatter: function (value) {
              return value;
            },
          },
          data: chartData.map((item) => item.c_total.value),
        },
      ],
    };

    option && chartRef.current.setOption(option);
  };

  const handleScreenAuto = () => {
    if (chartRef.current) {
      chartRef.current.resize();
    }
  };

  useEffect(() => {
    handleScreenAuto();
    // 监听
    window.addEventListener("resize", _.debounce(handleScreenAuto, 600));
    // 销毁
    return () =>
      window.removeEventListener("resize", _.debounce(handleScreenAuto, 600));
  });

  const getData = async () => {
    let res = await getOrgInfo([
      {
        action: "orgapi.get",
        parameters: { id, need_details: true },
        schema: { person: ["name", "id"] },
      },
    ]);
    if (res.data[0].details) {
      setChartData(
        res.data[0].details.year_total.sort((a, b) => a.key - b.key)
      );
    }
  };

  useEffect(() => {
    if (chartData.length) {
      init();
    }
  }, [JSON.stringify(chartData)]);
  useLayoutEffect(() => {
    getData();
  }, [id]);
  return <div ref={el} className={styles.chart}></div>;
};
export default Chart;
