import React, { useEffect, useState, useRef } from "react";
import styles from "./ChatSearch.less";
import { Routes, Route, useNavigate, BrowserRouter } from "react-router-dom";
import { getAiNodeStats, getPromptWord } from "src/services/api";
import downGif from "src/image/down.gif";
import bgMov from "src/image/chatGraph/bg.mp4";
import { Pagination, Tabs, Input, ConfigProvider, message } from "antd";
import InputClear from "src/image/inputClear.svg";
import tj from "@itic-sci/teamjob";
import CountUp from "react-countup";
import NewsPart from "src/component/NewsPart";
function AnimatedNumber(props: any) {
  return <CountUp start={0} end={props.end} separator="," duration={2.5} />;
}
function Chat(props: any) {
  const { handleClick } = props;
  const [currentTab, setCurrentTab] = useState("");
  const navigate = useNavigate();
  const inputRef = useRef(null);
  const [searchStr, setSearchStr] = useState("");
  const [promptWord, setPromptWord] = useState<any>({});
  const [nodeStats, setNodeStats] = useState({
    paper_count: 2878184,
    scholar_count: 4617136,
    patent_count: 409676,
    org_count: 27341,
    enterprise_count: 0,
  });
  const [currentPlaceHolder, setCurrentPlaceHolder] = useState(
    "输入论文标题关键词，在 2,321,032 个论文实体中检索"
  );
  const [searchTag, setSearchTag] = useState("paper");

  const [hasAnimated, setHasAnimated] = useState(false);

  useEffect(() => {
    setHasAnimated(true);
  }, []);

  const menuList = [
    {
      name: "论文",
      key: "paper",
      jumpKey: "paper",
      count: nodeStats.paper_count,
      searchtag: true,
      icon: "icon-lunwen",
      placeHolder: `输入论文标题关键词，在 ${formatNumber(
        nodeStats.paper_count
      )} 个论文实体中检索`,
    },
    {
      name: "专利",
      key: "patent",
      jumpKey: "patent",
      placeHolder: ``,
      count: nodeStats.patent_count,
      icon: "icon-zhuanli",
    },
    {
      name: "学者",
      count: nodeStats.scholar_count,
      searchtag: true,
      icon: "icon-xuezhe1",
      placeHolder: `输入学者关键词，在 ${formatNumber(
        nodeStats.scholar_count
      )} 个学者实体中检索`,
      key: "scholar",
      jumpKey: "scholar",
    },
    {
      name: "机构",
      searchtag: true,
      count: nodeStats.org_count,
      icon: "icon-jigou1",
      placeHolder: `输入机构关键词，在 ${formatNumber(
        nodeStats.org_count
      )} 个机构实体中检索`,
      key: "org",
      jumpKey: "industry",
    },
    {
      name: "企业",
      count: nodeStats.enterprise_count,
      placeHolder: ``,
      icon: "icon-qiye",
      jumpKey: "company",
      key: "enterprise",
    },
  ];
  const handleConfirm = () => {
    let str = (inputRef.current as any).input.value;
    if (str && str.trim().length) {
      if (str.length > 200) {
        message.error("不能超过200个关键词！");
      } else {
        navigate(`/SearchTabs?tag=${searchTag}&search=${str.trim()}`);
      }
    } else {
      message.error("请输入搜索关键词");
    }
  };
  function formatNumber(number: number) {
    return number.toLocaleString("en-US");
  }

  const queryStats = async () => {
    try {
      let res: any = await getAiNodeStats();
      if (res.code === 0) {
        setNodeStats(res.data.stats);
        setCurrentPlaceHolder(
          `输入论文标题关键词，在 ${formatNumber(
            res.data.stats.paper_count
          )} 个论文实体中检索`
        );
      }
    } catch (error) {
      console.log(error);
    }
  };
  const queryPromptWord = async () => {
    try {
      let res: any = await getPromptWord();
      if (res.code === 0) {
        setPromptWord(res.data.words);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleJump = (item?: any) => {
    if (item) {
      localStorage.setItem("templateKey", item.jumpKey);
    }
    navigate(`/Screen`);
  };
  useEffect(() => {
    queryStats();
    queryPromptWord();
    tj.track({
      params: {
        // 上报参数
        event_id: "kgi_cells_and_genes_home_enter",
      },
    });
  }, []);
  return (
    <div className={styles.chat}>
      <div className={styles.chatContent}>
        <p className={styles.chatTitle}>细胞与基因专题知识库</p>
        <p className={styles.chatSubTitle}>
          一个为学术研究而生，由AI大模型驱动的领域知识图谱
        </p>
        <div className={styles.searchPart}>
          <div className={styles.tags}>
            {menuList
              .filter((v) => v.searchtag)
              .map((item) => (
                <span
                  className={`${styles.tag} ${
                    searchTag === item.key ? styles.tagActive : ""
                  }`}
                  onClick={() => {
                    setCurrentPlaceHolder(item.placeHolder);
                    setSearchTag(item.key);
                  }}
                >
                  {item.name}
                </span>
              ))}
          </div>
          <div className={styles.searchBox}>
            <div className={styles.searchCmp}>
              <Input
                ref={inputRef}
                className={styles.searchInput}
                placeholder={currentPlaceHolder}
                allowClear={{
                  clearIcon: (
                    <>
                      <img
                        src={InputClear}
                        alt=""
                        className={styles.closeImg}
                        onClick={() => {
                          setSearchStr("");
                        }}
                      />
                      <span className={styles.splitLine}></span>
                    </>
                  ),
                }}
                onPressEnter={() => {
                  if (inputRef.current) {
                    handleConfirm();
                  }
                }}
              />
              <span
                className={`${styles.searchBtn} icon-sousuo2 iconfont`}
                onClick={() => {
                  handleConfirm();
                  tj.track({
                    params: {
                      // 上报参数
                      event_id: "kgi_cells_and_genes_home_search_click",
                    },
                  });
                }}
              ></span>
              <span className={styles.split}>|</span>
            </div>
            <div className={styles.jumpChatBox}>
              <span
                className={styles.jumpChat}
                onClick={() => {
                  navigate(`/Chat`);
                  tj.track({
                    params: {
                      // 上报参数
                      event_id: "kgi_cells_and_genes_home_chat_click",
                    },
                  });
                }}
              >
                <span
                  className={`${styles.duihua} icon-duihua1 iconfont`}
                ></span>
                对话
              </span>
            </div>
          </div>
          {promptWord && (
            <div className={styles.promptBox}>
              {promptWord[searchTag] && (
                <span className={styles.promptLabel}>热词推荐：</span>
              )}

              <span className={styles.promptText}>
                {promptWord[searchTag] &&
                  promptWord[searchTag].map((v: string, index: number) => (
                    <>
                      <span
                        onClick={() => {
                          tj.track({
                            params: {
                              // 上报参数
                              event_id:
                                "kgi_cells_and_genes_home_hotword_click",
                            },
                          });
                          navigate(
                            `/SearchTabs?tag=${searchTag}&search=${v.trim()}`
                          );
                        }}
                      >
                        {v}
                      </span>
                      {index < promptWord[searchTag].length - 1 && (
                        <span>，</span>
                      )}
                    </>
                  ))}
              </span>
            </div>
          )}
        </div>
        <NewsPart />
        <img
          src={downGif}
          alt=""
          className={styles.downGif}
          onClick={() => {
            handleClick(2);
          }}
        />

        {/* <div className={styles.stats}>
          {menuList.map((item) => (
            <span
              className={`${styles.stat} `}
              onClick={() => handleJump(item)}
            >
              <div className={`${styles.statLeft} `}>
                <span
                  className={`${styles.statsIcon} ${item.icon} iconfont`}
                ></span>
              </div>

              <div className={`${styles.statRight} `}>
                <span className={`${styles.label} `}>{item.name}实体：</span>
                <span className={`${styles.count} `}>
                  {!hasAnimated ? (
                    formatNumber(item.count)
                  ) : (
                    <AnimatedNumber end={item.count} />
                  )}
                </span>
              </div>
            </span>
          ))}

          <p className={styles.quickJump} onClick={() => handleJump()}>
            查看数据库
            <span className="icon-right iconfont"></span>
          </p>
        </div> */}
      </div>
      <video
        playsInline
        data-keepplaying
        autoPlay
        muted
        loop
        className={styles["background-video"]}
      >
        <source src={bgMov} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
}

export default Chat;
