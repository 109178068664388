import react, { useState, useEffect, useContext } from "react";
import { getChatLike, getChatDislike } from "src/services/api";
import { Input, Button, message } from "antd";
import styles from "./index.less";
interface IFeedBackModal {
  setFeedBackWinVisiable: any;
  sessionID: string;
  currentIndex: number;
  onSuccess?: any;
}
const { TextArea } = Input;
const list: any[] = ["有害不安全", "信息虚假", "没有帮助", "其他"];
function FeedBackModal(props: IFeedBackModal) {
  const { setFeedBackWinVisiable, sessionID, currentIndex, onSuccess } = props;
  const [selectTag, setSelectTag] = useState<any>("");
  const [loading, setLoading] = useState<any>(false);
  const [inputVal, setInputVal] = useState<any>("");
  const onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInputVal(e.target.value.trim());
  };
  const handleConfirm = async () => {
    try {
      setLoading(true);
      let res: any = await getChatDislike({
        feedback_tag: selectTag,
        feedback_content: inputVal.trim(),
        sess_id: sessionID,
        seq_no: Math.floor(currentIndex / 2),
      });

      setLoading(false);

      if (res.code === 0) {
        message.success("操作成功");
        setFeedBackWinVisiable(false);

        onSuccess(currentIndex);
      } else {
        message.error("系统错误，请稍后重试");
      }
    } catch (e: any) {
      setLoading(false);

      console.log(e);
      if (e.code === "ECONNABORTED") {
        message.error("接口超时，请刷新重试", 5);
      } else {
        message.error("系统错误，请稍后重试");
      }
    }
  };
  return (
    <div className={styles.FeedBackModal}>
      <div className={styles.content}>
        <p className={styles.title}>反馈</p>
        <span
          className={`${styles.closeIcon} iconfont icon-close`}
          onClick={() => {
            setFeedBackWinVisiable(false);
          }}
        ></span>
        <div className={styles.tag}>
          {list.map((item: any) => (
            <p
              key={item}
              className={`${styles.text} ${
                selectTag === item && styles.textActive
              }`}
              onClick={() => {
                setSelectTag(item);
              }}
            >
              {item}
            </p>
          ))}
        </div>
        <TextArea
          showCount
          className={styles.textArea}
          maxLength={1000}
          style={{ height: 140, resize: "none" }}
          onChange={onChange}
          placeholder="我们想知道你对此答案感到不满意的原因，你也可以提供一个更好的答案来帮助我们改进。"
        />

        <Button
          loading={loading}
          type="primary"
          onClick={() => {
            if (!(!selectTag || loading)) {
              handleConfirm();
            }
          }}
          className={`${styles.btn} ${
            (!selectTag || loading) && styles.btnDisable
          }`}
        >
          提交
        </Button>
      </div>
    </div>
  );
}

export default FeedBackModal;
