import { PortalLoginModal } from "@itic-sci/iticui";
import AuthApi from "src/services/auth/index";
import { encrypt } from "src/services/auth/crypto";
import { message } from "antd";
import "@itic-sci/iticui/es/portal-login-modal/style/index.css";
import React, { useState } from "react";
import styles from "./index.less";
interface ILogin {
  setLoginVisiable?: any;
  setUserInfo?: any;
  showLogin?: boolean;
}

export default (props: ILogin) => {
  const { setLoginVisiable, setUserInfo, showLogin } = props;
  const [isLogin, setIsLogin] = useState(false);
  const handleLogin = (data: any) => {
    console.log(data);
    setIsLogin(true);
    setUserInfo(data);
    message.success("登录成功");
    setLoginVisiable(false);
  };
  return (
    <PortalLoginModal
      isLoginModalOpen={showLogin}
      backUrl="https://kgdev-cg.itic-sci.com"
      onCancel={() => setLoginVisiable(false)}
      onLoginSuccess={handleLogin}
      env="prod"
    />
  );
};
