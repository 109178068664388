import react, { useState, useRef, useEffect } from "react";
import styles from "./index.less";
import defaultIndustry from "../../../image/defaultIndustry.svg";
import Chart from "./Chart";
import { getIndustryList } from "../../../services/api";
import _ from "lodash";
import { IListData } from "src/pages/Industry";
interface IOrgWin {
  currentClickData?: any;
}

function OrgWin(props: IOrgWin) {
  const { currentClickData } = props;

  const [listData, setListData] = useState<Array<IListData>>([]);
  const listRef = useRef<HTMLDivElement>(null);
  const currentPageRef = useRef(1);
  const listDataRef = useRef([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [status, setStatus] = useState<"expand" | "collapse">("expand");

  const getListData = async (params: any) => {
    let res: any = await getIndustryList({
      node_name: params.name_zh || "细胞与基因",
      node_layer: params.layer,
      size: 24,
      page: currentPage,
      is_sz: 0,
    });
    if (res.code === 0) {
      let temp = res.data.orgs;
      if (currentPage > 1 && listDataRef.current) {
        temp = [...listDataRef.current, ...temp];
      }
      setListData(temp);
      listDataRef.current = temp;
    }
  };

  useEffect(() => {
    if (listRef.current) {
      listRef.current.scrollTop = 0;
    }
    listDataRef.current = [];
    currentPageRef.current = 1;
    setCurrentPage(1);
  }, [JSON.stringify(currentClickData)]);

  useEffect(() => {
    getListData(currentClickData);
  }, [JSON.stringify(currentClickData), currentPage]);
  return (
    <div className={styles.OrgWin}>
      <img
        className={styles.listImg}
        onClick={() =>
          window.open(
            `/graph_detail?id=${currentClickData.node_id}&node_type=org`,
            "_blank"
          )
        }
        src={currentClickData.image || ""}
        alt=""
        onError={({ currentTarget }) => {
          currentTarget.onerror = null; // prevents looping
          currentTarget.src = defaultIndustry;
        }}
      />
      <p
        className={styles.listName + " text-two-ellipsis"}
        title={currentClickData.name_zh || currentClickData.name}
        onClick={() =>
          window.open(
            `/graph_detail?id=${currentClickData.node_id}&node_type=org`,
            "_blank"
          )
        }
      >
        {currentClickData.name_zh || currentClickData.name}
      </p>

      <div className={styles.countPart}>
        <div className={styles.countItem}>
          <p className={styles.count}>{currentClickData.citation}</p>
          <p className={styles.countType}>引用关系</p>
        </div>
        <div className={styles.countItem}>
          <p className={styles.count}>
            {currentClickData.papers === 10000
              ? "10000+"
              : currentClickData.papers}
          </p>
          <p className={styles.countType}>论文成果</p>
        </div>
      </div>

      <span className={styles.title}>历年发表和引用情况</span>
      <Chart id={currentClickData.id} />
    </div>
  );
}

export default OrgWin;
