import classnames from "classnames";
import { message, Avatar, Dropdown, Menu, Popover } from "antd";
import react, { useState, useEffect } from "react";
import AuthApi from "src/services/auth/index";
import { useNavigate, Link, useLocation } from "react-router-dom";
import UserImg from "src/image/gkx/default_head_img.png";
import styles from "./index.less";
import { useSharedState, defaultUserInfo } from "src/UserContext";
import gkxlogoBlack from "src/image/gkx/gkxLogoCircle.svg";
import tj from "@itic-sci/teamjob";

export const keyMap = {
  paper: {
    color: "#F7BA1E",
    name: "论文",
  },
  person: {
    color: "#3BA4F0",
    name: "学者",
  },
  org: {
    color: "#FF6565",
    name: "机构",
  },
  field: {
    color: "#5ED05C",
    name: "领域",
  },
};
const menu = [
  {
    icon: "icon-sousuo1",
    name: "首页",
    path: "ChatSearch",
    tarck: "kgi_cells_and_genes_navi_home_click",
  },
  {
    icon: "icon-duihua",
    name: "对话",
    path: "Chat",
    tarck: "kgi_cells_and_genes_navi_chat_click",
  },
  {
    icon: "icon-shujuku",
    name: "数据库",
    path: "Screen",
    tarck: "kgi_cells_and_genes_navi_db_click",
  },
];

const Header = (props) => {
  const { currentNode } = props;
  const navigate = useNavigate();
  const [userState, setUserState, showLogin, setShowLogin] = useSharedState();

  const logout = async () => {
    let res = await AuthApi.logout();
    if (res.code === 0) {
      setUserState(defaultUserInfo);
    } else if (res?.message) {
      message.error(res?.message);
    }
  };
  const content = userState.account ? (
    <div className={`${styles.dropdown}`}>
      <div className={styles.accountInfo}>
        <div
          onClick={() => {
            window.open(
              "https://my.itic-sci.com/accountInfo/basicInfo",
              "_blank"
            );
          }}
        >
          <img src={userState.head_url || UserImg} className={styles.avatar} />
          {userState.account}
        </div>
      </div>
      <div className={styles.item}>
        <div
          onClick={() => {
            window.open(
              "https://my.itic-sci.com/accountInfo/basicInfo",
              "_blank"
            );
          }}
        >
          <span className={`${styles.icon} icon-people iconfont`}></span>
          个人中心
        </div>
      </div>
      <div className={styles.item} onClick={logout}>
        <span className={`${styles.icon} icon-tuichudenglu iconfont`}></span>
        退出登录
      </div>
    </div>
  ) : (
    <div className={styles.loginBtn}>
      <p
        onClick={() => !userState.account && setShowLogin(true)}
        className={styles.item}
      >
        去登录
      </p>
    </div>
  );
  return (
    <>
      {currentNode && (
        <div className={styles.header}>
          <div className={styles.leftPart}>
            <img
              className={styles.logo}
              src={gkxlogoBlack}
              alt=""
              onClick={() => {
                window.open("https://itic-sci.com/", "_blank");
              }}
            />
            <span className={`${styles.name}  text-ellipsis`}>
              {currentNode.name_zh || currentNode.name}
            </span>
            <span
              className={styles.tag}
              style={{ background: keyMap[currentNode.node_type]?.color }}
            >
              {keyMap[currentNode.node_type]?.name}
            </span>
          </div>

          <div className={styles.tab}>
            {menu.map((item) => (
              <div
                key={item.name}
                className={styles.tabItem}
                onClick={() => {
                  tj.track({
                    params: {
                      // 上报参数
                      event_id: item.tarck,
                    },
                  });
                  if (location.pathname !== `/${item.path}`) {
                    if (item.path !== "Screen") {
                      if (location.pathname === `/Screen`) {
                        navigate(`/${item.path}`);
                      } else {
                        navigate(`/${item.path}`);
                      }
                    } else {
                      navigate(`/${item.path}`);
                    }
                  }
                }}
              >
                <span
                  className={`${styles.tabItemIcon} iconfont ${item.icon}`}
                ></span>
                <span className={styles.tabItemName}>{item.name}</span>
              </div>
            ))}
          </div>
          {!userState.account && (
            <div
              className={`${styles.unLoginBox} `}
              onClick={() => {
                tj.track({
                  params: {
                    // 上报参数
                    event_id: "kgi_cells_and_genes_navi_login_click",
                  },
                });
                !userState.account && setShowLogin(true);
              }}
            >
              <span className={`${styles.statusText}`}>登录/注册</span>
            </div>
          )}
          {userState.account && (
            <Popover
              content={content}
              overlayClassName={styles.headerLogin}
              placement={!userState.account ? "bottomLeft" : "bottomRight"}
              arrow={false}
            >
              {userState.account && (
                <div className={`${styles.loginBox} `}>
                  <img
                    src={userState.head_url || UserImg}
                    className={styles.avatar}
                  />

                  <span className={`${styles.statusText}`}>已登录</span>
                </div>
              )}
            </Popover>
          )}
        </div>
      )}
    </>
  );
};

export default Header;
