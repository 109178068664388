import React from "react";
import styled from "styled-components";

const StyledDiv = styled.div`
  background-color: #ffffff;
  border-radius: 8px;
  height: 449px;
  left: 340px;
  position: absolute;
  top: 234px;
  width: 920px;

  & .p-2 {
    color: transparent;
    font-family: "PingFang SC-Medium", Helvetica;
    font-size: 14px;
    font-weight: 400;
    left: 68px;
    letter-spacing: 0;
    line-height: 19.6px;
    position: absolute;
    top: 80px;
    width: 832px;
  }

  & .text-wrapper-13 {
    color: #316efc;
    font-weight: 500;
  }

  & .text-wrapper-14 {
    color: #333333;
    font-family: "PingFang SC-Regular", Helvetica;
  }

  & .element-26 {
    height: 30px;
    left: 20px;
    position: absolute;
    top: 40px;
    width: 127px;
  }

  & .text-wrapper-15 {
    color: #316efc;
    font-family: "PingFang SC-Regular", Helvetica;
    font-size: 20px;
    font-weight: 400;
    left: 47px;
    letter-spacing: 0;
    line-height: 28px;
    position: absolute;
    top: 1px;
    white-space: nowrap;
  }

  & .element-27 {
    height: 30px;
    left: 0;
    position: absolute;
    top: 0;
    width: 41px;
  }

  & .element-28 {
    height: 275px;
    left: 68px;
    position: absolute;
    top: 150px;
    width: 832px;
  }

  & .overlap-group-9 {
    background-color: #263b5f0d;
    height: 275px;
    margin-top: 10px;
    left: 1px;
    position: relative;
  }

  & .element-29 {
    height: 259px;
    left: 241px;
    object-fit: cover;
    position: absolute;
    top: 12px;
    width: 351px;
  }
`;

export const Div = () => {
  return (
    <StyledDiv id="login">
      <p className="p-2">
        <span className="text-wrapper-13">专题知识库</span>
        <span className="text-wrapper-14">
          是国际科技信息中心推出的首个集对话搜索和知识图谱于一体的智能搜索问答系统，帮助您快速提高检索效率，获取最新领域知识动态。我们以细胞与基因专题库为例，请您首先注册成为国际科技信息中心门户网站的用户，点击页面右上方的
        </span>
        <span className="text-wrapper-13">【登录/注册】</span>
        <span className="text-wrapper-14">完成登录；</span>
      </p>
      <div className="element-26">
        <div className="text-wrapper-15">账号登录</div>
        <img className="element-27" alt="Element" src="/assets/account/5.svg" />
      </div>
      <div className="element-28">
        <div className="overlap-group-9">
          <img
            className="element-29"
            alt="Element"
            src="/assets/account/1-2-2x.png"
          />
        </div>
      </div>
    </StyledDiv>
  );
};
