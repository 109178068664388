import React, {
  useEffect,
  createContext,
  useState,
  useContext,
  useMemo,
  useRef,
} from "react";
import logo from "./image/logo.svg";
import "./Screen.css";
import tableStyles from "./table.less";
import TopBar from "./component/TopBar/index";
import FloatMenu from "./component/FloatMenu/index";
import LeftTree from "./component/LeftTree/index";
import OverView from "./pages/OverView";
import Industry from "./pages/Industry";
import Company from "./pages/Company";
import Patent from "./pages/Patent";
import { useNavigate, Link, useLocation } from "react-router-dom";
import Header from "src/ChatPages/Header";
import tj from "@itic-sci/teamjob";

import Scholar from "./pages/Scholar";
import Paper from "./pages/Paper";
import { setHtmlFontSize } from "./util";
import LoadingSrc from "./image/loading.svg";
import { UserContext } from "./Context";
import { getTreeData, getNodeStats } from "./services/api";
import News from "./pages/News";

interface IleftCurrentClickData {
  id?: string;
}

function Screen() {
  const OverViewRef = useRef<any>(null);
  const [list, setList] = useState<Array<any>>([]);
  const [stats, setStats] = useState<any>([]);
  const navigate = useNavigate();

  const [pageType, setPageType] = useState<string>("");
  const [isloading, setIsLoading] = useState<boolean>(false);
  const value: any = useMemo(() => ({ isloading, setIsLoading }), [isloading]);
  // 左侧栏点击的数据
  const [leftCurrentClickData, setLeftCurrentClickData] =
    useState<IleftCurrentClickData>({});

  const [leftTreeCurrentClickData, setLeftTreeCurrentClickData] = useState<any>(
    {}
  );

  const queryData = async () => {
    let templateKey = localStorage.getItem("templateKey");
    if (!(templateKey && templateKey.length)) {
      setPageType("overview");
    }
    let res: any = await getTreeData();
    if (res.code === 0) {
      setList(formatTreeData([res.data.graph]));
    }
    let states: any = await getNodeStats();
    if (states.code === 0) {
      setStats(states.data.stats);

      if (templateKey && templateKey.length) {
        setPageType(templateKey);
        localStorage.removeItem("templateKey");
      }
    }
  };

  const formatTreeData = (rootList: any) => {
    let children = [];
    for (let i = 0; i < rootList.length; i++) {
      let temp: any = {
        title: rootList[i].name_zh || rootList[i].name,
        label: rootList[i].name_zh || rootList[i].name,
        key: rootList[i].node_id,
        value: rootList[i].node_id,
        ...rootList[i],
      };
      if (rootList[i].children && rootList[i].children.length) {
        temp.isLeaf = false;
        let child = formatTreeData(rootList[i].children);
        if (child && child.length) {
          temp.children = formatTreeData(rootList[i].children);
        }
      } else {
        temp.isLeaf = true;
      }
      children.push(temp);
    }
    return children;
  };

  useEffect(() => {
    queryData();
  }, []);

  // 大屏自适应
  const handleScreenAuto = () => {
    setHtmlFontSize();
    if (pageType === "overview" && OverViewRef.current.handleScreenAuto) {
      OverViewRef.current.handleScreenAuto();
    }
  };
  useEffect(() => {
    handleScreenAuto();
    // 监听
    window.addEventListener("resize", handleScreenAuto);
    return () => {
      window.removeEventListener("resize", handleScreenAuto);
    };
  });

  useEffect(() => {
    let html: any = document.getElementsByTagName("html")[0];
    try {
      let parent: any = document.querySelector(".screen-wrapper");
      if (parent) {
        parent.parentNode.style.position = "initial";
        parent.parentNode.style.height = "initial";
      }
    } catch (error) {}
    html.classList.add("screenBg");
    tj.track({
      params: {
        // 上报参数
        event_id: "kgi_cells_and_genes_db_enter",
      },
    });
    // 销毁
    return () => {
      html.classList.remove("screenBg");
    };
  }, []);

  return (
    <UserContext.Provider value={value}>
      <div className="screen-wrapper">
        <div className={`screen ${tableStyles.table}`} id="screen">
          {isloading && (
            <div className="loading-ct">
              <img src={LoadingSrc} className="loading" alt="" />
            </div>
          )}
          {/* <div className="title-part"></div>
          <div
            className="logo-part"
            onClick={() => window.open("https://itic-sci.com/")}
          ></div> */}
          {/* <FloatMenu /> */}

          <TopBar
            pageType={pageType}
            setPageType={setPageType}
            data={list}
            setCurrentClickData={(params: IleftCurrentClickData) => {
              setLeftTreeCurrentClickData(params);
              if (pageType === "overview" && OverViewRef.current.clickNode) {
                OverViewRef.current.clickNode(params);
              }
            }}
          />
          <div
            style={
              ["overview", "news", "company"].includes(pageType)
                ? { display: "none" }
                : {}
            }
          >
            <LeftTree
              data={list}
              stats={stats}
              pageType={pageType}
              leftCurrentClickData={leftTreeCurrentClickData}
              setCurrentClickData={(params: IleftCurrentClickData) => {
                setLeftCurrentClickData(params);
                // if (pageType === "overview" && OverViewRef.current.clickNode) {
                //   OverViewRef.current.clickNode(params);
                // }
              }}
            />
          </div>

          <div style={pageType === "overview" ? {} : { display: "none" }}>
            <OverView
              ref={OverViewRef}
              treedata={list}
              setLeftCurrentClickData={setLeftTreeCurrentClickData}
              leftCurrentClickData={leftTreeCurrentClickData}
            />
          </div>
          {/* {pageType === "industry" && (
            <Industry leftCurrentClickData={leftCurrentClickData} />
          )}
          {pageType === "scholar" && (
            <Scholar leftCurrentClickData={leftCurrentClickData} />
          )}
          {pageType === "paper" && (
            <Paper leftCurrentClickData={leftCurrentClickData} />
          )}
          {pageType === "patent" && (
            <Patent leftCurrentClickData={leftCurrentClickData} />
          )}
          {pageType === "company" && <Company stats={stats} />} */}
          <div style={pageType === "industry" ? {} : { display: "none" }}>
            <Industry leftCurrentClickData={leftCurrentClickData} />
          </div>

          <div style={pageType === "scholar" ? {} : { display: "none" }}>
            <Scholar
              leftCurrentClickData={leftCurrentClickData}
              pageType={pageType}
            />
          </div>

          <div style={pageType === "paper" ? {} : { display: "none" }}>
            <Paper
              leftCurrentClickData={leftCurrentClickData}
              pageType={pageType}
            />
          </div>

          <div style={pageType === "patent" ? {} : { display: "none" }}>
            <Patent leftCurrentClickData={leftCurrentClickData} />
          </div>
          <div style={pageType === "company" ? {} : { display: "none" }}>
            <Company stats={stats} />
          </div>
          {/* <div style={pageType === "news" ? {} : { display: "none" }}>
            <News />
          </div> */}

          <div className="scroll-ct"></div>
          {pageType === "overview" && <div className="shadow-ct"></div>}
        </div>
      </div>
    </UserContext.Provider>
  );
}

export default Screen;
