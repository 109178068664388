import react, { useState, useEffect } from "react";
import styles from "./index.less";

interface ICommonTitle {
  title: string;
  className: any;
}

function CommonTitle(props: ICommonTitle) {
  const { title, className } = props;

  return (
    <div className={`${styles.CommonTitle} ${className}`}>
      <span className={styles.title}>{title}</span>
      <span className={styles.bg}></span>
    </div>
  );
}

export default CommonTitle;
