import react, { useState, useEffect } from "react";
import styles from "./index.less";

function DetailModal(props) {
  const { data, setVisiable } = props;

  const handleClose = () => {
    setVisiable(false);
  };
  const companyDetails = [
    { header: "公司名称", text: data.org_name },
    { header: "法定代表人", text: data.legal_represent },

    { header: "经营状态", text: data.org_status },
    { header: "成立日期", text: data.establish_date },

    { header: "注册资本", text: data.registered_capital },
    { header: "实缴资本", text: data.paid_capital },

    { header: "统一社会信用代码", text: data.usc_code },
    { header: "纳税人识别号", text: data.tax_no },

    { header: "工商注册号", text: data.business_registration_number },
    { header: "组织机构代码", text: data.org_no },

    { header: "营业期限", text: data.business_term },
    { header: "年审时间", text: data.approval_date },

    { header: "企业类型", text: data.org_type },
    { header: "所属行业", text: data.industry },

    { header: "参保人数", text: data.insured_num },
    { header: "网址", text: data.website, type: "link" },
  ];

  return (
    <div className={`${styles.DetailModal}`} onClick={() => handleClose()}>
      <div className={styles.content}>
        <div className={styles.topBar}>
          <span className={styles.title}>企业详情</span>
          <span
            className={`icon-close iconfont ${styles.icon}`}
            onClick={() => handleClose()}
          ></span>
        </div>
        <div className={styles.mainBody}>
          <p className={styles.name}>{data.org_name}</p>

          <div className={styles.itemList}>
            {companyDetails.map((item, index) => (
              <div key={index} className={styles.item}>
                <div className={styles.label}>{item.header}</div>
                {item.type === "link" && item.text && (
                  <div
                    className={`${styles.text} ${styles.textLink}`}
                    onClick={() => window.open(item.text, "_blank")}
                  >
                    {item.text || "--"}
                  </div>
                )}
                {!(item.type === "link" && item.text) && (
                  <div className={styles.text}>{item.text || "--"}</div>
                )}
              </div>
            ))}
            <div className={styles.itemFull}>
              <div className={styles.label}>注册地址</div>
              <div className={styles.text}>{data.address || "--"}</div>
            </div>
            <div className={styles.itemFullFixed}>
              <div className={styles.label}>经营范围</div>
              <div className={styles.text}>{data.main_business || "--"}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DetailModal;
