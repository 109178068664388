import react, {
  useState,
  useRef,
  useEffect,
  useImperativeHandle,
  forwardRef,
} from "react";
import SearchPart from "src/component/SearchPart/index";
import CenterGraph from "src/component/CenterGraph/index";
import IndustryPart from "src/component/IndustryPart/index";
import PersonDetailPart from "src/component/PersonDetailPart/index";
import OrgDetailPart from "src/component/OrgDetailPart/index";
import ScholarPart from "src/component/ScholarPart/index";
import FieldDetailPart from "src/component/FieldDetailPart/index";
import styles from "./index.less";
import ZrenderPart from "src/component/ZrenderPart/index";
import { getSubGraphData } from "src/services/api";
import tj from "@itic-sci/teamjob";

interface IleftCurrentClickData {
  id?: string;
}
interface IOverView {
  leftCurrentClickData: IleftCurrentClickData;
  setLeftCurrentClickData: any;
  treedata: any;
}

const nodeMap: any = [
  {
    name: "领域",
    key: "industry",
  },
  {
    name: "学者",
    key: "scholar",
  },
  {
    name: "机构",
    key: "org",
  },
];

function OverView(props: IOverView, ref: any) {
  const { leftCurrentClickData, setLeftCurrentClickData, treedata } = props;

  const [scale, setScale] = useState(1);

  // zrender高亮的数据
  const [currentClickData, setCurrentClickData] = useState<any>({
    layer: 0,
    node_id: 1,
    name_zh: "细胞与基因",
    node_type: "field",
  });
  const GraphCmpRef = useRef<any>(null);
  const GraphRef = useRef<any>(null);

  useImperativeHandle(ref, () => ({
    clickNode: (params: any) => {
      GraphCmpRef.current.clickNode(params.id);
    },
    handleScreenAuto,
  }));

  // 大屏自适应
  const handleScreenAuto = () => {
    if (GraphRef.current) {
      GraphCmpRef.current.handleScreenAuto();
    }
  };
  const getGraphById = (id: string, type: string) => {
    let data;
    if (GraphRef.current) {
      let res = GraphRef.current.findById(id);
      let graph = (
        document.querySelector("#graph") as any
      ).getBoundingClientRect();
      if (res && graph) {
        let flag = GraphRef.current.getClientByPoint(
          res.getBBox().centerX,
          res.getBBox().centerY
        );

        data = {
          [`${type}Id`]: id,
          [`${type}X`]: flag.x - graph.left + 10 * scale, // graph 横向的偏移量
          [`${type}Y`]: flag.y - graph.top + 20, // graph 纵向的偏移量
        };
      }
    }
    return data;
  };

  const getDomById = (id: string, type: string) => {
    let data;
    if (typeof id === "number") return false;
    let target = document.querySelector(`[data-id=${id.toString()}]`);
    let graph = (
      document.querySelector("#graph") as any
    ).getBoundingClientRect();
    if (target && graph) {
      let res = target.getBoundingClientRect();

      if (res) {
        let offsetX = res.x + res.width / 2;
        let offsetY = res.y;
        data = {
          [`${type}Id`]: id,
          [`${type}X`]: offsetX - graph.left,
          [`${type}Y`]: offsetY - graph.top + 24,
        };
      }
    }
    return data;
  };

  const getNodePos = (id: string, type: string) => {
    return getGraphById(id, type) || getDomById(id, type);
  };
  useEffect(() => {
    if (leftCurrentClickData.id) {
      setCurrentClickData(leftCurrentClickData);
    }
  }, [JSON.stringify(leftCurrentClickData)]);

  // useEffect(() => {
  //   queryGraphData(leftCurrentClickData);
  // }, [JSON.stringify(leftCurrentClickData)]);
  return (
    <div className={styles.container}>
      {/* <SearchPart
        searchTag={tagMap}
        searchStr={searchStr}
        setSearchStr={setSearchStr}
      /> */}

      <CenterGraph
        scale={scale}
        // linksData={linksData}
        setCurrentClickData={(data: any) => {
          console.log(data);
          if (currentClickData.node_id !== data.node_id) {
            setCurrentClickData(data);
            setLeftCurrentClickData(data);
          }
        }}
        currentClickData={currentClickData}
        GraphRef={GraphRef}
        ref={GraphCmpRef}
      />
      {currentClickData.node_type === "field" && (
        <>
          <div className={styles.filedRightPart}>
            <IndustryPart currentClickData={currentClickData} />
            <ScholarPart currentClickData={currentClickData} />
          </div>

          <FieldDetailPart
            currentClickData={currentClickData}
            treedata={treedata}
            GraphCmpRef={GraphCmpRef}
            setCurrentClickData={(data: any) => {
              let obj = { ...data, id: data.node_id };
              console.log(data);

              setCurrentClickData(obj);
              setLeftCurrentClickData(obj);
            }}
          />
        </>
      )}

      {currentClickData.node_type === "person" && (
        <PersonDetailPart currentClickData={currentClickData} />
      )}

      {currentClickData.node_type === "org" && (
        <OrgDetailPart currentClickData={currentClickData} />
      )}

      <div className={styles.legend}>
        {nodeMap.map((item: any) => (
          <span className={styles[`${item.key}_label`]}>{item.name}</span>
        ))}
      </div>
      {/* <ZrenderPart linksData={linksData} /> */}
    </div>
  );
}

export default forwardRef(OverView);
