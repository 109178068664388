import React from "react";
import styled from "styled-components";

const StyledFrameWrapper = styled.div`
  background-color: #ffffff;
  border-radius: 8px;
  height: 963px;
  left: 340px;
  position: absolute;
  top: 703px;
  width: 920px;

  & .text-wrapper-3 {
    color: #333333;
    font-family: "PingFang SC-Regular", Helvetica;
    font-size: 14px;
    font-weight: 400;
    left: 68px;
    letter-spacing: 0;
    line-height: 19.6px;
    position: absolute;
    top: 89px;
    width: 832px;
  }

  & .p {
    color: transparent;
    font-family: "PingFang SC-Regular", Helvetica;
    font-size: 14px;
    font-weight: 400;
    left: 68px;
    letter-spacing: 0;
    line-height: 19.6px;
    position: absolute;
    top: 496px;
    width: 832px;
  }

  & .span {
    color: #333333;
  }

  & .text-wrapper-4 {
    color: #316efc;
    font-family: "PingFang SC-Medium", Helvetica;
    font-weight: 500;
  }

  & .element {
    height: 30px;
    left: 21px;
    position: absolute;
    top: 40px;
    width: 127px;
  }

  & .text-wrapper-5 {
    color: #316efc;
    font-family: "PingFang SC-Regular", Helvetica;
    font-size: 20px;
    font-weight: 400;
    left: 47px;
    letter-spacing: 0;
    line-height: 28px;
    position: absolute;
    top: 1px;
    white-space: nowrap;
  }

  & .overlap-group-wrapper {
    height: 30px;
    left: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 41px;
  }

  & .overlap-group-2 {
    height: 30px;
    left: -1px;
    position: relative;
    width: 42px;
  }

  & .img {
    height: 10px;
    left: 29px;
    position: absolute;
    top: 20px;
    width: 13px;
  }

  & .element-2 {
    height: 30px;
    left: 1px;
    position: absolute;
    top: 0;
    width: 32px;
  }

  & .text-wrapper-6 {
    color: #ffffff;
    font-family: "DINPro-Bold", Helvetica;
    font-size: 22px;
    font-weight: 700;
    left: 0;
    letter-spacing: 0;
    line-height: 26px;
    position: absolute;
    text-align: center;
    top: 1px;
    width: 34px;
  }

  & .element-3 {
    background-color: #263b5f08;
    height: 343px;
    left: 68px;
    position: absolute;
    top: 596px;
    width: 832px;
  }

  & .overlap-wrapper {
    height: 311px;
    left: 109px;
    position: relative;
    top: 16px;
    width: 618px;
  }

  & .overlap-2 {
    height: 327px;
    left: -8px;
    position: relative;
    top: -4px;
    width: 634px;
  }

  & .overlap-group-3 {
    height: 327px;
    left: 0;
    position: absolute;
    top: 0;
    width: 634px;
  }

  & .element-4 {
    height: 309px;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 492px;
  }

  & .element-5 {
    height: 255px;
    left: 452px;
    position: absolute;
    top: 72px;
    width: 182px;
  }

  & .image {
    height: 46px;
    left: 498px;
    position: absolute;
    top: 22px;
    width: 45px;
  }

  & .frame-2 {
    height: 323px;
    left: 68px;
    position: absolute;
    top: 150px;
    width: 832px;
  }

  & .overlap-3 {
    height: 323px;
    position: relative;
  }

  & .rectangle-wrapper {
    height: 323px;
    left: 0;
    position: absolute;
    top: 0;
    width: 832px;
  }

  & .rectangle {
    background-color: #263b5f0d;
    height: 323px;
    left: -2px;
    position: relative;
    width: 834px;
  }

  & .element-6 {
    height: 291px;
    left: 158px;
    object-fit: cover;
    position: absolute;
    top: 16px;
    width: 516px;
  }
`;

export const FrameWrapper = () => {
  return (
    <StyledFrameWrapper id="home">
      <div className="text-wrapper-3">
        在首页对话框输入关键字、论文标题、专家名、机构名称等进行搜索，点击查看检索内容详情和知识图谱，点击页面上方论文标题获取作者、机构、中英文摘要等详情信息；
      </div>
      <p className="p">
        <span className="span">点击</span>
        <span className="text-wrapper-4">【查看PDF】</span>
        <span className="span">获取原文PDF文档，点击</span>
        <span className="text-wrapper-4">【原文链接】</span>
        <span className="span">
          可跳转至期刊数据库原论文页面，获取更多信息；选中图谱中不同知识节点，点击
        </span>
        <span className="text-wrapper-4">【聚焦节点】</span>
        <span className="span">
          按钮，左侧自动切换展示详细信息；点击图谱右上角
        </span>
        <span className="text-wrapper-4">【对话】</span>
        <span className="span">
          按钮进行对话提问,Chatsearch小工具将在全库范围内为您寻找最贴切的答案（Chatsearch小工具与知识图谱独立运行）。选中生成答案中的蓝色字体内容，左侧自动切换展示该内容详细信息和关联图谱。
        </span>
      </p>
      <div className="element">
        <div className="text-wrapper-5">首页检索</div>
        <div className="overlap-group-wrapper">
          <div className="overlap-group-2">
            <img className="img" alt="Element" src="/assets/account/1-3.svg" />
            <img
              className="element-2"
              alt="Element"
              src="/assets/account/1-4.svg"
            />
            <div className="text-wrapper-6">02</div>
          </div>
        </div>
      </div>
      <div className="element-3">
        <div className="overlap-wrapper">
          <div className="overlap-2">
            <div className="overlap-group-3">
              <img
                className="element-4"
                alt="Element"
                src="/assets/account/1-1.png"
              />
              <img
                className="element-5"
                alt="Element"
                src="/assets/account/1.png"
              />
            </div>
            <img
              className="image"
              alt="Image"
              src="/assets/account/image-83.png"
            />
          </div>
        </div>
      </div>
      <div className="frame-2">
        <div className="overlap-3">
          <div className="rectangle-wrapper">
            <div className="rectangle" />
          </div>
          <img
            className="element-6"
            alt="Element"
            src="/assets/account/1-2.png"
          />
        </div>
      </div>
    </StyledFrameWrapper>
  );
};
