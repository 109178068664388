import react, { useState, useEffect } from "react";
import styles from "./index.less";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { useSharedState, defaultUserInfo } from "src/UserContext";
import { message, Avatar, Dropdown, Menu, Popover } from "antd";
import exitIcon from "src/image/gkx/exitIcon.svg";
import tj from "@itic-sci/teamjob";

import gkxLogoCircle from "src/image/gkx/gkxLogoCircle.svg";
import darkFont from "src/image/logo/darkFont.svg";
import lightFont from "src/image/logo/lightFont.svg";
import UserImg from "src/image/gkx/default_head_img.png";
import user from "src/image/gkx/user.png";
import AuthApi from "src/services/auth/index";

function Header(props: any) {
  const navigate = useNavigate();
  const lightList = ["/Chat", "/IntermediateOutPage", "/IntermediateInPage"];
  let theme = "light";

  const [userState, setUserState, showLogin, setShowLogin] = useSharedState();

  const menu = [
    {
      icon: "icon-sousuo1",
      name: "首页",
      path: "ChatSearch",
      tarck: "kgi_cells_and_genes_navi_home_click",
    },
    {
      icon: "icon-duihua",
      name: "对话",
      path: "Chat",
      tarck: "kgi_cells_and_genes_navi_chat_click",
    },
    {
      icon: "icon-shujuku",
      name: "数据库",
      path: "Screen",
      tarck: "kgi_cells_and_genes_navi_db_click",
    },
  ];

  const logout = async () => {
    let res: any = await AuthApi.logout();
    if (res.code === 0) {
      setUserState(defaultUserInfo);
    } else if (res?.message) {
      message.error(res?.message);
    }
  };

  const content = userState.account ? (
    <div className={`${styles.dropdown}`}>
      <div className={styles.accountInfo}>
        <div
          onClick={() => {
            window.open(
              "https://my.itic-sci.com/accountInfo/basicInfo",
              "_blank"
            );
          }}
        >
          <img src={userState.head_url || UserImg} className={styles.avatar} />
          {userState.account}
        </div>
      </div>
      <div className={styles.item}>
        <div
          onClick={() => {
            window.open(
              "https://my.itic-sci.com/accountInfo/basicInfo",
              "_blank"
            );
          }}
        >
          <span className={`${styles.icon} icon-people iconfont`}></span>
          个人中心
        </div>
      </div>
      <div className={styles.item} onClick={logout}>
        <span className={`${styles.icon} icon-tuichudenglu iconfont`}></span>
        退出登录
      </div>
    </div>
  ) : (
    <div className={styles.loginBtn}>
      <p
        onClick={() => !userState.account && setShowLogin(true)}
        className={styles.item}
      >
        去登录
      </p>
    </div>
  );
  return (
    <>
      <div
        className={`${styles[theme]} ${
          location.pathname === "/Screen" && styles.screen
        }`}
      >
        <div className={styles.logo}>
          {theme === "dark" && (
            <div className={`${styles.darkLogo}`}>
              <span className={styles.fullLogo}></span>
              <span
                className={styles.logoCircle}
                onClick={() => {
                  window.open("https://itic-sci.com/", "_blank");
                }}
              ></span>
            </div>
          )}
          {theme === "light" && (
            <div className={`${styles.lightLogo}`}>
              <span className={styles.fullLogo}></span>
              <span
                className={styles.logoCircle}
                onClick={() => {
                  window.open("https://itic-sci.com/", "_blank");
                }}
              ></span>
            </div>
          )}
        </div>

        <div className={styles.tab}>
          {menu.map((item) => (
            <div
              key={item.name}
              className={`${styles.tabItem} ${
                location.pathname === `/${item.path}` && styles["tabItemActive"]
              }`}
              onClick={() => {
                tj.track({
                  params: {
                    // 上报参数
                    event_id: item.tarck,
                  },
                });
                if (location.pathname !== `/${item.path}`) {
                  if (item.path !== "Screen") {
                    if (location.pathname === `/Screen`) {
                      navigate(`/${item.path}`);
                    } else {
                      navigate(`/${item.path}`);
                    }
                  } else {
                    navigate(`/${item.path}`);
                  }
                }
              }}
            >
              <span
                className={`${styles.tabItemIcon} iconfont ${item.icon}`}
              ></span>
              <span className={styles.tabItemName}>{item.name}</span>
            </div>
          ))}
        </div>
        <div
          className={styles.pageIcon}
          onClick={() => {
            tj.track({
              params: {
                // 上报参数
                event_id: "kgi_cells_and_genes_navi_manual_click",
              },
            });
            window.location.reload();
          }}
        >
          <span className="iconfont icon-shiyongshouce"></span>
          <span>使用手册</span>
        </div>
        {!userState.account && (
          <div
            className={`${styles.unLoginBox} `}
            onClick={() => {
              tj.track({
                params: {
                  // 上报参数
                  event_id: "kgi_cells_and_genes_navi_login_click",
                },
              });
              !userState.account && setShowLogin(true);
            }}
          >
            <span className={`${styles.statusText}`}>登录/注册</span>
          </div>
        )}
        {userState.account && (
          <Popover
            content={content}
            overlayClassName={styles.headerLogin}
            placement={!userState.account ? "bottomLeft" : "bottomRight"}
            arrow={false}
          >
            {userState.account && (
              <div className={`${styles.loginBox} `}>
                <img
                  src={userState.head_url || UserImg}
                  className={styles.avatar}
                />

                <span className={`${styles.statusText}`}>已登录</span>
              </div>
            )}
          </Popover>
        )}
      </div>
    </>
  );
}

export default Header;
