import react, { useState, useRef, useEffect } from "react";
import styles from "./index.less";
import defaultIndustry from "../../../image/chatGraph/defaultListOrg.png";
import Chart from "./Chart";
import ScholarList from "./ScholarList";
import loadingGif from "../../../image/chatGraph/orgloading.gif";
import tj from "@itic-sci/teamjob";

import _ from "lodash";
interface IOrgWin {
  currentClickData?: any;
}

function OrgWin(props: IOrgWin) {
  const { currentClickData } = props;
  const [loading, setLoading] = useState(false);
  return (
    <div
      className={styles.OrgWin}
      // style={{
      //   left: currentClickData.position.x + 40,
      //   top: currentClickData.position.y,
      // }}
    >
      <img
        className={styles.listImg}
        onClick={() => {
          tj.track({
            params: {
              // 上报参数
              event_id:
                "kgi_cells_and_genes_detail_institution_info_name_click",
            },
          });
          currentClickData.url && window.open(currentClickData.url);
        }}
        src={currentClickData.image || ""}
        alt=""
        onError={({ currentTarget }) => {
          currentTarget.onerror = null; // prevents looping
          currentTarget.src = defaultIndustry;
        }}
      />
      <p
        className={styles.listName + " text-two-ellipsis"}
        title={currentClickData.name_zh || currentClickData.name}
        onClick={() => {
          tj.track({
            params: {
              // 上报参数
              event_id:
                "kgi_cells_and_genes_detail_institution_info_name_click",
            },
          });
          currentClickData.url && window.open(currentClickData.url);
        }}
      >
        {currentClickData.name_zh || currentClickData.name || "--"}
      </p>

      <div className={styles.countPart}>
        <div className={styles.countItem}>
          <p className={styles.count}>{currentClickData.citation || "--"}</p>
          <p className={styles.countType}>引用关系</p>
        </div>
        <div className={styles.countItem}>
          <p className={styles.count}>
            {currentClickData.papers === 10000
              ? "10000+"
              : currentClickData.papers || "--"}
          </p>
          <p className={styles.countType}>论文成果</p>
        </div>
      </div>

      {/* <span className={styles.title}>历年发表和引用情况</span> */}
      <img
        src={loadingGif}
        style={{
          width: 300,
          marginTop: 40,
          display: loading ? "inline-block" : "none",
        }}
      />

      <div style={{ display: loading ? "none" : "block" }}>
        <Chart id={currentClickData.node_id} setLoading={setLoading} />
        <div className={styles.splitLine}></div>
        <ScholarList currentClickData={currentClickData} />
      </div>

      {/* <div className={styles.footer}>
        <div className={styles.footerBtn}>
          <span
            className={` iconfont icon-jujiaojiedian ${styles.icon}`}
          ></span>
          <span
            className={styles.btntext}
            onClick={() => {
              let host = window.location.origin;
              let href = `${host}/institution/graph_detail?id=${currentClickData.node_id}&node_type=${currentClickData.node_type}`;
              if (!window.location.href.includes("institution")) {
                href = `${host}/graph_detail?id=${currentClickData.node_id}&node_type=${currentClickData.node_type}`;
              }
              window.open(href, "_self");
            }}
          >
            聚焦节点
          </span>
        </div>
        <div className={styles.footerBtn}>
          <span
            className={`iconfont icon-chakanxiangqing  ${styles.icon}`}
          ></span>
          <span
            className={styles.btntext}
            onClick={() =>
              currentClickData.url && window.open(currentClickData.url)
            }
          >
            查看详情
          </span>
        </div>
      </div> */}
    </div>
  );
}

export default OrgWin;
