import react, { useState, useRef, useEffect } from "react";
import styles from "./index.less";
import CommonTitle from "../CommonTitle";
import defaultPeople from "../../image/defaultPeople.svg";
import { getScholarList } from "../../services/api";
import _ from "lodash";
import { IListData } from "src/pages/Scholar";
interface IScholarPart {
  currentClickData: any;
}

function ScholarPart(props: IScholarPart) {
  const { currentClickData } = props;
  const [listData, setListData] = useState<Array<IListData>>([]);
  const listRef = useRef<HTMLDivElement>(null);
  const currentPageRef = useRef(1);
  const listDataRef = useRef([]);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const [status, setStatus] = useState<"expand" | "collapse">("expand");

  const getListData = async (params: any) => {
    let res: any = await getScholarList({
      node_name: params.name_zh || "细胞与基因",
      node_layer: params.layer,
      size: 24,
      page: currentPage,
      sort_field: "ncitation",
    });
    if (res.code === 0) {
      let temp = res.data.scholars;
      if (currentPage > 1 && listDataRef.current) {
        temp = [...listDataRef.current, ...temp];
      }
      setListData(temp);
      listDataRef.current = temp;
    }
  };
  const handleScreenAuto = (e: any) => {
    let dom = listRef.current;
    if (dom) {
      //文档内容实际高度（包括超出视窗的溢出部分）
      let scrollHeight = Math.max(dom.scrollHeight, dom.scrollHeight);
      //滚动条滚动距离
      let scrollTop = e.target.scrollTop;
      //窗口可视范围高度
      let clientHeight = dom.clientHeight;
      if (clientHeight + scrollTop + 100 >= scrollHeight) {
        if (listDataRef.current.length === currentPageRef.current * 24) {
          currentPageRef.current += 1;

          setCurrentPage(currentPageRef.current);
        }
      }
    }
  };

  //React的生命周期 如果你是vue可以放到mountd或created中
  useEffect(() => {
    if (listRef.current) {
      listRef.current.addEventListener(
        "scroll",
        _.debounce(handleScreenAuto, 200),
        true
      );
      return () => {
        if (listRef.current) {
          listRef.current.removeEventListener(
            "scroll",
            _.debounce(handleScreenAuto, 200),
            true
          );
        }
      };
    }
  }, []);

  useEffect(() => {
    if (listRef.current) {
      listRef.current.scrollTop = 0;
    }
    listDataRef.current = [];
    currentPageRef.current = 1;
    setCurrentPage(1);
  }, [JSON.stringify(currentClickData)]);

  useEffect(() => {
    if (currentClickData.node_type === "field") {
      getListData(currentClickData);
    }
  }, [JSON.stringify(currentClickData), currentPage]);

  useEffect(() => {
    getListData(currentClickData);
  }, []);
  return (
    <>
      <div
        className={
          currentClickData.node_type === "field"
            ? styles.ScholarPart
            : styles.hide
        }
      >
        <CommonTitle title={"相关学者"} className={styles.title} />
        <span
          className={styles.closeBtn}
          onClick={() => setStatus("collapse")}
        ></span>
        <div className={styles.list} ref={listRef}>
          {Array.isArray(listData) &&
            listData.map((item: any) => (
              <div
                key={item.node_id}
                className={styles.listItem}
                data-id={item.node_id}
              >
                <img
                  className={styles.listImg}
                  src={item.avatar || ""}
                  alt=""
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = defaultPeople;
                  }}
                />

                <div className={styles.rightPart}>
                  <span
                    className={styles.listName + " text-ellipsis"}
                    title={item.scholar_nameZh || item.scholar_name}
                    onClick={() =>
                      window.open(
                        `/graph_detail?id=${item.aminer_id}&node_type=person`,
                        "_blank"
                      )
                    }
                  >
                    {item.scholar_nameZh || item.scholar_name}
                  </span>
                  <p className={styles.countDesc}>
                    H-index：{item.hindex}&nbsp; 论文数：
                    {item.n_papers === 10000 ? "10000+" : item.npubs}
                  </p>
                  <p className={styles.countDesc}>
                    引用数：{item.ncitation}&nbsp;
                  </p>
                </div>
              </div>
            ))}
        </div>
      </div>

      <div className={status === "collapse" ? styles.closeLayer : styles.hide}>
        <span
          className={styles.openBtn}
          onClick={() => setStatus("expand")}
        ></span>

        <span className={styles.openTitle}>相关学者</span>
      </div>
    </>
  );
}

export default ScholarPart;
