import React from "react";
import styled from "styled-components";
import { Div } from "./sections/Div";
import { DivWrapper } from "./sections/DivWrapper";
import { Frame } from "./sections/Frame/Frame";
import { FrameWrapper } from "./sections/FrameWrapper";
import { Overlap } from "./sections/Overlap";

const StyledScreen = styled.div`
  background-color: #ebf4ff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;

  & .div-6 {
    background-color: #ebf4ff;
    height: 3491px;
    position: relative;
    width: 1400px;
    top: -60px;
  }
`;

const Screen = () => {
  return (
    <StyledScreen>
      <div className="div-6">
        <Frame />
        <FrameWrapper />
        <DivWrapper />
        <Overlap />
        <Div />
      </div>
    </StyledScreen>
  );
};
export default Screen;
