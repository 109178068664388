import React, { useState } from "react";
import styles from "./index.less";

const Legend = (props) => {
  const { currentNode } = props;
  console.log(currentNode);
  return (
    <div
      className={`${styles.tooltip} `}
      style={{
        left: currentNode.position.x + 20,
        top: currentNode.position.y + 20,
      }}
    >
      {currentNode.name}
    </div>
  );
};

export default Legend;
