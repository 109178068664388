import React from "react";
import { Modal } from "antd";
import styles from "./index.less";

export default (props: any) => {
  const { visible, title, content, onCancel, onConfirm, confirmBtnText } =
    props;

  return (
    <Modal
      visible={visible}
      className={styles.modal}
      width={380}
      maskClosable={false}
      closeIcon={
        <div className={styles.iconCloseWrapper}>
          <svg className={"icon " + styles.iconClose} aria-hidden="true">
            <use xlinkHref="#icon-modal_close" />
          </svg>
        </div>
      }
      title={<div className={styles.title}>{title}</div>}
      onCancel={onCancel}
      cancelText="取消"
      okText={confirmBtnText || "确定"}
      onOk={onConfirm}
    >
      {content}
    </Modal>
  );
};
