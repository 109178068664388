import react, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
  useRef,
  useContext,
} from "react";
import _ from "lodash";
import styles from "./index.less";
import { useSharedState, defaultUserInfo } from "src/UserContext";
import SearchPart from "../../component/SearchPart/index";
import ConfirmModal from "src/component/ConfirmModal";
import InputClear from "../../image/inputClear.svg";
import moment from "moment";
import { getPatentList, getWarpLink } from "../../services/api";
import { UserContext } from "src/Context";
import { Pagination, message, Input, ConfigProvider } from "antd";
import zhCN from "antd/lib/locale/zh_CN";
import Empty from "src/image/ListEmpty.svg";

interface IListData {
  title: string;
  patent_id: string;
  titleZh: string;
  authors: any;
  abstractZh: string;
  abstract: string;
  fields: any;
  node_id: string;
  keywords: string;
  pub_date: string;
  patent_cited: string | number;
}
interface IPatent {
  leftCurrentClickData: any;
}

const tagMap = [
  {
    key: "Patent",
    label: "专利",
  },
];

function Patent(props: IPatent, ref: any) {
  const listRef = useRef<HTMLDivElement>(null);
  const currentPageRef = useRef(1);
  const { isloading, setIsLoading } = useContext(UserContext);
  const inputRef = useRef(null);
  const listDataRef = useRef([]);
  const [searchStr, setSearchStr] = useState<string>("");
  const { leftCurrentClickData } = props;
  const [sortType, setSortType] = useState<"time">("time");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [total, setTotal] = useState<number>(1);
  const [listData, setListData] = useState<Array<IListData>>([]);
  const [userState, setUserState, showLogin, setShowLogin] = useSharedState();

  const [msg, setMsg] = useState<any>();
  const [needBind, setNeedBind] = useState(false);
  useImperativeHandle(ref, () => ({}));
  const getListData = async (params: any) => {
    setIsLoading(true);
    let res: any = {};
    try {
      res = await getPatentList({
        node_name: params.name_zh,
        node_layer: params.layer,
        size: pageSize,
        sort_field: "pub_date",
        page: currentPage,
        keyword: searchStr.trim(),
      });
    } catch (error) {
      message.error("接口网络状态异常，请您稍后再试！");
      setIsLoading(false);
    }

    setIsLoading(false);

    if (res.code === 0) {
      let temp = res.data.patents;

      setListData(temp);
      setTotal(res.data.total);
      listDataRef.current = temp;
    }
  };

  const handleChange = (page: number, pageSize: number) => {
    setPageSize(pageSize);
    setCurrentPage(page);
  };
  useEffect(() => {
    if (listRef.current) {
      listRef.current.scrollTop = 0;
    }
    getListData(leftCurrentClickData);
  }, [currentPage, pageSize]);

  useEffect(() => {
    if (currentPage !== 1) {
      setCurrentPage(1);
    } else {
      if (listRef.current) {
        listRef.current.scrollTop = 0;
      }
      getListData(leftCurrentClickData);
    }
  }, [JSON.stringify(leftCurrentClickData), sortType, searchStr]);

  const handleJump = async (id: any) => {
    setIsLoading(true);
    let res: any = {};
    try {
      res = await getWarpLink({
        tag_type: 3,
        token: "",
        url: id,
      });
    } catch (error) {
      message.error("接口网络状态异常，请您稍后再试！");
      setIsLoading(false);
    }

    setIsLoading(false);

    if (res.code === 0) {
      window.open(res.url, "_blank");
      return;
    } else if (res.code === 2) {
      message.error(res.message);
    } else if (res.code === 10) {
      message.error("抱歉，您当前账号为非深圳注册用户，无下载权限");
    } else if (res.code === 22) {
      setShowLogin(true);
    } else if (res.code === 23) {
      setNeedBind(true);
      setMsg({
        title: "温馨提示",
        content:
          "此部分内容属于深圳市科技图书馆资源，按要求需办理图书馆读者证，您如需继续使用该部分资源，国际科技信息中心会将您的授权数据传给深圳市科技图书馆，完成读者证绑定。",
      });
    } else if (res.code === 408) {
      message.error("接口网络状态异常，请您稍后再试！");
    }
  };

  return (
    <>
      {/* <SearchPart
        searchTag={tagMap}
        searchStr={searchStr}
        setSearchStr={setSearchStr}
      /> */}
      <div className={styles.Patent}>
        <div className={styles.topBar}>
          <span className={styles.title}>细胞与基因</span>

          <div className={styles.sortCt}>
            <Input
              className={styles.searchBox}
              ref={inputRef}
              placeholder="请输入要搜索的内容"
              allowClear={{
                clearIcon: (
                  <img
                    src={InputClear}
                    alt=""
                    className={styles.closeImg}
                    onClick={() => {
                      setSearchStr("");
                    }}
                  />
                ),
              }}
              onPressEnter={() => {
                if (inputRef.current) {
                  setSearchStr((inputRef.current as any).input.value);
                }
              }}
              suffix={
                <span
                  className={`iconfont icon-sousuo ${styles.iconfont}`}
                  onClick={() => {
                    if (inputRef.current) {
                      setSearchStr((inputRef.current as any).input.value);
                    }
                  }}
                ></span>
              }
            />
          </div>
        </div>
        {Array.isArray(listData) && !!listData.length && (
          <>
            <div className={styles.listCt} ref={listRef} key={searchStr}>
              {listData.map((item: any, index) => (
                <div
                  key={Math.random()}
                  className={styles.listItem}
                  onClick={() => handleJump(item.patent_id)}
                >
                  <div className={styles.topInfo}>
                    <p
                      className={styles.topTitle + " text-two-ellipsis"}
                      // onClick={() => item.url && window.open(item.url)}
                      title={item.titleZh || item.title}
                    >
                      {item.titleZh || item.title}
                    </p>
                    {/* <LinkGraph /> */}
                  </div>

                  {Array.isArray(item.authors) && item.authors.length && (
                    <p
                      className={styles.authors + " text-two-ellipsis "}
                      title={item.authors
                        .map((item: any) => item.nameZh || item.name)
                        .join("，")}
                    >
                      {item.authors.map((item: any) => (
                        <strong
                          className={styles.authorItem}
                          onClick={() => {
                            item.aminer_id &&
                              window.open(
                                `https://brain.itic-sci.com/profile/${item.aminer_id}`
                              );
                          }}
                        >
                          {item.nameZh || item.name}
                        </strong>
                      ))}
                    </p>
                  )}

                  {!!(item.abstractZh || item.abstract) && (
                    <p
                      className={styles.content + " text-two-ellipsis "}
                      title={item.abstractZh || item.abstract}
                    >
                      {item.abstractZh || item.abstract}
                    </p>
                  )}

                  <div className={styles.bottomInfo}>
                    <div className={styles.tagList}>
                      {Array.isArray(item.mini_fields) &&
                        item.mini_fields.slice(0, 5).map((itemField: any) => (
                          <span className={styles.tag} key={itemField}>
                            {itemField || "-"}
                          </span>
                        ))}
                    </div>
                    <div className={styles.bottomCount}>
                      <div className={styles.bottomDetail}>
                        <span className={styles.label}>专利号：</span>
                        <span className={styles.count}>{item.patent_id}</span>
                      </div>
                      <div className={styles.bottomDetail}>
                        <span className={styles.label}>发表日期：</span>
                        <span className={styles.count}>
                          {moment(item.pub_date).format("YYYY-MM-DD")}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <div className={styles.footerBar}>
              <ConfigProvider locale={zhCN}>
                <Pagination
                  total={total}
                  showSizeChanger
                  pageSize={pageSize}
                  current={currentPage}
                  onChange={handleChange}
                  defaultCurrent={1}
                  className={styles.pagination}
                  showQuickJumper
                />
              </ConfigProvider>
              <div className={styles.totalBar}>共 {total} 个结果 </div>
            </div>
          </>
        )}
        {Array.isArray(listData) && listData.length === 0 && (
          <div className={styles.emptyPart}>
            <img src={Empty} alt="" />
            <p>找不到相关内容</p>
          </div>
        )}
      </div>

      <ConfirmModal
        visible={Boolean(msg) && needBind}
        title={msg?.title}
        content={msg?.content}
        confirmBtnText="立即绑卡"
        onCancel={() => {
          setNeedBind(false);
          setMsg(null);
        }}
        onConfirm={() => {
          if (needBind) {
            window.location.href = `https://itic-sci.com/certification?back_url=${encodeURIComponent(
              window.location.href
            )}`;
          }
        }}
      />
    </>
  );
}

export default forwardRef(Patent);
