import React from "react";
import styled from "styled-components";

const StyledDivWrapper = styled.div`
  background-color: #ffffff;
  border-radius: 8px;
  height: 469px;
  left: 340px;
  position: absolute;
  top: 3061px;
  width: 920px;

  & .div-2 {
    color: transparent;
    font-family: "PingFang SC-Regular", Helvetica;
    font-size: 14px;
    font-weight: 400;
    left: 68px;
    letter-spacing: 0;
    line-height: 19.6px;
    position: absolute;
    top: 89px;
    width: 832px;
  }

  & .text-wrapper-7 {
    color: #333333;
  }

  & .text-wrapper-8 {
    color: #316efc;
    font-family: "PingFang SC-Medium", Helvetica;
    font-weight: 500;
  }

  & .element-7 {
    height: 30px;
    left: 20px;
    position: absolute;
    top: 40px;
    width: 127px;
  }

  & .text-wrapper-9 {
    color: #316efc;
    font-family: "PingFang SC-Regular", Helvetica;
    font-size: 20px;
    font-weight: 400;
    left: 47px;
    letter-spacing: 0;
    line-height: 28px;
    position: absolute;
    top: 1px;
    white-space: nowrap;
  }

  & .element-8 {
    height: 30px;
    left: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 41px;
  }

  & .overlap-group-4 {
    height: 30px;
    left: -1px;
    position: relative;
    width: 42px;
  }

  & .element-9 {
    height: 10px;
    left: 29px;
    position: absolute;
    top: 20px;
    width: 13px;
  }

  & .element-10 {
    height: 30px;
    left: 1px;
    position: absolute;
    top: 0;
    width: 32px;
  }

  & .element-11 {
    color: #ffffff;
    font-family: "DINPro-Bold", Helvetica;
    font-size: 22px;
    font-weight: 700;
    left: 0;
    letter-spacing: 0;
    line-height: 26px;
    position: absolute;
    text-align: center;
    top: 1px;
    width: 34px;
  }

  & .element-wrapper {
    background-color: #263b5f08;
    height: 295px;
    left: 68px;
    position: absolute;
    top: 150px;
    width: 832px;
  }

  & .element-12 {
    height: 263px;
    left: 158px;
    object-fit: cover;
    position: absolute;
    top: 16px;
    width: 516px;
  }
`;

export const DivWrapper = () => {
  return (
    <StyledDivWrapper id="db">
      <p className="div-2">
        <span className="text-wrapper-7">首页点击</span>
        <span className="text-wrapper-8">【数据库】</span>
        <span className="text-wrapper-7">
          按钮，即可查看各维度完整数据库。您可通过点击页面上方的数据库标签查看不同类型数据库详情，并实现检索查询；也可通过单击选中、拖动等操作，查看、获取知识图谱中不同知识节点信息。
        </span>
      </p>
      <div className="element-7">
        <div className="text-wrapper-9">数据库</div>
        <div className="element-8">
          <div className="overlap-group-4">
            <img
              className="element-9"
              alt="Element"
              src="/assets/account/1-3.svg"
            />
            <img
              className="element-10"
              alt="Element"
              src="/assets/account/1-4.svg"
            />
            <div className="element-11">04</div>
          </div>
        </div>
      </div>
      <div className="element-wrapper">
        <img
          className="element-12"
          alt="Element"
          src="/assets/account/1-5.png"
        />
      </div>
    </StyledDivWrapper>
  );
};
