import react, { useState, useRef, useEffect } from "react";
import styles from "./index.less";
import PaperWin from "./PaperWin";
import PersonWin from "./PersonWin";
import _ from "lodash";
interface IPersonDetailPart {
  currentClickData: any;
}

function PersonDetailPart(props: IPersonDetailPart) {
  const { currentClickData } = props;
  useEffect(() => {}, [JSON.stringify(currentClickData)]);
  return (
    <>
      <div
        className={
          currentClickData.node_type === "person"
            ? styles.PersonDetailPart
            : styles.hide
        }
      >
        <PersonWin currentClickData={currentClickData} />
        <PaperWin currentClickData={currentClickData} />
      </div>
    </>
  );
}

export default PersonDetailPart;
