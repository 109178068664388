import react, { useState, useRef, useEffect } from "react";
import styles from "./index.less";
import defaultPeople from "../../../image/defaultPeople.svg";
import Chart from "./Chart";
import { getIndustryList, getNodeDetail } from "../../../services/api";

import _ from "lodash";
import { IListData } from "src/pages/Industry";
interface IPersonWin {
  currentClickData?: any;
}

function PersonWin(props: IPersonWin) {
  const { currentClickData } = props;
  const [listData, setListData] = useState<Array<IListData>>([]);
  const listRef = useRef<HTMLDivElement>(null);
  const currentPageRef = useRef(1);
  const [rawData, setRawData] = useState<any>({});
  const listDataRef = useRef([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const queryPersonDetail = async (params: any) => {
    let res: any = await getNodeDetail({
      data: [params.node_id],
      node_type: "person",
    });
    if (res.code === 0) {
      let temp = res.data.details || [];
      setRawData(temp[0]);
    }
  };

  const getListData = async (params: any) => {
    let res: any = await getIndustryList({
      node_name: params.name_zh || "细胞与基因",
      node_layer: params.layer,
      size: 24,
      page: currentPage,
      is_sz: 0,
    });
    if (res.code === 0) {
      let temp = res.data.orgs;
      if (currentPage > 1 && listDataRef.current) {
        temp = [...listDataRef.current, ...temp];
      }
      setListData(temp);
      listDataRef.current = temp;
    }
  };

  useEffect(() => {
    if (listRef.current) {
      listRef.current.scrollTop = 0;
    }
    listDataRef.current = [];
    currentPageRef.current = 1;
    setCurrentPage(1);
  }, [JSON.stringify(currentClickData)]);

  useEffect(() => {
    getListData(currentClickData);
  }, [JSON.stringify(currentClickData), currentPage]);
  useEffect(() => {
    queryPersonDetail(currentClickData);
  }, [JSON.stringify(currentClickData)]);
  return (
    <div className={styles.PersonWin}>
      <img
        className={styles.listImg}
        src={currentClickData.avatar || ""}
        alt=""
        onClick={() =>
          window.open(
            `/graph_detail?id=${currentClickData.id}&node_type=person`,
            "_blank"
          )
        }
        onError={({ currentTarget }) => {
          currentTarget.onerror = null; // prevents looping
          currentTarget.src = defaultPeople;
        }}
      />
      <p
        className={styles.listName}
        onClick={() =>
          window.open(
            `/graph_detail?id=${currentClickData.id}&node_type=person`,
            "_blank"
          )
        }
      >
        {currentClickData.name_zh || currentClickData.name}
      </p>

      {rawData && (
        <p
          className={styles.subName + " text-ellipsis"}
          title={rawData.org_nameZh || rawData.org_name}
        >
          {rawData.org_nameZh || rawData.org_name}
        </p>
      )}

      <div className={styles.countPart}>
        <div className={styles.countItem}>
          <p className={styles.count}>{currentClickData.hindex}</p>
          <p className={styles.countType}>H-index</p>
        </div>
        <div className={styles.countItem}>
          <p className={styles.count}>
            {currentClickData.papers === 10000
              ? "10000+"
              : currentClickData.papers}
          </p>
          <p className={styles.countType}>论文数</p>
        </div>
        <div className={styles.countItem}>
          <p className={styles.count}>{currentClickData.citation}</p>
          <p className={styles.countType}>引用数</p>
        </div>
      </div>

      <span className={styles.title}>研究兴趣</span>
      <Chart id={currentClickData.id} />
    </div>
  );
}

export default PersonWin;
