/* eslint-disable */
export const handleName = (C) => {
  var t = 20,
    e = [],
    L = (C = C.toUpperCase()).length > 16 ? [0, 4, 10, 16, 23] : [0, 5, 11, 16];
  if ((C = C.length > t ? C.slice(0, t - 1) + "..." : C).length > 8)
    for (var n = 0; n < L.length - 1; n++) {
      var o = C.slice(L[n], L[n + 1]);
      o && e.push(o);
    }
  else
    for (var A = 0; A < Math.ceil(C.length / 4); A++) {
      var i = C.slice(4 * A, 4 * (A + 1));
      i && e.push(i);
    }
  return e;
};
export const getUrlParams = (key) => {
  // 获取当前页面的 URL
  const currentUrl = new URL(window.location.href);

  // 使用 URLSearchParams 获取查询参数
  const searchParams = new URLSearchParams(currentUrl.search);

  const value = searchParams.get(key);
  return value;
};

export const getD3Position = (e) => {
  console.log(e);
  var t = -600,
    a = 330,
    n = 1030,
    x = window.d3,
    i = 35,
    o = 400,
    r = e.nodes.length;
  r < 50
    ? ((t = -800), (a = 400))
    : r < 100
    ? ((t = -800), (a = 350), (i = 35))
    : r < 150
    ? ((t = -900), (a = 450))
    : r < 200
    ? ((t = -1e3), (a = 500))
    : r >= 200 && ((t = -1600), (a = 500), (n = 100), (i = 35)),
    (this.simulation = x
      .forceSimulation(e.nodes)
      .force(
        "charge",
        x.forceManyBody().strength(t).distanceMax(a).distanceMin(o)
      )
      .force("link", x.forceLink(e.relationships).distance(n))
      .force("center", x.forceCenter(800, 400))
      .force(
        "collide",
        x.forceCollide().radius(function () {
          return i;
        })
      ));
};

export const highLight = (id, cy) => {
  cy.collection("node").removeClass("nodeActive");
  cy.collection("edge").removeClass("edgeActive");
  cy.collection("node").addClass("dull");
  cy.collection("edge").addClass("dull");

  cy.nodes(function (t) {
    t._private.data.id === id &&
      (t.removeClass("dull"),
      t.neighborhood("edge").removeClass("dull"),
      t.neighborhood("edge").addClass("edgeActive"),
      t.neighborhood("edge").connectedNodes().removeClass("dull"));
  });
};

export const cancelHighLight = (cy) => {
  cy.collection("node").removeClass("nodeActive");
  cy.collection("edge").removeClass("edgeActive");
  cy.collection("node").removeClass("dull");
  cy.collection("edge").removeClass("dull");
};
// 领域 - 下级领域 -> 领域
// TYPE, SUB_FIELD
// 领域 - 相关学者 -> 学者
// TYPE, RELATE_PERSON
// 领域 - 相关机构 -> 机构
// TYPE, RELATE_ORG
// 学者 - 任职机构 -> 机构
// TYPE, WORK_ORG
// 学者 - 合作学者 -> 学者
// TYPE, CO_PERSON
// 学者 - 合作机构 -> 机构 （这个关系没放上去）
// TYPE, CO_ORG
export const colorMap = {
  paper: {
    color: "#F7BA1E",
    borderColor: "#F7BA1E",
  },
  person: {
    color: "#3F89F8",
    borderColor: "#3F89F8",
  },
  org: {
    color: "#FF6565",
    borderColor: "#FF6565",
  },
  field: {
    color: "#5ED05C",
    borderColor: "#5ED05C",
  },
};

// 格式化后段返回的数据
export const formatData = (data) => {
  let obj = {
    nodes: [],
    edges: [],
  };
  obj.nodes = data.nodes.map((item) => ({
    data: {
      ...item,
      ...(colorMap[item.node_type] || {}),
      id: item.node_id.toString(),
      name: item.name_zh || item.name,
      avatar: item.avatar || item.image,
    },
  }));

  obj.edges = data.edges.map((item) => ({
    data: {
      ...item,
      source: item.source_id,
      target: item.target_id,
      label: item.name,
    },
  }));

  return obj;
};

export const onFullScreen = (target) => {
  target.requestFullscreen
    ? target.requestFullscreen()
    : target.mozRequestFullScreen
    ? target.mozRequestFullScreen()
    : target.webkitRequestFullscreen
    ? target.webkitRequestFullscreen()
    : target.msRequestFullscreen && target.msRequestFullscreen();
};
export const onExitFullScreen = () => {
  document.exitFullscreen
    ? document.exitFullscreen()
    : document.mozCancelFullScreen
    ? document.mozCancelFullScreen()
    : document.webkitExitFullscreen
    ? document.webkitExitFullscreen()
    : document.msExitFullscreen && document.msExitFullscreen();
};
