import React, { useEffect, useState } from "react";
import styles from "./index.less";
import { Routes, Route, Navigate, BrowserRouter } from "react-router-dom";
import EntryPage from "src/ChatPages/EntryPage";

function Chat() {
  const [currentTab, setCurrentTab] = useState("");
  return (
    <div className={styles.chat}>
      <div className={styles.chatContent}>
        <EntryPage />
      </div>
    </div>
  );
}

export default Chat;
