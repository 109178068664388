import react, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
  useRef,
  useContext,
} from "react";
import _ from "lodash";
import styles from "./index.less";
import ExpandableText from "src/component/ExpandableText/index";
import AuthorList from "src/component/AuthorList/index";
import moment from "moment";
import Info from "./Info";
import { useSharedState, defaultUserInfo } from "src/UserContext";
import Header from "src/ChatPages/SearchTabs/Header/index";
import DefaultIndustry from "src/image/chatGraph/defaultListOrg.png";
import Empty from "src/image/chatGraph/empty.svg";
import LoadingGif from "src/image/loading.gif";
import DefaultScholar from "src/image/chatGraph/defaultListPeople.png";
import { getUrlParams } from "src/ChatPages/Graph/util.js";
import { getChatDetailList, getWarpLink } from "src/services/api";
import { UserContext } from "src/Context";
import { Pagination, Select, message, ConfigProvider, Spin } from "antd";
import { useNavigate, Link, useLocation } from "react-router-dom";
import InputClear from "../../image/inputClear.svg";
import zhCN from "antd/lib/locale/zh_CN";
interface IListData {
  title: string;
  authors: any;
  abstractZh: string;
  abstract: string;
  fields: any;
  keywords: string;
  pub_year: string;
  cite_num: string | number;
  node_id: string;
  mini_fields: any;
}
interface IPaper {}

function ChatDetailPage(props: IPaper, ref: any) {
  const listRef = useRef<HTMLDivElement>(null);
  const currentPageRef = useRef(1);
  const chatDetailPageParamsRef = useRef<any>({});
  const [isloading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const inputRef = useRef(null);
  const [userState, setUserState, showLogin, setShowLogin] = useSharedState();
  const listDataRef = useRef([]);
  const [sortType, setSortType] = useState<"link" | "time">("link");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [total, setTotal] = useState<number>(0);
  const [realTotal, setRealTotal] = useState<number>(0);
  const [listData, setListData] = useState<Array<IListData>>([]);
  const [checkBoxState, setCheckBoxState] = useState(false);
  useImperativeHandle(ref, () => ({}));
  const getListData = async () => {
    try {
      setIsLoading(true);
      let res: any = await getChatDetailList({
        ...chatDetailPageParamsRef.current,
        page: {
          size: pageSize,
          page: currentPage,
        },
      });
      setIsLoading(false);

      if (res.code === 0) {
        let temp = res.data.list;
        setListData(temp);
        setTotal(Math.min(res.data.total, 1000));
        setRealTotal(res.data.total);
        listDataRef.current = temp;
        window.scrollTo(0, 0);
      } else {
        message.error(res.message || "系统错误，请稍后重试");
      }
    } catch (e: any) {
      console.log(e);
      setIsLoading(false);
      if (e.code === "ECONNABORTED") {
        message.error("接口超时，请刷新重试", 5);
      } else {
        message.error("系统错误，请稍后重试");
      }
    }
  };

  const handleChange = (page: number, pageSize: number) => {
    setPageSize(pageSize);
    setCurrentPage(page);
  };

  useEffect(() => {
    try {
      let params: any = localStorage.getItem("ChatDetailPage");
      console.log(params);

      if (params) {
        params = JSON.parse(params);
        chatDetailPageParamsRef.current = params;
      }
    } catch (error) {
      console.log(error);
    }
    if (listRef.current) {
      listRef.current.scrollTop = 0;
    }
    getListData();
  }, [currentPage, pageSize]);
  const handleJump = async (id: any) => {
    setIsLoading(true);
    let res: any = {};
    try {
      res = await getWarpLink({
        tag_type: 3,
        token: "",
        url: id,
      });
    } catch (error) {
      message.error("接口网络状态异常，请您稍后再试！");
      setIsLoading(false);
    }

    setIsLoading(false);

    if (res.code === 0) {
      window.open(res.url, "_blank");
      return;
    } else if (res.code === 2) {
      message.error(res.message);
    } else if (res.code === 10) {
      message.error("抱歉，您当前账号为非深圳注册用户，无下载权限");
    } else if (res.code === 22) {
      setShowLogin(true);
    } else if (res.code === 408) {
      message.error("接口网络状态异常，请您稍后再试！");
    }
  };

  return (
    <div className={styles.ChatDetailPage}>
      <Header disableSearch inputRef={inputRef} />
      <div className={styles.content}>
        <Spin
          spinning={isloading}
          indicator={
            <img
              src={LoadingGif}
              style={{
                width: 100,
                height: 100,
                display: "inline-block",
                marginTop: 100,
                transform: "translateX(-250px)",
              }}
            />
          }
        >
          <div className={styles.params}>
            <Info chatDetailPageParams={chatDetailPageParamsRef.current} />
          </div>
          <div className={styles.listCt} ref={listRef}>
            {Array.isArray(listData) && !!listData.length && (
              <>
                {chatDetailPageParamsRef.current &&
                  chatDetailPageParamsRef.current.select === "论文" && (
                    <>
                      <div className={styles.totalBar}>
                        共找到 {realTotal} 个相关论文实体
                        {realTotal > 1000 && "，仅展示前 1,000 条检索结果"}
                      </div>
                      {listData.map((item: any, index) => (
                        <div
                          key={item.node_id}
                          className={styles.paperListItem}
                        >
                          <div className={styles.topInfo}>
                            <p
                              className={
                                styles.topTitle + " text-three-ellipsis"
                              }
                              title={item.titleZh || item.title}
                              onClick={() =>
                                window.open(
                                  `/graph_detail?id=${item.node_id}&node_type=${item.node_type}`
                                )
                              }
                            >
                              {item.titleZh || item.title || "--"}
                            </p>
                          </div>
                          {Array.isArray(item.authors) &&
                            !!item.authors.length && (
                              <AuthorList authors={item.authors} />
                            )}
                          <div className={styles.bottomInfo}>
                            <div className={styles.bottomCount}>
                              <div className={styles.bottomDetail}>
                                <span className={styles.label}>引用量：</span>
                                <span className={styles.count}>
                                  {item.cite_num || "0"}
                                </span>
                              </div>
                              <div className={styles.bottomDetail}>
                                <span className={styles.label}>出版时间：</span>
                                <span className={styles.count}>
                                  {item.pub_year || "--"}
                                </span>
                              </div>
                              <div className={styles.bottomDetail}>
                                <span className={styles.label}>相关实体：</span>
                                <span className={styles.count}>
                                  {item.sub_graph_total || "0"}
                                </span>
                              </div>
                            </div>
                          </div>
                          <ExpandableText
                            text={item.abstractZh || item.abstract}
                            key={item.node_id}
                            track={
                              "kgi_cells_and_genes_detail_paper_info_more_abstract_click"
                            }
                          />
                        </div>
                      ))}
                    </>
                  )}
                {chatDetailPageParamsRef.current &&
                  (chatDetailPageParamsRef.current.select === "学者" ||
                    chatDetailPageParamsRef.current.select === "合作学者") && (
                    <>
                      <div className={styles.totalBar}>
                        共找到 {realTotal} 个相关学者实体
                        {realTotal > 1000 && "，仅展示前 1,000 条检索结果"}
                      </div>
                      {listData.map((item: any, index) => (
                        <div key={index} className={styles.scholarListItem}>
                          <img
                            src={item.avatar || DefaultScholar}
                            alt=""
                            onClick={() =>
                              window.open(
                                `/graph_detail?id=${item.node_id}&node_type=${item.node_type}`
                              )
                            }
                            className={styles.leftIcon}
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null; // prevents looping
                              currentTarget.src = DefaultScholar;
                            }}
                          />
                          <div className={styles.rightPart}>
                            <div className={styles.topInfo}>
                              <p
                                className={
                                  styles.topTitle + " text-two-ellipsis "
                                }
                                title={item.scholar_nameZh || item.scholar_name}
                                onClick={() =>
                                  window.open(
                                    `/graph_detail?id=${item.node_id}&node_type=${item.node_type}`
                                  )
                                }
                              >
                                {item.scholar_nameZh || item.scholar_name}
                              </p>
                              {item.scholar_nameZh && item.scholar_name && (
                                <span className={styles.region}>
                                  （{item.scholar_name}）
                                </span>
                              )}
                            </div>

                            <p
                              className={
                                styles.belongOrg + " text-two-ellipsis "
                              }
                              title={item.org_nameZh || item.org_name || "--"}
                            >
                              {item.org_nameZh || item.org_name}
                            </p>
                            <div className={styles.bottomInfo}>
                              <div className={styles.bottomCount}>
                                <div
                                  className={`${styles.bottomDetail} ${styles.bottomDetailLeft}`}
                                >
                                  <span className={styles.label}>
                                    H-index：
                                  </span>
                                  <span className={styles.count}>
                                    {item.hindex || "0"}
                                  </span>
                                </div>
                                <div className={styles.bottomDetail}>
                                  <span className={styles.label}>引用量：</span>
                                  <span className={styles.count}>
                                    {item.ncitation || "0"}
                                  </span>
                                </div>
                                <div className={styles.bottomDetail}>
                                  <span className={styles.label}>论文数：</span>
                                  <span className={styles.count}>
                                    {item.npubs === 10000
                                      ? "10000+"
                                      : item.npubs || "0"}
                                  </span>
                                </div>
                                <div className={styles.bottomDetail}>
                                  <span className={styles.label}>
                                    相关实体：
                                  </span>
                                  <span className={styles.count}>
                                    {item.sub_graph_total || "0"}
                                  </span>
                                </div>
                              </div>
                            </div>

                            <ExpandableText
                              text={item.profile}
                              key={item.node_id}
                              track={
                                "kgi_cells_and_genes_detail_paper_info_more_abstract_click"
                              }
                            />
                          </div>
                        </div>
                      ))}
                    </>
                  )}
                {chatDetailPageParamsRef.current &&
                  chatDetailPageParamsRef.current.select === "机构" && (
                    <>
                      <div className={styles.totalBar}>
                        共找到 {realTotal} 个相关机构实体
                        {realTotal > 1000 && "，仅展示前 1,000 条检索结果"}
                      </div>
                      {listData.map((item: any, index) => (
                        <div key={index} className={styles.industryListItem}>
                          <img
                            src={item.image || DefaultIndustry}
                            alt=""
                            onClick={() =>
                              window.open(
                                `/graph_detail?id=${item.node_id}&node_type=${item.node_type}`
                              )
                            }
                            className={styles.leftIcon}
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null; // prevents looping
                              currentTarget.src = DefaultIndustry;
                            }}
                          />
                          <div className={styles.rightPart}>
                            <div className={styles.topInfo}>
                              <p
                                className={
                                  styles.topTitle + " text-two-ellipsis "
                                }
                                title={item.org_nameZh || item.org_name}
                                onClick={() =>
                                  window.open(
                                    `/graph_detail?id=${item.node_id}&node_type=${item.node_type}`
                                  )
                                }
                              >
                                {item.org_nameZh || item.org_name || "--"}
                              </p>
                              {item.org_nameZh && item.org_name && (
                                <span className={styles.region}>
                                  （{item.org_name}）
                                </span>
                              )}

                              {/* <LinkGraph /> */}
                            </div>

                            <div className={styles.bottomInfo}>
                              <div className={styles.bottomCount}>
                                <div className={styles.bottomDetail}>
                                  <span className={styles.label}>引用量：</span>
                                  <span className={styles.count}>
                                    {item.n_citation || "0"}
                                  </span>
                                </div>
                                <div className={styles.bottomDetail}>
                                  <span className={styles.label}>论文数：</span>
                                  <span className={styles.count}>
                                    {" "}
                                    {item.n_papers === 10000
                                      ? "10000+"
                                      : item.n_papers || "0"}
                                  </span>
                                </div>
                                <div className={styles.bottomDetail}>
                                  <span className={styles.label}>
                                    相关实体：
                                  </span>
                                  <span className={styles.count}>
                                    {item.sub_graph_total || "--"}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className={styles.tagList}>
                              {Array.isArray(item.mini_fields) &&
                                item.mini_fields
                                  .slice(0, 5)
                                  .map((item: any) => (
                                    <span className={styles.tag} key={item}>
                                      {item || "--"}
                                    </span>
                                  ))}
                            </div>
                          </div>
                        </div>
                      ))}
                    </>
                  )}
                {chatDetailPageParamsRef.current &&
                  chatDetailPageParamsRef.current.select === "专利" && (
                    <>
                      <div className={styles.totalBar}>
                        共找到 {realTotal} 个相关专利
                        {realTotal > 1000 && "，仅展示前 1,000 条检索结果"}
                      </div>
                      {listData.map((item: any, index) => (
                        <div
                          key={Math.random()}
                          className={styles.patentListItem}
                          onClick={() => handleJump(item.patent_id)}
                        >
                          <div className={styles.topInfo}>
                            <p
                              className={styles.topTitle + " text-two-ellipsis"}
                              // onClick={() => item.url && window.open(item.url)}
                              title={item.titleZh || item.title}
                            >
                              {item.titleZh || item.title}
                            </p>
                            {/* <LinkGraph /> */}
                          </div>
                          {/* 
                          {Array.isArray(item.authors) &&
                            item.authors.length && (
                              <p
                                className={
                                  styles.authors + " text-two-ellipsis "
                                }
                                title={item.authors
                                  .map((item: any) => item.nameZh || item.name)
                                  .join("，")}
                              >
                                {item.authors.map((item: any) => (
                                  <strong
                                    className={styles.authorItem}
                                    onClick={() => {
                                      item.aminer_id &&
                                        window.open(
                                          `https://brain.itic-sci.com/profile/${item.aminer_id}`
                                        );
                                    }}
                                  >
                                    {item.nameZh || item.name}
                                  </strong>
                                ))}
                              </p>
                            )} */}

                          {!!(item.abstractZh || item.abstract) && (
                            <p
                              className={
                                styles.patentContent + " text-two-ellipsis "
                              }
                              title={item.abstractZh || item.abstract}
                            >
                              {item.abstractZh || item.abstract}
                            </p>
                          )}

                          <div className={styles.bottomInfo}>
                            {/* <div className={styles.tagList}>
                              {Array.isArray(item.mini_fields) &&
                                item.mini_fields
                                  .slice(0, 5)
                                  .map((itemField: any) => (
                                    <span
                                      className={styles.tag}
                                      key={itemField}
                                    >
                                      {itemField || "-"}
                                    </span>
                                  ))}
                            </div> */}
                            <div className={styles.bottomCount}>
                              <div className={styles.bottomDetail}>
                                <span className={styles.label}>专利号：</span>
                                <span className={styles.count}>
                                  {item.patent_id}
                                </span>
                              </div>
                              <div className={styles.bottomDetail}>
                                <span className={styles.label}>发表日期：</span>
                                <span className={styles.count}>
                                  {moment(item.pub_date).format("YYYY-MM-DD")}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </>
                  )}
              </>
            )}
          </div>
          {!isloading && Array.isArray(listData) && !listData.length && (
            <div className={styles.emptyPart}>
              <img src={Empty} alt="" />
              <p>找不到相关内容</p>
            </div>
          )}
          {!!total && (
            <div className={styles.footerBar}>
              <ConfigProvider locale={zhCN}>
                <Pagination
                  total={total}
                  showSizeChanger={false}
                  onChange={handleChange}
                  defaultCurrent={1}
                  className={styles.pagination}
                />
              </ConfigProvider>
            </div>
          )}
        </Spin>
      </div>
    </div>
  );
}

export default forwardRef(ChatDetailPage);
