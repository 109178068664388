import react, { useState, useEffect, useContext } from "react";
import styles from "./index.less";
interface IHelpLayer {
  setHelpWinVisiable: any;
}

const list = [
  {
    icon: "icon-danji",
    text: "左键单击节点：选中节点",
  },
  {
    icon: "icon-danji",
    text: "左键单击空白：取消选中",
  },
  {
    icon: "icon-shuangji",
    text: "左键双击/右键单击节点：展开/收起节点",
  },
  {
    icon: "icon-changan",
    text: "左键长按空白：拖动画布",
  },
  {
    icon: "icon-changan",
    text: "左键长按节点：拖动节点",
  },
  {
    icon: "icon-gunlun",
    text: "滚轮上下滚动：放大/缩小",
  },
];

function HelpLayer(props: IHelpLayer) {
  const { setHelpWinVisiable } = props;
  return (
    <div
      className={styles.HelpLayer}
      onClick={(e) => {
        if (e.currentTarget === e.target) {
          setHelpWinVisiable(false);
        }
      }}
    >
      <div className={styles.content}>
        <div className={styles.title}>操作说明</div>
        <div className={styles.list}>
          {list.map((item) => (
            <div className={styles.listItem}>
              <span
                className={`iconfont ${item.icon} ${styles.iconfont}`}
              ></span>
              <span className={styles.text}>{item.text}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default HelpLayer;
